import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getIcons } from "../../../api/assets";
import { setNotification } from "../../../slices/notificationSnackbar";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import styles from "../css/Header.module.css";
import { translations as t } from "../../../assets/translations.js";

function groupIconsByCategory(icons, page) {
  const grouped = icons.reduce((acc, icon) => {
    const category = icon.category || "Altro";
    acc[category] = acc[category] || [];
    acc[category].push(icon);
    return acc;
  }, {});
  // Ordina categorie, logic specific to 'social' page
  return orderCategories(grouped, page);
}

function orderCategories(grouped, page) {
  const sortedCategories = Object.keys(grouped)
    .sort()
    .reduce((acc, key) => {
      acc[key] = grouped[key];
      return acc;
    }, {});

  if (page === "social") {
    const priorityCategories = ["Social Media Icons", "Comuni"];
    return priorityCategories.reduce((acc, category) => {
      if (sortedCategories[category]) {
        acc[category] = sortedCategories[category];
        delete sortedCategories[category];
      }
      return acc;
    }, sortedCategories);
  }
  return sortedCategories;
}

export default function SearchSelect({ handleChangeIcon, selectedIcon, page }) {
  // eslint-disable-next-line
  const [icons, setIcons] = useState([]);
  const [groupedIcons, setGroupedIcons] = useState({});
  const [selectedValue, setSelectedValue] = useState(selectedIcon || "");
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.userLang.value);

  useEffect(() => {
    getIcons()
      .then((response) => {
        const iconsData = response.data;
        setIcons(iconsData);
        setGroupedIcons(groupIconsByCategory(iconsData, page));
        if (
          selectedIcon &&
          iconsData.some((icon) => icon.id === selectedIcon.id)
        ) {
          setSelectedValue(selectedIcon);
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response?.data?.error || "Something went wrong.",
            severity: "error",
          })
        );
      });
  }, [dispatch, page, selectedIcon]);

  const optionsArray = useMemo(
    () =>
      Object.keys(groupedIcons).reduce((acc, category) => {
        acc.push(...groupedIcons[category]);
        return acc;
      }, []),
    [groupedIcons]
  );

  return (
    <Autocomplete
      id="icon-select"
      size="small"
      options={optionsArray}
      value={selectedValue || null}
      sx={{
        width: "100%",
        "& .MuiAutocomplete-inputRoot": {
          borderRadius: "40px",
          paddingLeft: "16px !important",
        },
      }}
      groupBy={(option) => option.category}
      autoHighlight
      getOptionLabel={(option) =>
        option.identifier || t["search_select"]["loading"][lang]
      }
      renderOption={(props, option) => (
        <MenuItem {...props} className={styles.menuItemStyle}>
          <img src={option.icon.file} alt={option.identifier} />
          <span className={styles.itemMenuText}>{option.identifier}</span>
        </MenuItem>
      )}
      noOptionsText={t["search_select"]["no_icons"][lang]}
      onChange={(event, newValue) => {
        setSelectedValue(newValue);
        handleChangeIcon(event, newValue ? newValue.id : null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            borderRadius: 10,
            startAdornment: selectedValue?.icon && (
              <img
                src={selectedValue.icon.file}
                alt={selectedValue.identifier}
                style={{ width: 20, marginRight: 2 }}
              />
            ),
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}
