import { useState, useEffect } from "react";
import { Container, Box, Typography, Grid, Button } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import meravigliaLogo from "../../assets/img/logo meraviglia.png";

import { translations as t } from "../../assets/translations.js";
import { ThemeProvider } from "@mui/material/styles";
import { themeButtonItemsMenu } from "../../themes.js";
import arrowLeftIcon from "../../assets/icons/Icone/fi-rr-arrow-left.png";
import { useSelector } from "react-redux";
import useGetProfile from "../../hooks/useGetProfile.jsx";
import CustomIconButton from "../../components/CustomIconButton";
import ButtonTooltip from "../../components/ButtonTooltip";
import styles from "./css/Appearance.module.css";

export default function ModifyComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const lang = useSelector((state) => state.userLang.value);
  const { user_id } = useParams();
  const from = location.state ? location.state.from : null;
  const isFromSettings = from === "settings";
  // eslint-disable-next-line
  const [avatar, setAvatar] = useState(null);

  const handleBack = () => {
    if (isFromSettings) {
      navigate("/overview/settings");
    } else {
      navigate("/overview");
    }
  };

  const handleModifyProfile = () => {
    navigate(`/modify-user/${user_id}`);
  };

  const handleModifyAppearance = () => {
    navigate(`/modify-appearance/${user_id}`);
  };

  //VALORI DI DEFAULT
  const { userProfileLoading, userProfile } = useGetProfile();

  //UPLOAD IMMAGINE
  // reset the body bgcolor to white
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.backgroundColor = "#fff";
  });

  return (
    <Container maxWidth="sm" className={styles.container}>
      <ButtonTooltip
        title={
          isFromSettings
            ? t["botdrw-settings"][lang]
            : t["tooltips"]["overview"][lang]
        }
      >
        <Box className={styles.boxBack}>
          <CustomIconButton
            icon={arrowLeftIcon}
            iconAlt={"arrow left"}
            onClickFunction={handleBack}
          />
        </Box>
      </ButtonTooltip>
      <Typography
        variant="h5"
        fontWeight="500"
        color="initial"
        textAlign="center"
      >
        {t["modify-profile"][lang]}
      </Typography>
      <Box className={styles.avatarBox}>
        <Grid container direction="row">
          <Grid item xs={11}>
            {!userProfileLoading && (
              <img
                src={
                  userProfile.user.avatar
                    ? userProfile.user.avatar.file
                    : meravigliaLogo
                }
                alt="foto profilo"
                className={styles.avatar}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <ThemeProvider theme={themeButtonItemsMenu}>
        <Button
          fullWidth
          className={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleModifyProfile}
        >
          {t["modify-profile-button"][lang]}
        </Button>
        <Button
          fullWidth
          className={styles.button}
          variant="outlined"
          color="primary"
          onClick={handleModifyAppearance}
        >
          {t["modify-appearance-button"][lang]}
        </Button>
      </ThemeProvider>
      <Button
        fullWidth
        className={styles.button + " " + styles["button-save"]}
        variant="contained"
        color="primary"
        onClick={handleBack}
      >
        {t["mod-user-button"][lang]}
      </Button>
    </Container>
  );
}
