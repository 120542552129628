import React from "react";
import { MenuItem, Container, ListItemIcon, ListItemText, Skeleton } from "@mui/material";

import styles from "./CategoryButton.module.css";

export default function CategoryButton({ item, handleMenuItemClick }) {
  if (item.skeleton) {
    return (
      <MenuItem className={styles.categories} onClick={() => handleMenuItemClick(item)}>
        <Container className={styles.centerContainer}>
          <ListItemIcon className={styles.iconCategory}>
            <Skeleton variant="circular" width={20} height={20} />
          </ListItemIcon>
          <ListItemText>
            <Skeleton variant="text" width="100%" />
          </ListItemText>
        </Container>
      </MenuItem>
    );
  } else {
    return (
      <MenuItem className={styles.categories} onClick={() => handleMenuItemClick(item)}>
        <Container className={styles.centerContainer}>
          <ListItemIcon className={styles.iconCategory}>
            <img src={item.icon.icon.file} alt={item.icon.name} />
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </Container>
      </MenuItem>
    );
  }
}
