import { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./SelectUsername.module.css";
import { Box, Typography, Grid, ThemeProvider, Button } from "@mui/material";
import ValidateUsername from "../../../components/ValidateUsername/ValidateUsername";
import { useNavigate } from "react-router-dom";
import { themeBlackButton } from "../../../themes";
import { setLandingUserName } from "../../../slices/persistentRegistrationData";
import { useSelector } from "react-redux";
import { translations as t } from "../../../assets/translations";

const SelectUsername = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);
  const [invalidChars, setInvalidChars] = useState([]);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isNumeric, setIsNumeric] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.userLang.value);

  const validateProps = {
    isUsernameTaken,
    setIsUsernameTaken,
    lengthValid,
    setLengthValid,
    hasInvalidCharacters,
    setHasInvalidCharacters,
    invalidChars,
    setInvalidChars,
    checkingUsername,
    setCheckingUsername,
    debounceTimeout,
    setDebounceTimeout,
    isNumeric,
    setIsNumeric,
    username,
    setUsername,
    firstLoadData: {},
    required: false,
    landingPage: true,
    inputLabelProps: {
      style: {
        color: "black",
      },
    },
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" rowGap={1}>
        <Grid item xs={12} lg={6}>
          <Box className={styles.validateUsernameBox}>
            <Typography variant="body1" fontWeight="500" color={"white"}>
              hellolink.pro/
            </Typography>
            <ValidateUsername props={validateProps} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ThemeProvider theme={themeBlackButton}>
            <Button
              variant="outlined"
              onClick={() => {
                localStorage.setItem("usernameLandingPage", username);
                dispatch(
                  setLandingUserName({
                    landingUserName: username,
                  })
                );
                navigate("/signup");
              }}
              sx={{
                mt: {
                  xs: "5px",
                  sm: 0,
                },
                ml: {
                  xs: 0,
                  lg: 2,
                },
              }}
            >
              {t["landing_page"]["get_your_link"][lang]}
            </Button>
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectUsername;
