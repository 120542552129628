import { axiosInstance } from "./common.js";

export const uploadFile = (file) => {
  const apiEndpoint = "storage/upload/";

  let formData = new FormData();
  formData.append("file", file);

  return axiosInstance
    .post(apiEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
