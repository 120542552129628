import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormGroup,
  Typography,
  Container,
  Link,
  Button,
} from "@mui/material";
import { translations as t } from "../../assets/translations.js";
import { loginFunction } from "../../api/authentication.js";
import { useSelector, useDispatch } from "react-redux";
import { setIsAuth, setRefresh } from "../../slices/userAuth.js";
import { setUserType } from "../../slices/userTypeSlice.js";
import { setNotification } from "../../slices/notificationSnackbar.js";
import GoogleLoginButton from "../../components/GoogleLoginButton/GoogleLoginButton.jsx";
import Meraviglia from "../../components/Meraviglia";
import InputEmail from "../../components/InputEmail";
import InputPassword from "../../components/InputPassword";
import IconFlag from "../../components/IconFlag";
import styles from "./css/Login.module.css";
import { useCheckRefreshToken } from "../../hooks/useCheckRefreshToken.jsx";

export default function Login() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);
  const dispatch = useDispatch();

  // Checking presence of refresh token
  useEffect(() => {
    if (localStorage.getItem("refresh")) {
      dispatch(setRefresh());
    }
  }, [dispatch]);

  // reset the body bgcolor to white
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.backgroundColor = "#fff";
  });

  // Checking refresh state
  useCheckRefreshToken();

  const redirectToMain = () => {
    const request = {
      email: email,
      password: password,
    };

    if (isValidEmail && email.trim() !== "" && password.trim() !== "") {
      loginFunction(request)
        .then((response) => {
          // Set tokens
          const data = response.data;
          localStorage.setItem("refresh", JSON.stringify(data.refresh));
          dispatch(setRefresh());
          localStorage.setItem("access", JSON.stringify(data.access));
          dispatch(setIsAuth());
          localStorage.setItem("user_type", JSON.stringify(data.user_type));
          dispatch(setUserType({ userType: data.user_type }));

          // Redirect to page
          if (data.user_type === "NU") {
            navigate("/overview");
          } else if (data.user_type === "CU") {
            navigate("/company-pannel");
          } else {
            navigate("/unauthorized");
          }
        })
        .catch((err) => {
          const errStatusCode = err.response ? err.response.data.status : null;

          dispatch(
            setNotification({
              open: true,
              message: errStatusCode
                ? t[`login-${errStatusCode}`][lang]
                : "Something went wrong: Could not log in.",
              severity: "error",
            })
          );
        });
    } else {
      let errorMessage = "";
      if (!isValidEmail || email.trim() === "") {
        errorMessage += t["email-non-valida"][lang];
      }
      if (password.trim() === "") {
        errorMessage += t["password-non-valida"][lang];
      }
      alert(errorMessage);
    }
  };

  const handleRecoverPassword = () => {
    navigate("/forgot-password", { state: email });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      redirectToMain();
    }
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <IconFlag />
      <FormControl id="login" component="fieldset" sx={{ width: "100%" }}>
        <Typography variant="h3" fontWeight="500" color="initial">
          {t["login-ciao"][lang]}
        </Typography>
        <Typography variant="h3" fontWeight="500" color="primary">
          {t["login-bentornato"][lang]}
        </Typography>

        <FormGroup sx={{ width: "100%" }} onKeyPress={handleKeyPress}>
          <InputEmail
            email={email}
            setEmail={setEmail}
            isValidEmail={isValidEmail}
            setIsValidEmail={setIsValidEmail}
          />
          <InputPassword password={password} setPassword={setPassword} />
        </FormGroup>
        <Typography variant="body1" color="initial" sx={{ textAlign: "right" }}>
          <Link
            onClick={handleRecoverPassword}
            variant="body1"
            underline="hover"
            color="inherit"
            fontWeight="500"
          >
            {t["login-pass-dimenticata"][lang]}
          </Link>
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          className={styles.buttonForm}
          onClick={redirectToMain}
          disabled={
            email.trim() === "" || password.trim() === "" || !isValidEmail
          }
        >
          {t["login-accedi"][lang]}
        </Button>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="body1" color="initial">
            {t["login-nuovo-account"][lang]}{" "}
          </Typography>
          <Link
            href="/signup"
            variant="body1"
            underline="hover"
            color="inherit"
            fontWeight="600"
          >
            {t["login-registrati"][lang]}
          </Link>
        </Container>
      </FormControl>
      <GoogleLoginButton />
      <Meraviglia />
    </Container>
  );
}
