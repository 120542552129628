import { useState } from "react";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { textFieldStyle } from "../../../themes";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import { translations as t } from "../../../assets/translations.js";
import { getCities } from "../../../api/assets.js";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../../slices/notificationSnackbar.js";

// Theme.ts
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          renderOption: (props, option, state, ownerState) => (
            <Box
              sx={{
                fontFamily: "Poppins, Arial, sans-serif",
                borderRadius: "40px",
                margin: "5px",
                [`&.${autocompleteClasses.option}`]: {
                  padding: "8px",
                },
              }}
              component="li"
              {...props}
            >
              {ownerState.getOptionLabel(option)}
            </Box>
          ),
        },
      },
    },
  });

export default function CitySelect({
  value,
  handleAddressChange,
  index,
  handleCityChange,
  disabled,
  selectedProvinceId,
}) {
  const outerTheme = useTheme();
  const lang = useSelector((state) => state.userLang.value);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   getCities(selectedProvinceId).then((response) => {
  //     if (response.ok) {
  //       setError({ open: false, code: "", message: "" });
  //       setCities(response.data);
  //     } else {
  //       setError({
  //         open: true,
  //         code: response.status,
  //         message: response.data.error,
  //       });
  //     }
  //   });
  // }, [selectedProvinceId, disabled]);

  function handleLoadCities() {
    getCities(selectedProvinceId)
      .then((response) => {
        dispatch(
          setNotification({
            open: false,
          })
        );
        setCities(response.data);
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  }
  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Autocomplete
        name="city-autocomplete"
        options={cities}
        sx={textFieldStyle}
        value={value}
        onOpen={handleLoadCities}
        disabled={disabled}
        onChange={(event, newValue) => {
          handleAddressChange(index, "city", newValue);
          handleCityChange(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.name}`}
        renderInput={(params) => (
          <TextField
            name="city-field"
            {...params}
            sx={textFieldStyle}
            label={t["contact-city"][lang]}
          />
        )}
      />
    </ThemeProvider>
  );
}
