import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  refreshAccessToken,
  clearClientAuthState,
} from "../api/authentication";
import { setNotification } from "../slices/notificationSnackbar";
import { clearIsAuth, clearRefresh } from "../slices/userAuth";
import { clearUserType } from "../slices/userTypeSlice";
import { translations as t } from "../assets/translations";

/**
 * Custom hook that checks the refresh token and updates the access token if necessary.
 * It also handles the redirection based on the user type.
 */

export const useCheckRefreshToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refresh = useSelector((state) => state.userAuth.refresh);
  const lang = useSelector((state) => state.userLang.value);

  useEffect(() => {
    async function checkRefresh() {
      try {
        const refreshToken = await JSON.parse(localStorage.getItem("refresh"));
        const response = await refreshAccessToken(refreshToken);
        const newAccess = JSON.stringify(response.data.access);
        const newRefresh = JSON.stringify(response.data.refresh);

        // Set tokens
        localStorage.setItem("access", newAccess);
        localStorage.setItem("refresh", newRefresh);

        const userType = JSON.parse(localStorage.getItem("user_type"));

        // Redirect to page based on user type
        if (userType === "NU") {
          navigate("/overview");
        } else if (userType === "CU") {
          navigate("/company-pannel");
        } else {
          navigate("/");
        }
      } catch (error) {
        // Handle error
        dispatch(
          setNotification({
            open: true,
            message: t["login-snackbar"][lang],
            severity: "error",
          })
        );

        dispatch(clearIsAuth());
        dispatch(clearRefresh());
        dispatch(clearUserType());
        clearClientAuthState();
      }
    }

    if (refresh) {
      checkRefresh();
    }
  }, [refresh, navigate, dispatch, lang]);
};
