// Capitalizes the first letter of a string.
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Capitalizes the first letter of each word in a string.
export function capitalizeFirstLetterOfEachWord(string) {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

// Imports all images from a given context.
export function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "").replace("fi-rr-", "").replace(".png", "")] =
      r(item);
  });
  return images;
}

/**
 * Calculates the number of columns needed to display a specific number of icons.
 * @param {number} numberOfIcons - The number of icons to be displayed.
 * @returns {number} The number of columns needed to display the icons.
 */
export const disponiIcone = (numberOfIcons) => {
  if (numberOfIcons === 1) return 12;
  if (numberOfIcons === 2) return 6;
  if (numberOfIcons === 4) return 6;
  if (numberOfIcons <= 6) return 4;
  if (numberOfIcons > 6) return 3;
  return Math.max(Math.floor(12 / numberOfIcons), 3);
};

// Delays the execution for a specified number of milliseconds.
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Checks if a username contains invalid characters.
 * @param {string} username - The username to be checked.
 * @returns {string[]} An array of invalid characters found in the username.
 */
export const containsInvalidCharacters = (username) => {
  const invalidCharactersRegex = /[^a-zA-Z0-9_]/g;
  const matches = username.match(invalidCharactersRegex);
  return matches ? [...new Set(matches)] : [];
};
export const rgbToObject = (rgbString) => {
  const regex = /rgb\((\d+)[,\s]*\s*(\d+)[,\s]*\s*(\d+)\)/g;
  const matches = [...rgbString.matchAll(regex)];
  return matches.map((match) => ({
    r: match[1],
    g: match[2],
    b: match[3],
  }));
};

export function formatColor(color) {
  const formattedColor = {
    hex: color.hex,
    rgb: {
      r: color.rgb_r,
      g: color.rgb_g,
      b: color.rgb_b,
    },
  };

  return formattedColor;
}

export const rgbColorIsDark = (r, g, b) => {
  return r * 0.299 + g * 0.587 + b * 0.114 < 186;
};

export const hexToRgb = (hex) => {
  const hexValue = hex.replace("#", "");
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);
  return { r, g, b };
};
