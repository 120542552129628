import { Box, Container, Typography, Grid } from "@mui/material";
import { translations as t } from "../../../assets/translations";
import { useSelector } from "react-redux";
import FAQItem from "./FAQItem";

const FAQ = () => {
  const lang = useSelector((state) => state.userLang.value);
  return (
    <Box sx={{ bgcolor: "white" }}>
      <Container
        sx={{
          padding: {
            xs: "50px 16px 50px 16px",
            md: "100px 32px 100px 32px",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          rowGap={5}
          sx={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="black"
              fontWeight={700}
              textAlign={"center"}
            >
              {t["landing_page"]["faq_title"][lang]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FAQItem faqId={1} />
          </Grid>
          <Grid item xs={12}>
            <FAQItem faqId={2} />
          </Grid>
          <Grid item xs={12}>
            <FAQItem faqId={3} />
          </Grid>
          <Grid item xs={12}>
            <FAQItem faqId={4} />
          </Grid>
          <Grid item xs={12}>
            <FAQItem faqId={5} />
          </Grid>
          <Grid item xs={12}>
            <FAQItem faqId={6} />
          </Grid>
          <Grid item xs={12}>
            <FAQItem faqId={7} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQ;
