import { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  Button,
  Grid,
  ThemeProvider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import menuIcon from "../../assets/icons/Icone/fi-rr-menu-dots.png";
import homeIcon from "../../assets/icons/Icone/fi-rr-home.png";
import { translations as t } from "../../assets/translations.js";
// import Review from "../components/Review";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";
import ButtonTooltip from "../../components/ButtonTooltip";
import CustomIconButton from "../../components/CustomIconButton";
import Meraviglia from "../../components/Meraviglia";
import BottomDrawer from "./components/BottomDrawer";
import ContactDrawer from "./components/ContactDrawer";
import NotFoundPage from "../../components/NotFoundPage";
import ProfilePicture from "./components/ProfilePicture";
import {
  getPublicUserData,
  getPublicContactData,
  getPublicPdfData,
} from "../../api/public.js";
import { BASE_URL_ENDPOINT } from "../../api/common.js";
import { disponiIcone } from "../../utils";
import styles from "./css/Overview.module.css";
import {
  themeButtonItemsMenu as themeButtons,
  themeTextOverview,
} from "../../themes.js";
import { hexToCSSFilter } from "hex-to-css-filter";

export default function OverviewPreview() {
  const navigate = useNavigate();
  const location = useLocation();
  const lang = useSelector((state) => state.userLang.value);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [contactDrawerOpen, setContactDrawerOpen] = useState(false);
  const [existentUser, setExistentUser] = useState(false);
  const [userDataApi, setUserDataApi] = useState([]);
  const [arrayDataApi, setArrayDataApi] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Stato per il controllo del caricamento
  const isAuth = useSelector((state) => state.userAuth.isAuth);
  const [userInfo, setUserInfo] = useState({});
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false); // Stato per tracciare il completamento del caricamento dei dati
  // Custom appearance here is set as a state because it is not a global state
  const [customAppearance, setCustomAppearance] = useState({});
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const username = pathSegments[0];

  //SETTAGGIO DEI VALORI
  useEffect(() => {
    setIsLoading(true); // Inizia il caricamento
    if (username) {
      getPublicUserData(username)
        .then((response) => {
          setArrayDataApi(response.data.links);
          setUserDataApi(response.data.user);
          setExistentUser(true);
          setUserInfo({
            id: response.data.user.user.id ?? 123234,
            avatar: response.data.user.avatar
              ? response.data.user.avatar.file
              : null,
            first_name: response.data.user.user.first_name,
            last_name: response.data.user.user.last_name,
            username: response.data.user.user.username,
            bio: response.data.user.bio,
          });
          setCustomAppearance(response.data.user.preference);
          setDataLoaded(true);
          setIsLoading(false);
          dispatch(
            setNotification({
              open: false,
            })
          );
        })
        .catch(() => {
          setExistentUser(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [location.pathname, dispatch]);

  // load background color on body when finished loading
  useEffect(() => {
    if (dataLoaded) {
      const body = document.querySelector("body");
      body.style.backgroundColor = `rgb(${customAppearance.background.color.rgb_r},
        ${customAppearance.background.color.rgb_g},
        ${customAppearance.background.color.rgb_b})`;
    }
  }, [dataLoaded, customAppearance]);

  // Funzione per aprire il drawer
  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const iconsApi = arrayDataApi.filter(
    (item) => item.type === "ICON" && item.is_visible
  );

  const buttonsApi = arrayDataApi.filter(
    (item) => item.type === "BUTTON" && item.is_visible
  );

  //LASCIARE UNA RECENSIONE
  // const [reviewLink, setReviewLink] = useState("");
  // const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  // const handleReviewSubmit = (reviewData) => {
  //   // Invia la recensione al server o gestiscila come necessario
  // };

  const [contactData, setContactData] = useState({});
  const [contactId, setContactId] = useState({});
  const handleClickOpen = (item) => {
    switch (item.category.name) {
      case "Contact":
        getContactDataFunction(item.id, item.contact);
        setContactDrawerOpen(true);
        break;
      case "Links/Websites":
        window.open(item.url, "_blank");
        break;

      case "PDF":
        getPublicPdfData(item.id, item.pdf)
          .then((response) => {
            window.location.href = response.data.pdf.file;
          })
          .catch((error) => {
            alert("errore non puoi scaricare");
            dispatch(
              setNotification({
                open: true,
                message: error.response.data.data
                  ? error.response.data.data.error
                  : "Something went wrong.",
                severity: "error",
              })
            );
          });
        break;

      case "Social Media":
        window.open(item.url, "_blank");

        break;

      case "Video":
        window.open(item.url, "_blank");
        break;

      case "Review":
        //LOGICA PER LASCIARE UNA RECENSIONE
        // setReviewDialogOpen(true);
        // setReviewLink(item.url);
        window.open(item.url, "_blank");
        break;

      default:
        break;
    }
  };

  const getContactDataFunction = (link_id, contact_id) => {
    getPublicContactData(link_id, contact_id, username)
      .then((response) => {
        setContactData(response.data.contact);
        setContactId(contact_id);
        dispatch(
          setNotification({
            open: false,
          })
        );
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  const downloadContact = () => {
    window.location.href = `${BASE_URL_ENDPOINT}contacts/${contactId}/download/?username=${username}`;
  };

  if (isLoading) {
    <Container maxWidth="sm" className={styles.container}>
      {/* Skeleton per l'avatar */}
      <Skeleton variant="circular" width={150} height={150} />

      <Skeleton variant="text" width={"20%"} height={20} />
      <Skeleton variant="text" width={"20%"} height={20} />
      <Skeleton variant="text" width={"15%"} height={14} />
      <Skeleton variant="text" width={"15%"} height={14} />

      {/* Skeleton per i bottoni */}
      {Array.from(new Array(3)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={210}
          height={50}
          style={{ borderRadius: "4px", marginBottom: "10px" }}
        />
      ))}

      {/* Skeleton per le icone */}
      <Grid container spacing={2}>
        {Array.from(new Array(4)).map((_, index) => (
          <Grid item key={index}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
        ))}
      </Grid>
    </Container>;
  } else if (!isLoading && existentUser) {
    return (
      <Box
        sx={
          customAppearance.background.type === "gradient"
            ? {
                background: `linear-gradient(to bottom, rgb(${customAppearance.background.color.rgb_r},
                                        ${customAppearance.background.color.rgb_g},
                                        ${customAppearance.background.color.rgb_b}),
                                      rgb(255, 255, 255))`,
                height: "100%",
              }
            : {
                backgroundColor: `rgb(${customAppearance.background.color.rgb_r},
                                        ${customAppearance.background.color.rgb_g},
                                        ${customAppearance.background.color.rgb_b})`,
                height: "100%",
              }
        }
      >
        <Container
          maxWidth="sm"
          className={styles.container}
          sx={{
            pt: 2,
          }}
        >
          {/* Box posizionato nell'angolo in alto a sinistra */}
          {isAuth ? (
            <ButtonTooltip title={t["tooltips"]["overview"][lang]}>
              <Box className={styles.leftcornerIcon}>
                <CustomIconButton
                  icon={homeIcon}
                  iconAlt={"home profile"}
                  onClickFunction={() => navigate("/overview")}
                  targetColor={{
                    r: customAppearance.texts.color.rgb_r,
                    g: customAppearance.texts.color.rgb_g,
                    b: customAppearance.texts.color.rgb_b,
                  }}
                />
              </Box>
            </ButtonTooltip>
          ) : null}

          {/* Box posizionato nell'angolo in alto a sinistra */}
          <Box className={styles.rightcornerIcon}>
            <CustomIconButton
              icon={menuIcon}
              iconAlt={"menu"}
              onClickFunction={() => openDrawer()}
              targetColor={{
                r: customAppearance.texts.color.rgb_r,
                g: customAppearance.texts.color.rgb_g,
                b: customAppearance.texts.color.rgb_b,
              }}
            />
          </Box>
          {/* {userDataApi.avatar ? (
          <div className={styles.avatarContainer}>
            <img
              className={styles.avatar}
              src={userDataApi.avatar ? userDataApi.avatar.file : null}
              alt="Avatar"
            />
          </div>
        ) : null} */}
          <ProfilePicture
            photo={userDataApi.avatar ? userDataApi.avatar.file : null}
            dataLoaded={dataLoaded}
          />

          <Box className={styles.boxCopy}>
            <ThemeProvider theme={themeTextOverview}>
              <Typography
                variant="h5"
                sx={{
                  color: customAppearance.texts.color.hex,
                  fontFamily: customAppearance.texts.family,
                }}
              >
                {userDataApi["user"]["first_name"]}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="500"
                sx={{
                  color: customAppearance.texts.color.hex,
                  fontFamily: customAppearance.texts.family,
                }}
              >
                {userDataApi["user"]["last_name"]}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: customAppearance.texts.color.hex,
                  fontFamily: customAppearance.texts.family,
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {userDataApi["bio"]}
              </Typography>
            </ThemeProvider>
          </Box>

          <Container maxWidth="sm" role="main" className={styles.containerMain}>
            {buttonsApi.length > 0 && (
              <Box className={styles.mainBox}>
                {buttonsApi.map((item, index) => {
                  const cssFilter = hexToCSSFilter(
                    customAppearance.buttons.font.color.hex
                  ).filter.slice(0, -1);
                  return (
                    <ThemeProvider key={index} theme={themeButtons}>
                      <Button
                        variant="outlined"
                        onClick={() => handleClickOpen(item)}
                        startIcon={
                          // <ProcessedImage
                          //   source={item.icon ? item.icon.icon.file : null}
                          //   alt={item.icon ? item.icon.identifier : null}
                          //   style={{
                          //     height: "21px",
                          //   }}
                          //   targetColor={{
                          //     r: customAppearance.buttons.text.rgb.r,
                          //     g: customAppearance.buttons.text.rgb.g,
                          //     b: customAppearance.buttons.text.rgb.b,
                          //   }}
                          // />
                          <img
                            src={item.icon.icon.file}
                            alt={item.icon.identifier}
                            style={{
                              height: "21px",
                              filter: cssFilter,
                            }}
                          />
                        }
                        sx={{
                          bgcolor: customAppearance.buttons.color.hex,
                          border: customAppearance.buttons.strokeIsActive
                            ? `1px solid ${customAppearance.buttons.stroke.hex} !important`
                            : "none",
                          "&:hover": {
                            bgcolor: customAppearance.buttons.onhover.hex,
                          },
                        }}
                      >
                        <Typography
                          variant="body1"
                          noWrap
                          color="initial"
                          sx={{
                            color: customAppearance.buttons.font.color.hex,
                            fontFamily: customAppearance.buttons.font.family,
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Button>
                    </ThemeProvider>
                  );
                })}
              </Box>
            )}

            {iconsApi.length > 0 && (
              <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                sx={{ maxWidth: "150px", mb: 1 }}
              >
                {iconsApi.map((item, index) => {
                  const cssFilter = hexToCSSFilter(
                    customAppearance.buttons.font.color.hex
                  ).filter.slice(0, -1);
                  return (
                    <Grid
                      xs={disponiIcone(iconsApi.length)}
                      item
                      key={index}
                      tabIndex={0}
                      className={styles.center}
                    >
                      <CustomIconButton
                        icon={item.icon ? item.icon.icon.file : null}
                        iconAlt={item.icon ? item.icon.identifier : item.title}
                        onClickFunction={() => handleClickOpen(item)}
                        filter={cssFilter}
                        // targetColor={{
                        //   r: customAppearance.buttons.text.rgb.r,
                        //   g: customAppearance.buttons.text.rgb.g,
                        //   b: customAppearance.buttons.text.rgb.b,
                        // }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Container>

          <BottomDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            userData={userInfo}
          />
          <ContactDrawer
            username={username}
            contactData={contactData}
            contactId={contactId}
            downloadContact={downloadContact}
            drawerOpen={contactDrawerOpen}
            setDrawerOpen={setContactDrawerOpen}
          />
          <Meraviglia
            isOverview={true}
            localCustomAppearance={customAppearance}
          />
          {/* <Review
          open={reviewDialogOpen}
          onClose={() => setReviewDialogOpen(false)}
          onReviewSubmit={handleReviewSubmit}
          link={reviewLink}
          lang={lang}
        /> */}
        </Container>
      </Box>
    );
  } else if (!isLoading && !existentUser) {
    return <NotFoundPage />;
  }
}
