import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Card,
} from "@mui/material";
import styles from "./LinkNfc.module.css";
import CustomIconButton from "../../components/CustomIconButton";
import ButtonTooltip from "../../components/ButtonTooltip";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";
import { translations as t } from "../../assets/translations.js";
import arrowLeftIcon from "../../assets/icons/Icone/fi-rr-arrow-left.png";
import { useNavigate, useParams } from "react-router-dom";
import { textFieldStyleLoginSignup as textFieldStyle } from "../../themes.js";
import { assignNfcCard, getNfcData, disconnectNfcCard } from "../../api/nfc.js";
import logomeraviglia from "../../assets/img/meraviglia.png";

export default function LinkNfc() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.userLang.value);
  const [nfcId, setNfcId] = useState("");
  const [nfcCode, setNfcCode] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [pin, setPin] = useState("");
  const { user_id } = useParams();

  useEffect(() => {
    getNfcData(user_id)
      .then((response) => {
        const id = response.data.id;
        const nfc_code = response.data.nfc_code;
        setNfcCode(nfc_code);
        setNfcId(id);
        if (nfc_code) {
          setIsActive(true);
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  }, [dispatch, user_id]);

  const handleBack = () => {
    navigate("/overview/settings");
  };

  const handleNfcCodeChange = (event) => {
    setNfcCode(event.target.value);
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    assignNfcCard(nfcCode, pin)
      .then(() => {
        dispatch(
          setNotification({
            open: true,
            message: t["nfc"]["success"][lang],
            severity: "success",
          })
        );
        setIsActive(true);
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  const handleClickDisconnect = () => {
    disconnectNfcCard(nfcId)
      .then(() => {
        dispatch(
          setNotification({
            open: true,
            message: t["nfc"]["success"][lang],
            severity: "success",
          })
        );
        setIsActive(false);
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <ButtonTooltip title={t["botdrw-settings"][lang]}>
        <Box className={styles.boxBack}>
          <CustomIconButton
            icon={arrowLeftIcon}
            iconAlt={"arrow left"}
            onClickFunction={handleBack}
          />
        </Box>
      </ButtonTooltip>

      {isActive ? (
        <>
          <Typography variant="h5" color="initial" fontSize="29px">
            {t["nfc"]["title2"][lang]}
          </Typography>
          <Card
            elevation={3}
            sx={{
              width: "90%",
              height: "240px",
              borderRadius: "30px",
              p: 3,
              m: 3,
              backgroundImage: `url(${logomeraviglia})`,
              backgroundPosition: "left top",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backgroundBlendMode: "overlay",
              overflow: "visible",
            }}
          >
            <Typography variant="h6" color="initial">
              NFC code <b>{nfcCode}</b>
            </Typography>
          </Card>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={styles.button}
            onClick={handleClickDisconnect}
          >
            {t["nfc"]["unlink"][lang]}
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h5" fontSize="29px">
            {t["nfc"]["title"][lang]}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 3 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="nfc-code"
              label="Codice NFC"
              name="nfcCode"
              autoComplete="off"
              autoFocus
              value={nfcCode}
              onChange={handleNfcCodeChange}
              sx={textFieldStyle}
            />
            <Typography
              variant="caption"
              color="initial"
              sx={{ paddingLeft: "24px" }}
            >
              {t["nfc"]["code"][lang]}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="pin"
              label="PIN"
              name="pin"
              autoComplete="off"
              value={pin}
              onChange={handlePinChange}
              sx={textFieldStyle}
            />
            <Typography
              variant="caption"
              color="initial"
              sx={{ paddingLeft: "24px" }}
            >
              {t["nfc"]["pin"][lang]}
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={styles.button}
            >
              {t["nfc"]["link"][lang]}
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}
