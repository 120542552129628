import { useState } from "react";

import { Skeleton } from "@mui/material";
import ProfilePictureDialog from "./ProfilePictureDialog";

import styles from "../css/Overview.module.css";

export default function ProfilePicture({ photo, dataLoaded }) {
  // Stato per controllare l'apertura del dialog della foto del profilo
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  // Funzione per aprire il dialog della foto del profilo
  const handleProfileClick = () => {
    setProfileDialogOpen(true);
  };

  // Funzione per chiudere il dialog della foto del profilo
  const handleCloseProfileDialog = () => {
    setProfileDialogOpen(false);
  };

  if (photo && dataLoaded) {
    return (
      <>
        <div className={styles.avatarContainer}>
          <img
            className={styles.avatar}
            src={photo}
            alt="Avatar"
            onClick={handleProfileClick}
          />
        </div>
        <ProfilePictureDialog
          photoUrl={photo}
          open={profileDialogOpen}
          handleClose={handleCloseProfileDialog}
        />
      </>
    );
  } else if (photo && !dataLoaded) {
    return (
      <div className={styles.avatarContainer}>
        <Skeleton variant="circular" width={125} height={125} />
      </div>
    );
  } else {
    return null;
  }
}
