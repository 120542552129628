import { useState, useEffect } from "react";
import { Cancel } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
// import { ColorPicker } from "primereact/colorpicker";
import ColorPicker from "../ColorPicker";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
  Grid,
} from "@mui/material";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";

import styles from "./CustomCropper.module.css";
import { translations as t } from "../../assets/translations";

const CropEasy = ({
  open,
  imageUrlLocal,
  setImageUrlLocal,
  setOpenCropper,
  setFile,
  cancelCrop,
  setCroppedUrlImage,
  onCropComplete = () => {
    setOpenCropper(false);
  },
}) => {
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [color, setColor] = useState({
    hex: "#fff",
    rgb: {
      r: 255,
      g: 255,
      b: 255,
    },
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const lang = useSelector((state) => state.userLang.value);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (imageUrlLocal) {
      const image = new Image();
      image.src = imageUrlLocal;
      image.onload = () => {
        setImageSize({
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
      };
    }
  }, [imageUrlLocal]);

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        imageUrlLocal,
        croppedAreaPixels,
        rotation,
        color.hex
      );
      setCroppedUrlImage(url);
      setImageUrlLocal(url);
      setFile(file);
      onCropComplete(file);
    } catch (error) {
      dispatch(
        setNotification({
          open: true,
          message: error.response.data.data
            ? error.response.data.data.error
            : "Something went wrong.",
          severity: "error",
        })
      );
    }
  };

  // Reset
  const handleClickReset = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setColor({
      hex: "#fff",
      rgb: {
        r: 255,
        g: 255,
        b: 255,
      },
    });
  };

  return (
    <Dialog open={open} PaperProps={{ sx: { borderRadius: 10, p: 1, pb: 2 } }}>
      <DialogContent
        dividers
        className={styles.content}
        sx={{
          minHeight: { xs: 400 },
          bgcolor: color.hex,
        }}
      >
        <Cropper
          image={imageUrlLocal}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          cropShape="round"
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
          objectFit={
            imageSize.width > imageSize.height
              ? "vertical-cover"
              : "horizontal-cover"
          }
          minZoom={0.1}
          zoomSpeed={0.25}
          restrictPosition={false}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column", p: 2, overflowY: "auto" }}>
        <Box sx={{ width: "100%", minHeight: 200, maxHeight: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography>{t["crop_image"]["bgcolor"][lang]}</Typography>
              <ColorPicker startingColor={color} onColorSelection={setColor} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Zoom: {zoomPercent(zoom)}</Typography>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={zoomPercent}
                min={0}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
              <Typography>
                {t["crop_image"]["rotation"][lang]}: {rotation + "°"}
              </Typography>
              <Slider
                valueLabelDisplay="auto"
                min={0}
                max={360}
                value={rotation}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              gap: { xs: 0.5, sm: 2 },
              flexDirection: "row",
            }}
          >
            <Button
              variant="text"
              onClick={handleClickReset}
              className={styles.button}
            >
              {t["crop_image"]["reset"][lang]}
            </Button>
            <Button
              variant="outlined"
              startIcon={<Cancel />}
              onClick={cancelCrop}
              className={styles.button}
            >
              {t["crop_image"]["cancel"][lang]}
            </Button>
            <Button
              variant="contained"
              startIcon={<CropIcon />}
              onClick={cropImage}
              className={styles.button}
            >
              {t["crop_image"]["crop"][lang]}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
