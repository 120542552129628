import React from "react";
import { Dialog, DialogContent } from "@mui/material";

export default function ProfilePictureDialog({ photoUrl, open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "hidden",
          borderRadius: "50%",
          border: "solid 8px #247DFF", // #f5f5f5
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={photoUrl}
          alt="Profile"
          style={{
            maxWidth: "100%",
            maxHeight: "400px",
            minHeight: "300px",
            width: "auto",
            height: "auto",
            objectFit: "cover",
            aspectRatio: "1 / 1",
            borderRadius: "50%",
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
