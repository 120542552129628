import React from 'react'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const ButtonTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#247DFF",
      fontWeight: "bold",
      boxShadow: theme.shadows[2],
      fontSize: 12,
    },
}));

export default ButtonTooltip
