import { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import MenuIcon from "@mui/icons-material/Menu";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

import addIcon from "../../assets/icons/Icone/fi-rr-add.png";
// import userIcon from "../../assets/icons/Icone/fi-rr-user.png";
import EditIcon from "../../assets/icons/Icone/fi-rr-pencil.png";
import eliminaIcon from "../../assets/icons/Icone/fi-rr-trash.png";
import activateIcon from "../../assets/icons/Icone/fi-rr-arrow-from-bottom.png";
// import crossIcon from "../../assets/icons/Icone/fi-rr-cross-circle.png";
import smartphoneIcon from "../../assets/icons/Icone/fi-rr-smartphone.png";

// import ClientInfo from "../components/buttons components/ContactInfo";
// import WebSiteInfo from "../components/buttons components/WebSiteInfo";
// import SocialInfo from "../components/buttons components/SocialInfo";
// import PdfInfo from "../components/buttons components/PdfInfo";
// import VideoInfo from "../components/buttons components/VideoInfo";

import Overview from "../Overview/Overview";
import AddEmployee from "./components/AddEmployee";
import DeleteDialog from "./components/DeleteDialog";
// import Meraviglia from "../components/Meraviglia";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#247DFF",
    fontWeight: "bold",
    boxShadow: theme.shadows[2],
    fontSize: 12,
  },
}));

function CompanyPanel() {
  const containerRef = useRef(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({
    name: "",
    surname: "",
    email: "",
    bio: "",
    username: "",
  });
  const [showListEmployees, setShowListEmployees] = useState(true);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  // const [currentComponent, setCurrentComponent] = useState(null);

  // const switchComponent = (data) => {
  //   let component;
  //   switch (data.type) {
  //     case "contact":
  //       component = <ClientInfo />;
  //       break;
  //     case "link":
  //       component = <WebSiteInfo />;
  //       break;
  //     case "pdf":
  //       component = <PdfInfo />;
  //       break;
  //     case "social":
  //       component = <SocialInfo />;
  //       break;
  //     case "video":
  //       component = <VideoInfo />;
  //       break;
  //     // Altri casi per differenti tipi
  //     default:
  //       component = null;
  //   }
  //   setCurrentComponent(component);
  // };

  //ADD EMPLOYEE

  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  // const [newEmployeeData, setNewEmployeeData] = useState({
  //   name: "",
  //   surname: "",
  //   email: "",
  //   bio: "",
  //   username: "",
  //   stato: false,
  //   // Add other fields as necessary
  // });

  const [isEditMode, setIsEditMode] = useState(false);

  // Toggle Add Employee Form
  const toggleAddEmployeeForm = () => {
    setIsEditMode(false);
    setShowEmployeeForm(!showEmployeeForm);
    toggleDrawer();
    setShowListEmployees(!showListEmployees);
  };

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    // Carica i dati da local storage all'avvio
    const savedEmployees = JSON.parse(localStorage.getItem("employees")) || [];
    setEmployees(savedEmployees);
  }, []);

  const handleAddEmployeeSubmit = (formData) => {
    const newEmployee = { ...formData };
    const updatedEmployees = [...employees, newEmployee];

    setEmployees(updatedEmployees);
    localStorage.setItem("employees", JSON.stringify(updatedEmployees));

    setShowEmployeeForm(false);
    setShowListEmployees(true);
  };

  //MODIFY EMPLOYEES

  const handleEmployeeEditSubmit = (editedData) => {
    const updatedEmployees = employees.map((emp) =>
      emp.id === editedData.id ? { ...editedData } : emp
    );

    setEmployees(updatedEmployees);

    localStorage.setItem("employees", JSON.stringify(updatedEmployees));
    setSelectedEmployee(null);
    setShowEmployeeForm(false);
    setShowListEmployees(true);
  };

  const handleEditClick = (employee) => {
    setIsEditMode(true);
    setSelectedEmployee(employee);
    setShowEmployeeForm(true);
    setShowListEmployees(false);
  };

  const switchView = () => {
    setShowListEmployees(!showListEmployees);
    setShowEmployeeForm(!showEmployeeForm);
  };

  //DELETE EMPLOYEE

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const handleDeleteIconClick = (employeeId) => {
    setEmployeeToDelete(employeeId);
    setOpenConfirmDialog(true);
  };

  const handleDeleteClick = (employeeId) => {
    const updatedEmployees = employees.filter((emp) => emp.id !== employeeId);
    setEmployees(updatedEmployees);
    localStorage.setItem("employees", JSON.stringify(updatedEmployees));
    setOpenConfirmDialog(false);
  };

  //CHANGE EMPLOYEE STATUS

  const handleActivateEmployee = (employeeId) => {
    const updatedEmployees = employees.map((emp) =>
      emp.id === employeeId ? { ...emp, stato: true } : emp
    );

    setEmployees(updatedEmployees);
    localStorage.setItem("employees", JSON.stringify(updatedEmployees));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AppBar
        position="static"
        elevation={1}
        sx={{ zIndex: 1, bgcolor: "#247DFF" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            style={{ marginRight: "25px" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem button onClick={toggleAddEmployeeForm}>
            <ListItemIcon>
              <img src={addIcon} alt="" />
            </ListItemIcon>
            <ListItemText primary="Aggiungi Dipendente" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
        sx={{
          flex: 1, // Prende tutto lo spazio rimanente dopo l'AppBar
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            width: "100%",
            height: "100%",
            borderRight: "1px solid #f5f5f5",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {showEmployeeForm && (
            <AddEmployee
              formData={selectedEmployee}
              onSubmit={
                isEditMode ? handleEmployeeEditSubmit : handleAddEmployeeSubmit
              }
              isEditMode={isEditMode}
              handleBack={switchView}
            />
          )}
          {!showEmployeeForm && (
            <>
              <Typography variant="h4" color="initial" margin={2}>
                Utenti attivati
              </Typography>

              {employees
                .filter((employee) => employee.stato === true)
                .map((employee) => (
                  <Box
                    key={employee.id}
                    p={2}
                    borderBottom="1px solid #f5f5f5"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      onClick={() => handleEmployeeClick(employee)}
                      sx={{ cursor: "pointer" }}
                    >
                      {employee.name} {employee.surname} - {employee.username}
                    </Typography>
                    <Box>
                      <LightTooltip title="Modifica questo dipendente">
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => handleEditClick(employee)}
                          sx={{ border: "solid 1px #f2f2f2", mx: 1 }}
                        >
                          <img src={EditIcon} alt="edit" />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Mostra un'anteprima del profilo">
                        <IconButton
                          aria-label="show"
                          onClick={() => handleEmployeeClick(employee)}
                          size="small"
                          sx={{ border: "solid 1px #f2f2f2", mx: 1 }}
                        >
                          <img src={smartphoneIcon} alt="preview" />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Elimina questo dipendente">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteIconClick(employee.id)}
                          size="small"
                          sx={{ border: "solid 1px #f2f2f2", mx: 1 }}
                        >
                          <img src={eliminaIcon} alt="delete" />
                        </IconButton>
                      </LightTooltip>
                    </Box>
                  </Box>
                ))}
              <DeleteDialog
                openConfirmDialog={openConfirmDialog}
                setOpenConfirmDialog={setOpenConfirmDialog}
                handleDeleteClick={handleDeleteClick}
                employeeToDelete={employeeToDelete}
              />
            </>
          )}
          {!showEmployeeForm && (
            <>
              <Typography variant="h4" color="initial" margin={2}>
                Utenti da Attivare
              </Typography>

              {employees
                .filter(
                  (employee) =>
                    employee.stato === false || employee.stato === undefined
                )
                .map((employee) => (
                  <Box
                    key={employee.id}
                    p={2}
                    borderBottom="1px solid #f5f5f5"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      onClick={() => handleEmployeeClick(employee)}
                      sx={{ cursor: "pointer" }}
                    >
                      {employee.name} {employee.surname}
                    </Typography>
                    <Box>
                      <LightTooltip title="Modifica questo dipendente">
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => handleEditClick(employee)}
                          sx={{ border: "solid 1px #f2f2f2", mx: 1 }}
                        >
                          <img src={EditIcon} alt="edit" />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Mostra un'anteprima del profilo">
                        <IconButton
                          aria-label="show"
                          onClick={() => handleEmployeeClick(employee)}
                          size="small"
                          sx={{ border: "solid 1px #f2f2f2", mx: 1 }}
                        >
                          <img src={smartphoneIcon} alt="preview" />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Elimina questo dipendente">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteIconClick(employee.id)}
                          size="small"
                          sx={{ border: "solid 1px #f2f2f2", mx: 1 }}
                        >
                          <img src={eliminaIcon} alt="delete" />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Attiva questo dipendente">
                        <IconButton
                          aria-label="activate"
                          onClick={() => handleActivateEmployee(employee.id)}
                          sx={{ border: "solid 1px #f2f2f2", mx: 1 }}
                        >
                          <img src={activateIcon} alt="activate" />
                        </IconButton>
                      </LightTooltip>
                    </Box>
                  </Box>
                ))}
              <DeleteDialog
                openConfirmDialog={openConfirmDialog}
                setOpenConfirmDialog={setOpenConfirmDialog}
                handleDeleteClick={handleDeleteClick}
                employeeToDelete={employeeToDelete}
              />
            </>
          )}
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          sx={{
            height: "100%",
            borderLeft: "1px solid #f5f5f5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: 5,
            mb: 3,
          }}
        >
          <DeviceFrameset device="iPhone X" style={{ overflow: "auto" }}>
            <div className="container" ref={containerRef}>
              <Overview
                userData={selectedEmployee}
                infoData={[]}
                // infoData={selectedEmployee?.infoData}
              />
            </div>
          </DeviceFrameset>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CompanyPanel;
