import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Container, Button, Link } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import IconFlag from "../../components/IconFlag";
import Meraviglia from "../../components/Meraviglia";
import { validateNfcCard, getNfcCard } from "../../api/nfc.js";
import { translations as t } from "../../assets/translations.js";
import styles from "./css/Signup.module.css";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";

export default function NfcVerification() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const { nfc_code } = useParams();
  const lang = useSelector((state) => state.userLang.value);
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const validateChar = (value) => {
    return !isNaN(Number(value));
  };

  useEffect(() => {
    setTimeout(() => {
      getNfcCard(nfc_code)
        .then((response) => {
          const username = response.data.user.user.username;
          const links = response.data.links;
          if (links.length === 1 && links[0].category === "Review") {
            window.open(`${links[0].url}`, "_blank");
          }
          navigate(`/${username}`);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 500);
  }, [navigate, nfc_code]);

  const handleVerification = () => {
    validateNfcCard(nfc_code, otp)
      .then((response) => {
        dispatch(
          setNotification({
            open: false,
          })
        );
        navigate(`/signup/${nfc_code}`, { state: response.data.nfc_passkey }); // Modifica questo percorso in base alle tue esigenze
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <IconFlag />

      <Container maxWidth="sm" sx={{ mb: 3 }}>
        <Typography variant="h3" fontWeight="500" color="initial">
          {t["pin-attiva"][lang]}
        </Typography>
        <Typography variant="h3" fontWeight="500" color="primary">
          {t["pin-card"][lang]}
        </Typography>
      </Container>
      <Container sx={{ p: 2, border: "solid 1px #F2F2F2", borderRadius: 5 }}>
        <Typography variant="subtitle1">
          {t["pin-code"][lang]}: <b>{nfc_code}</b>
        </Typography>
        <Typography
          variant="h5"
          fontWeight="500"
          color="initial"
          maxWidth="220px"
        >
          {t["pin-inserisci"][lang]}
        </Typography>

        <Container maxWidth="md" sx={{ p: 4 }}>
          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            validateChar={validateChar}
            length={4}
          />
        </Container>
      </Container>
      <Button
        variant="contained"
        color="primary"
        className={styles.buttonForm}
        onClick={handleVerification}
      >
        {t["check-Invia"][lang]}
      </Button>
      <Typography
        variant="body1"
        color="initial"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {t["check-hai-account"][lang]}
        <Link
          href="/login"
          variant="body1"
          underline="hover"
          color="inherit"
          marginLeft="5px"
        >
          <b>{t["check-accedi"][lang]}</b>
        </Link>
      </Typography>
      <Meraviglia />
    </Container>
  );
}
