import { useState, useRef, useEffect } from "react";
import { Button, Container, Box, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import meravigliaLogo from "../../assets/img/logo meraviglia.png";
import { translations as t } from "../../assets/translations.js";
import arrowLeftIcon from "../../assets/icons/Icone/fi-rr-arrow-left.png";
import { useSelector, useDispatch } from "react-redux";
import useGetProfile from "../../hooks/useGetProfile.jsx";
import { updateUserPreferences } from "../../api/preferences.js";
import CustomIconButton from "../../components/CustomIconButton";
import ButtonTooltip from "../../components/ButtonTooltip";
import BackgroundSelection from "./components/BackgroundSelection.jsx";
import styles from "./css/ModifyAppearance.module.css";
import ButtonStyleSelection from "./components/ButtonStyleSelection.jsx";
import TextStyleSelection from "./components/TextStyleSelection.jsx";
import Meraviglia from "../../components/Meraviglia/Meraviglia.jsx";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { customAppearanceDefaults } from "../../slices/userProfile.js";
import DialogUnsavedChanges from "./components/DialogUnsavedChanges";
import { setUserProfile } from "../../slices/userProfile.js";
import { setNotification } from "../../slices/notificationSnackbar.js";

export default function ModifyComponent() {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);
  const { userProfileLoading, userProfile } = useGetProfile();
  const { user_id } = useParams();
  const dispatch = useDispatch();
  // Refs
  const backgroundRef = useRef(null);
  const buttonStyleRef = useRef(null);
  const textStyleRef = useRef(null);

  // eslint-disable-next-line
  const [avatar, setAvatar] = useState(null);

  // Unsaved changes
  const [openDialog, setOpenDialog] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleCustomAppearanceSave = () => {
    // This is needed because input refs hold the value as a string
    const customizationParsedObject = {
      background: JSON.parse(backgroundRef.current.value),
      buttons: JSON.parse(buttonStyleRef.current.value),
      texts: JSON.parse(textStyleRef.current.value),
    };

    updateUserPreferences(customizationParsedObject)
      .then((response) => {
        const background = response.data.data.background;
        const buttons = response.data.data.buttons;
        const texts = response.data.data.texts;

        dispatch(
          setUserProfile({
            user: {
              ...userProfile.user,
              preference: {
                background: background,
                buttons: buttons,
                texts: texts,
              },
            },
          })
        );

        dispatch(
          setNotification({
            open: true,
            message: t["success-edit"][lang],
            severity: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
    setOpenDialog(false);
    setUnsavedChanges(false);
  };

  const handleCustomAppearanceReset = () => {
    dispatch(
      setUserProfile({
        user: {
          ...userProfile.user,
          preference: customAppearanceDefaults,
        },
      })
    );
    setUnsavedChanges(true);
    setOpenDialog(false);
  };

  //UNSAVED CHANGES

  const handleUnsavedChanges = () => {
    setUnsavedChanges(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleBack = () => {
    if (unsavedChanges) {
      setOpenDialog(true);
    } else {
      navigate("/appearance/" + user_id);
    }
  };

  // reset the body bgcolor to white
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.backgroundColor = "#fff";
  });

  return (
    <Container
      maxWidth="sm"
      className={styles.container}
      sx={{
        justifyContent: "start !important",
      }}
    >
      <ButtonTooltip title={t["tooltips"]["modify-profile"][lang]}>
        <Box className={styles.boxBack}>
          <CustomIconButton
            icon={arrowLeftIcon}
            iconAlt={"arrow left"}
            onClickFunction={handleBack}
          />
        </Box>
      </ButtonTooltip>
      <Typography
        variant="h5"
        fontWeight="500"
        color="initial"
        textAlign="center"
      >
        {t["modify-profile"][lang]}
      </Typography>
      <Box className={styles.avatarBox}>
        <Grid container direction="row">
          <Grid item xs={11}>
            {!userProfileLoading && (
              <img
                src={
                  userProfile.user.avatar
                    ? userProfile.user.avatar.file
                    : meravigliaLogo
                }
                alt="foto profilo"
                className={styles.avatar}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <BackgroundSelection
        setUnsavedChanges={handleUnsavedChanges}
        ref={backgroundRef}
      />
      <ButtonStyleSelection
        setUnsavedChanges={handleUnsavedChanges}
        ref={buttonStyleRef}
      />
      <TextStyleSelection
        setUnsavedChanges={handleUnsavedChanges}
        ref={textStyleRef}
      />

      <Button
        fullWidth
        className={styles.button}
        variant="outlined"
        color="primary"
        onClick={handleCustomAppearanceReset}
        endIcon={<RestartAltIcon />}
      >
        {t["modify_appearance"]["reset_all"][lang]}
      </Button>

      <Button
        fullWidth
        disabled={!unsavedChanges}
        className={styles.button}
        variant="contained"
        color="primary"
        onClick={handleCustomAppearanceSave}
      >
        {t["salva"][lang]}
      </Button>
      <Meraviglia />

      <DialogUnsavedChanges
        userId={user_id}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleCustomAppearanceSave={handleCustomAppearanceSave}
      />
    </Container>
  );
}
