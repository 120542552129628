import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { translations as t } from "../assets/translations";
import { updateUserProfile as updateUserProfileAPI } from "../api/profile";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../slices/notificationSnackbar";
import { setUserProfile } from "../slices/userProfile";

/**
 * Custom hook for updating user profile.
 *
 * @returns {Object} An object containing the updateUserProfile function and updateLoadingUserProfile state.
 */
const useUpdateProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);
  const [updateLoadingUserProfile, setUpdateLoadingUserProfile] =
    useState(false);
  const reduxUserProfile = useSelector((state) => state.userProfile);
  // eslint-disable-next-line
  const [userProfile, setUserProfileState] = useState(reduxUserProfile || null);

  const updateUserProfile = useCallback(
    // can be called with or without onSuccess and onError callbacks
    (data, onSuccess, onError) => {
      setUpdateLoadingUserProfile(true);
      updateUserProfileAPI(data)
        .then((response) => {
          dispatch(
            setUserProfile({
              user: response.data,
            })
          );
          setUserProfileState({
            user: response.data,
          });
          setUpdateLoadingUserProfile(false);
          if (onSuccess) {
            onSuccess(response);
          } else {
            dispatch(
              setNotification({
                open: true,
                message: t["success-edit"][lang],
                severity: "success",
              })
            );
            navigate("/overview");
          }
        })
        .catch((error) => {
          setUpdateLoadingUserProfile(false);
          if (onError) {
            onError(error);
          } else {
            dispatch(
              setNotification({
                open: true,
                message: error.response.data.data
                  ? error.response.data.data.error
                  : "Something went wrong.",
                severity: "error",
              })
            );
          }
        });
    },
    [dispatch, navigate, lang]
  );

  return { updateUserProfile, updateLoadingUserProfile };
};
export default useUpdateProfile;
