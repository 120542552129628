import { useState } from "react";
import { Typography, Container, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { translations as t } from "../../assets/translations.js";
import { forgotPassword } from "../../api/authentication.js";
import { setNotification } from "../../slices/notificationSnackbar.js";

import Meraviglia from "../../components/Meraviglia";
import IconFlag from "../../components/IconFlag";
import InputEmail from "../../components/InputEmail";
import styles from "./css/ForgotPassword.module.css";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);

  const redirectToLogin = () => {
    navigate("/login");
  };

  const handleVerification = () => {
    forgotPassword(email)
      .then(() => {
        setIsSend(true);
        dispatch(
          setNotification({
            open: false,
          })
        );
        // navigate(`/reset-password/${response.data.token}`);
      })
      .catch((error) => {
        setIsSend(false);
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };
  if (isSend) {
    return (
      <Container maxWidth="sm" className={styles.container}>
        <Container className={styles.card}>
          <Typography
            variant="h4"
            fontWeight="500"
            color="inherit"
            textAlign="center"
            sx={{ mt: 3 }}
          >
            {t["newpass-send1"][lang]}{" "}
            <span style={{ color: "#247DFF" /* Primary color */ }}>
              {t["newpass-send2"][lang]}
            </span>
          </Typography>
          <Container maxWidth="sm" sx={{ p: 3 }}>
            <Typography variant="body1" color="inherit" textAlign="center">
              {t["newpass-help1"][lang]}
            </Typography>
            <Typography
              variant="body1"
              fontWeight="500"
              color="inherit"
              textAlign="center"
            >
              {email}
            </Typography>
            <Typography variant="body1" color="inherit" textAlign="center">
              {t["newpass-help2"][lang]}
            </Typography>
          </Container>

          <Button
            variant="contained"
            color="primary"
            className={styles.buttonForm}
            onClick={redirectToLogin}
          >
            {t["newpass-accedi"][lang]}
          </Button>
        </Container>
        <Meraviglia />
      </Container>
    );
  } else {
    return (
      <Container maxWidth="sm" className={styles.container}>
        <IconFlag />
        <Container maxWidth="md" sx={{ mb: 3 }}>
          <Typography variant="h3" fontWeight="500" color="initial">
            {t["recpass-Recupero"][lang]}
          </Typography>
          <Typography variant="h3" fontWeight="500" color="primary">
            {t["recpass-Password"][lang]}
          </Typography>
        </Container>
        <Container className={styles.card}>
          <InputEmail
            email={email}
            setEmail={setEmail}
            isValidEmail={isValidEmail}
            setIsValidEmail={setIsValidEmail}
          />
          <Typography variant="subtitle2" sx={{ color: "#8E8E8E" }}>
            {t["recpass-otp"][lang]}{" "}
          </Typography>
        </Container>
        <Typography
          variant="body1"
          color="initial"
          sx={{ width: "100%", textAlign: "right" }}
        >
          <Link href="/login" variant="body1" underline="hover" color="inherit">
            <b>{t["login-accedi"][lang]}</b>
          </Link>
        </Typography>

        <Button
          variant="contained"
          color="primary"
          className={styles.buttonForm}
          onClick={handleVerification}
          disabled={!isValidEmail || !email}
        >
          {t["recpass-Invia"][lang]}
        </Button>
        <Meraviglia />
      </Container>
    );
  }
}
