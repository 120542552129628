import { axiosInstance, axiosInstancePublic } from "./common.js";

export const getNfcCard = (code) => {
  const apiEndpoint = `nfc/${code}/`;

  return axiosInstancePublic
    .get(apiEndpoint)
    .then((response) => {
      // return response.data.data.user.user.username;
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const validateNfcCard = (code, nfc_key) => {
  const apiEndpoint = `nfc/${code}/validate/`;

  return axiosInstancePublic
    .post(apiEndpoint, { nfc_key })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const assignNfcCard = (nfc_code, nfc_key) => {
  const apiEndpoint = `nfc/assign-user/`;

  const data = { nfc_code, nfc_key };

  return axiosInstance
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNfcData = (user_id) => {
  const apiEndpoint = `nfc/user/${user_id}/`;

  return axiosInstance
    .post(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const disconnectNfcCard = (nfc_card_id) => {
  const apiEndpoint = `nfc/${nfc_card_id}/unassign-user/`;

  return axiosInstance
    .post(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
