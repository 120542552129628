import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Container,
  TextField,
  Grid,
  FormControl,
} from "@mui/material";

import ValidateUsername from "../../components/ValidateUsername";
import CustomIconButton from "../../components/CustomIconButton";
import ButtonTooltip from "../../components/ButtonTooltip";
import Cropper from "../../components/CustomCropper";

import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";
import { uploadFile } from "../../api/storage.js";
import { createUserProfile } from "../../api/profile.js";

import arrowLeftIcon from "../../assets/icons/Icone/fi-rr-arrow-left.png";
import { translations as t } from "../../assets/translations.js";
import { helloBoxStyle as boxStyle, textFieldStyle } from "../../themes.js";
import styles from "./css/ProfileSetup.module.css";

export default function ProfileSetup() {
  const location = useLocation();
  const navigate = useNavigate();
  // Stepper
  const passedState = location.state || { nextStep: 0 };
  const [activeStep, setActiveStep] = useState(
    passedState.nextStep ? passedState.nextStep : 0
  );
  const totalSteps = 3;
  // Upload photo
  const [imageUrlLocal, setImageUrlLocal] = useState("");
  const fileInputRef = useRef(null);
  const [zeroImg, setZeroImg] = useState(true);
  const [file, setFile] = useState(null);
  // User Information
  const [avatar_id, setAvatar_id] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const landingUserName = useSelector(
    (state) => state.persistentRegistrationData.landingUserName
  );
  const [username, setUsername] = useState(landingUserName || "");
  const [bio, setBio] = useState("");
  const lang = useSelector((state) => state.userLang.value);
  const snackbar = useSelector((state) => state.notificationSnackbar);
  const dispatch = useDispatch();
  //CROP IMAGE
  const [openCropper, setOpenCropper] = useState(false);
  const [croppedUrlImage, setCroppedUrlImage] = useState(null);
  // eslint-disable-next-line
  const [isCropping, setIsCropping] = useState(false);

  const handleNext = () => {
    // Controlla se la Snackbar è aperta o se c'è un errore
    if (!snackbar.open) {
      setActiveStep((prevActiveStep) => {
        const nextStep = prevActiveStep + 1;
        if (nextStep >= totalSteps) {
          navigate("/intro");
        }
        return nextStep;
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Controllo dimensione file (es. 5MB)
      const maxFileSize = 1 * 1024 * 1024; // 5MB in byte

      if (file.size > maxFileSize) {
        // Se l'immagine supera la dimensione massima consentita
        dispatch(
          setNotification({
            open: true,
            message: t["snackbars"]["image-too-big"][lang],
            severity: "error",
          })
        );
      } else {
        setFile(file);
        const imageUrl = URL.createObjectURL(file);
        setImageUrlLocal(imageUrl);
        setOpenCropper(true);
        setIsCropping(true);
      }
      event.target.value = "";
    }
  };

  const onCropComplete = (croppedImage) => {
    setIsCropping(false); // Il ritaglio è terminato
    uploadCroppedImage(croppedImage);
    setOpenCropper(false);
  };

  const uploadCroppedImage = (croppedImage) => {
    uploadFile(croppedImage)
      .then((response) => {
        setAvatar_id(response.data.media_id);
        // SALVO IN LOCALE
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrlLocal(reader.result);
        };
        reader.readAsDataURL(file);
        handleNext();
        dispatch(
          setNotification({
            open: false,
          })
        );
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
    setZeroImg(false);
  };

  useEffect(() => {
    return () => {
      if (imageUrlLocal) {
        URL.revokeObjectURL(imageUrlLocal);
      }
    };
  }, [imageUrlLocal]);

  //CHECK USERNAME VALIDITY
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);
  const [invalidChars, setInvalidChars] = useState([]);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isNumeric, setIsNumeric] = useState(false);

  const validateProps = {
    isUsernameTaken,
    setIsUsernameTaken,
    lengthValid,
    setLengthValid,
    hasInvalidCharacters,
    setHasInvalidCharacters,
    invalidChars,
    setInvalidChars,
    checkingUsername,
    setCheckingUsername,
    debounceTimeout,
    setDebounceTimeout,
    isNumeric,
    setIsNumeric,
    username,
    setUsername,
    firstLoadData: {},
    required: true,
  };

  //DISABILITA BOTTONE
  // Aggiungi uno stato per controllare se il bottone avanti è disabilitato
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  // Controllo dello stato delle variabili per controllare se il bottone avanti sarà disabilitato
  useEffect(() => {
    const isNameEmpty = name === "";
    const isSurnameEmpty = surname === "";
    const isUsernameInvalid =
      isUsernameTaken || !lengthValid || hasInvalidCharacters || isNumeric;
    setIsNextButtonDisabled(isNameEmpty || isSurnameEmpty || isUsernameInvalid);
  }, [
    name,
    surname,
    isUsernameTaken,
    isNumeric,
    lengthValid,
    hasInvalidCharacters,
  ]);

  //INVIO DEI DATI ALL'ENDPOINT CREA UTENTE

  const postUserData = () => {
    const request = {
      first_name: name,
      last_name: surname,
      username: username,
    };
    if (avatar_id !== "") {
      request.avatar_id = avatar_id;
    }
    if (bio !== "") {
      request.bio = bio;
    }
    createUserProfile(request)
      .then(() => {
        dispatch(
          setNotification({
            open: false,
          })
        );
        handleNext();
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  //CROP IMAGE

  const cancelCrop = () => {
    // Close cropper
    setOpenCropper(false);
    setImageUrlLocal(croppedUrlImage);
    setFile(null);
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      {activeStep === 0 ? (
        <Box>
          <Box className={styles.boxSkip}>
            <Typography
              variant="body2"
              color="initial"
              className={styles.skip}
              onClick={() => {
                handleNext();
                setZeroImg(true);
              }}
            >
              {t["psu-salta"][lang]}
            </Typography>
          </Box>
          <Typography
            variant="h3"
            fontWeight="500"
            color="initial"
            textAlign="center"
          >
            {t["psu-title"][lang]}
          </Typography>
          <Box className={styles.mainBox}>
            <Box sx={boxStyle} />
          </Box>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="image/*"
          />
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={handleFileInputClick}
          >
            {t["psu-carica"][lang]}
          </Button>
          <Button
            fullWidth
            className={styles.button}
            variant="outlined"
            color="inherit"
            onClick={() => {
              handleNext();
              setZeroImg(true);
            }}
          >
            {t["psu-non-ora"][lang]}
          </Button>
        </Box>
      ) : null}
      {activeStep === 1 ? (
        <Box>
          <ButtonTooltip title={t["tooltips"]["back"][lang]}>
            <Box className={styles.boxBack}>
              <CustomIconButton
                icon={arrowLeftIcon}
                iconAlt={"arrow left"}
                onClickFunction={handleBack}
              />
            </Box>
          </ButtonTooltip>
          {!zeroImg ? (
            <Typography
              variant="h4"
              fontWeight="500"
              color="initial"
              textAlign="center"
            >
              {t["psu-complimenti"][lang]}
            </Typography>
          ) : (
            <Typography
              variant="h4"
              fontWeight="500"
              color="initial"
              textAlign="center"
            >
              {t["psu-domanda"][lang]}
            </Typography>
          )}
          <Box className={styles.mainBox}>
            <Box //IMMAGINE IN PREVIEW
              className={styles.imageBoxS2}
              sx={{
                backgroundImage: `url(${croppedUrlImage})`,
              }}
            />
          </Box>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {t["psu-prossimo"][lang]}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }} // Hide the file input
            accept="image/*" // Accept only images
          />
          <Button
            fullWidth
            className={styles.button}
            variant="outlined"
            onClick={handleFileInputClick}
            color="inherit"
          >
            {t["psu-cambia"][lang]}
          </Button>
        </Box>
      ) : null}
      {activeStep === 2 ? (
        <Box sx={{ width: "100%" }}>
          <ButtonTooltip title={t["tooltips"]["back"][lang]}>
            <Box className={styles.boxBack}>
              <CustomIconButton
                icon={arrowLeftIcon}
                iconAlt={"arrow left"}
                onClickFunction={handleBack}
              />
            </Box>
          </ButtonTooltip>
          <Typography
            variant="h5"
            fontWeight="500"
            color="initial"
            textAlign="center"
          >
            {t["psu-dati"][lang]}
          </Typography>
          <Box className={styles.mainBox}>
            <Box
              className={styles.imageBoxS3}
              sx={{
                backgroundImage: `url(${croppedUrlImage})`,
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Grid container className={styles.containerGrid} wrap="wrap">
              <FormControl
                id="field-first-name"
                fullWidth
                className={styles.form}
              >
                <TextField
                  required
                  id="first-name"
                  label={t["psu-l1"][lang]}
                  maxRows={4}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={textFieldStyle}
                />
              </FormControl>
              <FormControl
                id="field-last-name"
                fullWidth
                className={styles.form}
              >
                <TextField
                  required
                  id="last-name"
                  label={t["psu-l2"][lang]}
                  maxRows={4}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  sx={textFieldStyle}
                />
              </FormControl>
              <ValidateUsername props={validateProps} />
              <FormControl
                id="field-bio"
                fullWidth
                className={styles.form}
              >
                <TextField
                  id="bio"
                  label={t["psu-l4"][lang]}
                  multiline
                  rows={4}
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  sx={textFieldStyle}
                />
              </FormControl>
            </Grid>
          </Box>
          <Button
            fullWidth
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={postUserData}
            disabled={isNextButtonDisabled || checkingUsername}
          >
            {t["psu-fine"][lang]}
          </Button>
        </Box>
      ) : null}
      <Cropper
        open={openCropper}
        imageUrlLocal={imageUrlLocal}
        setImageUrlLocal={setImageUrlLocal}
        setOpenCropper={setOpenCropper}
        setFile={setFile}
        cancelCrop={cancelCrop}
        setCroppedUrlImage={setCroppedUrlImage}
        onCropComplete={onCropComplete}
      />
    </Container>
  );
}
