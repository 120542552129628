import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import meravigliaLogo from "../../assets/img/logo meraviglia.png";

import { translations as t } from "../../assets/translations.js";
import { textFieldStyle } from "../../themes.js";
import arrowLeftIcon from "../../assets/icons/Icone/fi-rr-arrow-left.png";
import editIcon from "../../assets/icons/Icone/fi-rr-pencil.png";
import { useSelector, useDispatch } from "react-redux";
import useUpdateProfile from "../../hooks/useUpdateProfile.jsx";
import useGetProfile from "../../hooks/useGetProfile.jsx";
import { setNotification } from "../../slices/notificationSnackbar.js";

import CustomIconButton from "../../components/CustomIconButton";
import ButtonTooltip from "../../components/ButtonTooltip";
import ValidateUsername from "../../components/ValidateUsername";
import styles from "./css/ModifyUser.module.css";

export default function ModifyComponent() {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);
  const location = useLocation();
  const { user_id } = useParams();
  const dispatch = useDispatch();
  // hook to update user profile
  const { updateUserProfile } = useUpdateProfile();

  const dataFromUploadImage = location.state || {};
  // eslint-disable-next-line
  const [avatar_id, setAvatar_id] = useState(
    dataFromUploadImage ? dataFromUploadImage.avatar_id : ""
  );
  const [avatar, setAvatar] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [firstLoadData, setFirstLoadData] = useState({});

  const handleBack = () => {
    navigate("/appearance/" + user_id);
  };

  //CHECK USERNAME VALIDITY
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);
  const [lengthValid, setLengthValid] = useState(true);
  const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);
  const [invalidChars, setInvalidChars] = useState([]);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isNumeric, setIsNumeric] = useState(false);

  const validateProps = {
    isUsernameTaken,
    setIsUsernameTaken,
    lengthValid,
    setLengthValid,
    hasInvalidCharacters,
    setHasInvalidCharacters,
    invalidChars,
    setInvalidChars,
    checkingUsername,
    setCheckingUsername,
    debounceTimeout,
    setDebounceTimeout,
    isNumeric,
    setIsNumeric,
    username,
    setUsername,
    firstLoadData,
    required: false,
    landingpage: false,
    modifyprofile: true,
  };

  //VALORI DI DEFAULT
  const { userProfileLoading, userProfile } = useGetProfile();

  useEffect(() => {
    if (!userProfileLoading) {
      setAvatar(
        userProfile.user.avatar ? userProfile.user.avatar.file : meravigliaLogo
      );
      setFirstName(userProfile.user.user.first_name);
      setLastName(userProfile.user.user.last_name);
      setUsername(userProfile.user.user.username);
      setBio(userProfile.user.bio);
      setFirstLoadData({
        avatar: userProfile.user.avatar
          ? userProfile.user.avatar.file
          : meravigliaLogo,
        first_name: userProfile.user.user.first_name,
        last_name: userProfile.user.user.last_name,
        username: userProfile.user.user.username,
        bio: userProfile.user.bio,
      });
      dispatch(
        setNotification({
          open: false,
        })
      );
    }
  }, [userProfileLoading, userProfile, dispatch]);

  //UPLOAD IMMAGINE
  // eslint-disable-next-line
  const [imageUrlLocal, setImageUrlLocal] = useState(
    dataFromUploadImage ? dataFromUploadImage.image : null
  );

  const handleSubmit = () => {
    const request = {
      first_name: firstName,
      last_name: lastName,
      username: username,
      bio: bio,
    };
    if (avatar_id) {
      request.avatar = avatar_id;
    }
    // call the hook to update the user profile
    updateUserProfile(request);
  };

  const navigateOnClick = () => {
    navigate(`/modify-user/${user_id}/upload-image`);
  };

  // reset the body bgcolor to white
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.backgroundColor = "#fff";
  });

  return (
    <Container maxWidth="sm" className={styles.container}>
      <ButtonTooltip title={t["tooltips"]["modify-profile"][lang]}>
        <Box className={styles.boxBack}>
          <CustomIconButton
            icon={arrowLeftIcon}
            iconAlt={"arrow left"}
            onClickFunction={handleBack}
          />
        </Box>
      </ButtonTooltip>
      <Typography
        variant="h5"
        fontWeight="500"
        color="initial"
        textAlign="center"
      >
        {t["modify-profile"][lang]}
      </Typography>
      <Box className={styles.avatarBox}>
        <Grid container direction="row">
          <Grid item xs={11}>
            <img
              src={
                avatar && !imageUrlLocal
                  ? avatar
                  : !avatar && !imageUrlLocal
                    ? meravigliaLogo
                    : imageUrlLocal || avatar
              }
              alt="foto profilo"
              className={styles.avatar}
              onClick={navigateOnClick}
            />
          </Grid>
          <Grid item xs={1}>
            <ButtonTooltip title={t["tooltips"]["modify-avatar"][lang]}>
              <CustomIconButton
                icon={editIcon}
                iconAlt={"edit"}
                onClickFunction={navigateOnClick}
              />
            </ButtonTooltip>
          </Grid>
        </Grid>
      </Box>
      <FormControl id="field-first-name" fullWidth className={styles.form}>
        <TextField
          name="first-name"
          label={t["psu-l1"][lang]}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          sx={textFieldStyle}
          InputProps={{
            startAdornment: (
              <img src={editIcon} alt="edit" className={styles.editicon} />
            ),
          }}
        />
      </FormControl>
      <FormControl id="field-last-name" fullWidth className={styles.form}>
        <TextField
          name="last-name"
          label={t["psu-l2"][lang]}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          sx={textFieldStyle}
          InputProps={{
            startAdornment: (
              <img src={editIcon} alt="edit" className={styles.editicon} />
            ),
          }}
        />
      </FormControl>
      <ValidateUsername props={validateProps} />
      <FormControl id="field-bio" fullWidth className={styles.form}>
        <TextField
          name="bio"
          label={t["psu-l4"][lang]}
          multiline
          rows={4}
          value={bio || ""}
          onChange={(e) => setBio(e.target.value)}
          sx={textFieldStyle}
          InputProps={{
            startAdornment: (
              <img src={editIcon} alt="edit" className={styles.editicon} />
            ),
          }}
        />
      </FormControl>
      <Button
        fullWidth
        className={styles.button + " " + styles["button-save"]}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        {t["salva"][lang]}
      </Button>
    </Container>
  );
}
