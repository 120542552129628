import { createSlice } from "@reduxjs/toolkit";

export const userLangSlice = createSlice({
  name: "userLang",
  initialState: {
    value: "en-US",
  },
  reducers: {
    setLang: (state, action) => {
      localStorage.setItem("language", action.payload.language);
      state.value = action.payload.language;
    },
  },
});

export const { setLang } = userLangSlice.actions;

export default userLangSlice.reducer;
