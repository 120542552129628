import React from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { translations as t } from "../../../assets/translations";
import { useSelector } from "react-redux";
import lightSectionImage from "../../../assets/img/landing_page_light_section_image.png";
import { useNavigate } from "react-router-dom";

const LandingLightSection = () => {
  const lang = useSelector((state) => state.userLang.value);
  const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: "#F2F2F2" }}>
      <Container
        sx={{
          padding: {
            xs: "50px 16px 50px 16px",
            md: "70px 32px 70px 32px",
          },
        }}
      >
        <Grid
          container
          rowGap={5}
          sx={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              padding: {
                xs: "0",
                md: "0 50px 0 0",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography variant="h4" color="black" fontWeight={700}>
              {t["landing_page"]["light_section_title"][lang]}
            </Typography>

            <Typography
              variant="subtitle1"
              color="black"
              fontWeight={200}
              sx={{
                marginTop: "25px",
              }}
            >
              {t["landing_page"]["light_section_subtitle"][lang]}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/signup")}
              sx={{
                borderRadius: "32px !important",
                padding: "8px 24px !important",
                marginTop: "25px",
              }}
            >
              {t["landing_page"]["register_free"][lang]}
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={lightSectionImage}
              alt="User example"
              style={{
                maxWidth: "100%",
                display: "block",
                margin: "0 auto",
                maxHeight: "450px",
                height: "450px",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingLightSection;
