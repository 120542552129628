import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import meravigliaLogoChiara from "../../assets/img/scritta meraviglia chiara.png";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import crossIcon from "../../assets/icons/Icone/fi-rr-cross-circle.png";

import InstagramIcon from "../../assets/icons/Social/instagram.png";
import FacebookIcon from "../../assets/icons/Social/facebook.png";
import LinkedInIcon from "../../assets/icons/Social/linkedin.png";
import ThreadsIcon from "../../assets/icons/Social/threads.png";
import TelegramIcon from "../../assets/icons/Social/telegram.png";
import WhatsAppIcon from "../../assets/icons/Social/whatsapp.png";
// import MessengerIcon from "../../assets/icons/Social/messenger.png";
import DiscordIcon from "../../assets/icons/Social/Discord.png";
import XIcon from "../../assets/icons/Social/x.png";
// import emailIcon from "../../assets/icons/Icone/fi-rr-envelope.png";
import MoreOptionsIcon from "../../assets/icons/Icone/fi-rr-upload.png";
import arrowLeftIcon from "../../assets/icons/Icone/fi-rr-arrow-left.png";
import angleSmallRight from "../../assets/icons/Icone/fi-rr-angle-small-right.png";
import { useSelector } from "react-redux";

import CopyLink from "./components/CopyLink";
import { translations as t } from "../../assets/translations.js";
import ButtonTooltip from "../../components/ButtonTooltip";
import styles from "./css/ItemsBottomDrawer.module.css";
import CustomIconButton from "../../components/CustomIconButton";

export default function ShareOptions() {
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [surname, setSurname] = useState("");
  const lang = useSelector((state) => state.userLang.value);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = location.state ? location.state : {};
    setName(userData ? userData.first_name : "");
    setSurname(userData ? userData.last_name : "");
    setUsername(userData ? userData.username : "");
  }, [location.state]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // reset the body bgcolor to white
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.backgroundColor = "#fff";
  });

  const actionSnackbar = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleCloseSnackbar}>
        OK
      </Button>
      <CustomIconButton
        icon={crossIcon}
        iconAlt={"close"}
        onClickFunction={handleCloseSnackbar}
      />
    </React.Fragment>
  );

  const handleShare = (platform) => {
    const shareUrl = `https://hellolink.pro/${username}`;
    const shareText = encodeURIComponent(
      "Dai un'occhiata👀 al mio profilo Hellolink.pro!👋\n"
    );
    const sharePlainText = encodeURIComponent(
      "Dai un'occhiata al mio profilo Hellolink.pro!\n"
    );

    switch (platform) {
      case "Facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&t=Hellolink.pro`,
          "_blank"
        );
        break;
      case "Instagram":
        navigator.clipboard.writeText(shareUrl).then(() => {
          alert(
            "Link copiato! Incollalo nella tua storia o post di Instagram."
          );
        });
        break;
      case "LinkedIn":
        window.open(
          `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
            shareUrl
          )}`,

          "_blank"
        );
        break;
      case "Telegram":
        window.open(
          `https://t.me/share/url?url=${shareUrl}&text=${shareText}`,
          "_blank"
        );
        break;
      case "WhatsApp":
        window.open(
          `https://api.whatsapp.com/send?text=${sharePlainText}%20${shareUrl}`,
          "_blank"
        );
        break;
      case "Discord":
        // Mostra un prompt all'utente con il link preselezionato per la copia
        const discordShareLink = `https://hellolink.pro/${username}`;
        navigator.clipboard
          .writeText(discordShareLink)
          .then(() => {
            // Notifica all'utente che il link è stato copiato
            alert(
              "Link copiato negli appunti! Incollalo in Discord per condividerlo."
            );
          })
          .catch((err) => {
            console.error("Errore durante la copia del link: ", err);
          });
        break;
      // case "Messenger":
      //   window.open(
      //     `fb-messenger://share?link=${shareUrl}`,
      //     "_blank"
      //   );
      //   break;
      case "X": // Twitter
        window.open(
          `https://twitter.com/intent/tweet?text=${shareText}&url=${encodeURIComponent(
            shareUrl
          )}`,
          "_blank"
        );
        break;
      case "Threads":
        // Non esiste un URL di condivisione standard per Threads, quindi potresti mostrare un messaggio o copiare l'URL negli appunti
        window.open(
          `https://threads.net/intent/post?text=${sharePlainText} ${shareUrl}`,
          "_blank"
        );
        break;
      case "Mail":
        window.open(
          `mailto:?subject=${encodeURIComponent(
            sharePlainText
          )}&body=${encodeURIComponent(
            "Dai un'occhiata al mio profilo Hellolink su: " + shareUrl
          )}`
        );
        break;
      case "More":
        handleMoreOptionShare();
        break;
      default:
        console.warn("Piattaforma non supportata o azione non riconosciuta");
    }
  };
  const handleBack = () => {
    navigate("/overview");
  };

  const shareOptions = [
    {
      name: t["share-condividi"][lang] + "Facebook",
      icon: FacebookIcon,
      action: "Facebook",
    },
    {
      name: "Condividi su Instagram",
      icon: InstagramIcon,
      action: "Instagram",
    },
    // {
    //   name: t["share-condividi2"][lang] + "Messenger",
    //   icon: MessengerIcon,
    //   action: "Messenger",
    // },
    {
      name: t["share-condividi"][lang] + "LinkedIn",
      icon: LinkedInIcon,
      action: "LinkedIn",
    },
    {
      name: t["share-condividi"][lang] + "Discord",
      icon: DiscordIcon,
      action: "Discord",
    },
    { name: t["share-condividi"][lang] + "X", icon: XIcon, action: "X" },
    {
      name: t["share-condividi"][lang] + "Threads",
      icon: ThreadsIcon,
      action: "Threads",
    },
    {
      name: t["share-condividi"][lang] + "Telegram",
      icon: TelegramIcon,
      action: "Telegram",
    },
    {
      name: t["share-condividi2"][lang] + "WhatsApp",
      icon: WhatsAppIcon,
      action: "WhatsApp",
    },
    // {
    //   name: t["share-condividi2"][lang] + "Mail",
    //   icon: emailIcon,
    //   action: "Mail",
    // },
    {
      name: t["share-piu-opzioni"][lang],
      icon: MoreOptionsIcon,
      action: "More",
    },
    // Aggiungi altri elementi se necessario
  ];

  const handleMoreOptionShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Condividi il tuo Meralink",
          text: "Condividi il tuo Meralink con chi vuoi!",
          url: `https://hellolink.pro/${username}`,
        });
      } catch (error) {
        console.error("Errore nella condivisione:", error);
      }
    } else {
      // Fallback per i browser che non supportano navigator.share
      try {
        // Copia l'URL negli appunti
        await navigator.clipboard.writeText(
          `https://hellolink.pro/${username}`
        );
        setOpenSnackbar(true);
      } catch (error) {
        console.error("Errore nella copia negli appunti:", error);
        alert(
          "La condivisione non è supportata nel tuo browser. Copia manualmente l'URL per condividerlo."
        );
      }
    }
  };

  return (
    <Box className={styles.optionContainer}>
      <ButtonTooltip title={t["tooltips"]["overview"][lang]}>
        <Box className={styles.backBox}>
          <CustomIconButton
            icon={arrowLeftIcon}
            iconAlt={"arrow left"}
            onClickFunction={handleBack}
          />
        </Box>
      </ButtonTooltip>
      <Container maxWidth="sm" className={styles.optionMainContainer}>
        <Typography variant="h6" fontWeight="500" className={styles.headerText}>
          {name} {surname}
        </Typography>
        <List className={styles.listItems}>
          {shareOptions.map((option, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                handleShare(option.action);
              }}
              className={styles.itemList}
            >
              <ListItemIcon>
                <img
                  src={option.icon}
                  alt={option.action}
                  className={styles.itemIcon}
                />
                <ListItemSecondaryAction>
                  <img
                    src={angleSmallRight}
                    alt="arrow-right"
                    className={styles.arrowIcon}
                  />
                </ListItemSecondaryAction>
              </ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItem>
          ))}
        </List>
        <Box className={styles.boxCopyLink}>
          <CopyLink
            username={username}
            openSnackbar={openSnackbar}
            setOpenSnackbar={setOpenSnackbar}
            actionSnackbar={actionSnackbar}
          />
        </Box>
      </Container>
      <Box className={styles.footerCard}>
        <Box className={styles.boxText}>
          <Typography variant="body2" sx={{ color: "#8E8E8E" }}>
            Powered by
          </Typography>
          <img
            src={meravigliaLogoChiara}
            alt="Meraviglia Logo"
            className={styles.imgMeraviglia}
          />
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
        message="Link copiato nella clipboard!"
        action={actionSnackbar}
        sx={{ borderRadius: 10 }}
      />
    </Box>
  );
}
