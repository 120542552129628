import {
  axiosInstance,
  axiosInstancePublic,
  BASE_URL_ENDPOINT,
  config,
} from "./common.js";

export const getPreferences = () => {
  const apiEndpoint = BASE_URL_ENDPOINT + "preferences/";

  return axiosInstance
    .get(apiEndpoint, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserPreferences = (data) => {
  const apiEndpoint = BASE_URL_ENDPOINT + "preferences/";

  return axiosInstance
    .put(apiEndpoint, data, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFonts = () => {
  const apiEndpoint = BASE_URL_ENDPOINT + "preferences/font-families/";

  return axiosInstancePublic
    .get(apiEndpoint, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
