import { axiosInstance } from "./common.js";

export const createUserProfile = (data) => {
  const apiEndpoint = "auth/profile/";

  return axiosInstance
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserProfile = () => {
  const apiEndpoint = "auth/profile/";

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserProfile = (data) => {
  const apiEndpoint = `auth/profile/`;

  return axiosInstance
    .patch(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
