import axios from "axios";
import { refreshAccessToken } from "./authentication.js";

// export const BASE_URL_ENDPOINT = process.env.REACT_APP_API_BASE_URL;
export const BASE_URL_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL &&
  process.env.REACT_APP_API_BASE_URL !== "http://localhost:3000/"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:8080/api/V0.0.0/";

// Configurazione Axios di base
export const axiosInstance = axios.create({
  baseURL: BASE_URL_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});
export const axiosInstancePublic = axios.create({
  baseURL: BASE_URL_ENDPOINT,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const config = {
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
};

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem("access")) || "";
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Se la risposta è ok, la restituiamo direttamente
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    // Controlla se l'errore è dovuto a un token scaduto
    if (
      (error.response.status === 500 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // Ottieni il refresh token dallo storage locale o dalla tua logica di storage
      const refreshToken = JSON.parse(localStorage.getItem("refresh"));

      // Prova a ottenere un nuovo access token usando il refresh token
      return refreshAccessToken(refreshToken).then((res) => {
        if (res.status === 200) {
          // Salva il nuovo token e aggiorna l'header della richiesta originale
          localStorage.setItem("access", JSON.stringify(res.data.access));
          localStorage.setItem("refresh", JSON.stringify(res.data.refresh));
          originalRequest.headers["Authorization"] =
            "Bearer " + res.data.access;

          // Ritorna la richiesta Axios originale con il nuovo token
          return axios(originalRequest);
        } else {
          return axios(originalRequest);
        }
      });
    }

    // Gestisci altri errori
    return Promise.reject(error);
  }
);
