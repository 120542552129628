import { Container, Typography, Button } from "@mui/material";
import NotFoundIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import notFoundImage from "../assets/img/user-not-found-404.png";
import { translations as t } from "../assets/translations.js";

const NotFoundPage = () => {
  const lang = useSelector((state) => state.userLang.value);
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <img
        src={notFoundImage}
        alt="User Not Found"
        style={{ maxWidth: "80%", height: "auto", marginBottom: "16px" }}
      />
      <Typography variant="h4" gutterBottom>
        {t["not-found-title"][lang]}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {t["not-found-message"][lang]}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/login")}
        sx={{ borderRadius: 10, py: 1.5, px: 5, m: 2, fontSize: 20 }}
      >
        <NotFoundIcon size="small" sx={{ mr: 1 }} />
        {t["not-found-button"][lang]}
      </Button>
    </Container>
  );
};

export default NotFoundPage;
