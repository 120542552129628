import React from "react";
import { Drawer, Container, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Meraviglia from "../Meraviglia";
import GrabItem from "../GrabItem";
import CategoryButton from "../CategoryButton";

import { translations as t } from "../../assets/translations.js";
import styles from "./DrawerButtonsMenu.module.css";

export default function CustomDrawer({
  drawerHeight,
  lang,
  drawerOpen,
  setDrawerOpen,
  menuItems,
}) {
  const navigate = useNavigate();

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleMenuItemClick = (item) => {
    if (item.name === "Contact") {
      navigate(`/add-link/contact/${item.id}`, {
        state: { category_id: item.id, icon: item.icon },
      });
    }
    if (item.name === "Links/Websites") {
      navigate(`/add-link/website/${item.id}`, {
        state: { category_id: item.id, icon: item.icon },
      });
    }
    if (item.name === "PDF") {
      navigate(`/add-link/pdf/${item.id}`, {
        state: { category_id: item.id, icon: item.icon },
      });
    }
    if (item.name === "Social Media") {
      navigate(`/add-link/social/${item.id}`, {
        state: { category_id: item.id, icon: item.icon },
      });
    }
    if (item.name === "Video") {
      navigate(`/add-link/video/${item.id}`, {
        state: { category_id: item.id, icon: item.icon },
      });
    }
    if (item.name === "Review") {
      navigate(`/add-link/review/${item.id}`, {
        state: { category_id: item.id, icon: item.icon },
      });
    }
    handleCloseDrawer();
  };
  return (
    <Drawer
      anchor="bottom"
      open={drawerOpen}
      onClose={handleCloseDrawer}
      classes={{ paper: styles.drawerPaper }}
    >
      <Container maxWidth="sm">
        <Box className={styles.buttonsContainer}>
          <GrabItem drawerHeight={drawerHeight} setDrawerOpen={setDrawerOpen} />
          <Typography variant="h4" className={styles.title}>
            {t["btsmenu-title"][lang]}
          </Typography>

          {menuItems.map((item) => (
            <CategoryButton
              key={item.id}
              item={item}
              handleMenuItemClick={handleMenuItemClick}
            />
          ))}
        </Box>
        <Meraviglia padding={1} />
      </Container>
    </Drawer>
  );
}
