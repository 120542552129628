import "./App.css";
import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from "react-redux";
import { setLang } from "./slices/userLang";
import { setIsAuth, setRefresh } from "./slices/userAuth";
import { setUserType } from "./slices/userTypeSlice.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import ProtectedRoute from "./components/ProtectedRoute";
import NotificationSnackbar from "./components/NotificationSnackbar";

import LandingPage from "./routes/LandingPage/LandingPage";
import Login from "./routes/Authentication/Login";
import Signup from "./routes/Authentication/Signup";
import UnauthorizedPage from "./routes/Authentication/UnauthorizedPage";
import AccountVerification from "./routes/Authentication/AccountVerification";
import ProfileSetup from "./routes/ProfileSetup/ProfileSetup";
import Intro from "./routes/ProfileSetup/Intro";
import Overview from "./routes/Overview/Overview";
import OverviewPreview from "./routes/Overview/OverviewPreview";
import ResetPassword from "./routes/ResetPassword/ResetPassword";
import ForgotPassword from "./routes/ForgotPassword/ForgotPassword";
import CompanyPannel from "./routes/CompanyPannel/CompanyPannel";
import ConfirmUpload from "./routes/ModifyUser/ConfirmUpload";
import ModifyUser from "./routes/ModifyUser/ModifyUser";
import NfcVerification from "./routes/Nfc/NfcVerification";
import NfcNavigator from "./routes/Nfc/NfcNavigator";
import LinkNfc from "./routes/LinkNfc/LinkNfc";
import ShareOptions from "./routes/Options/ShareOptions";
import SettingsOptions from "./routes/Options/SettingsOptions";
import ContactInfo from "./routes/Links/ContactInfo";
import WebSiteInfo from "./routes/Links/WebSiteInfo";
import SocialInfo from "./routes/Links/SocialInfo";
import PdfInfo from "./routes/Links/PdfInfo";
import VideoInfo from "./routes/Links/VideoInfo";
import ReviewInfo from "./routes/Links/ReviewInfo";

import ReactGA from "react-ga4";
import Hotjar from "@hotjar/browser";

import { theme } from "./themes.js";
import { BASE_URL_ENDPOINT } from "./api/common.js";
import ModifyAppearance from "./routes/ModifyAppearance/ModifyAppearance";
import Appearance from "./routes/Appearance/Appearance.jsx";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ffffff; /* Sostituisci #ffffff con il colore desiderato */
  }
`;

function AnimactionWrapper({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        height: "100%",
        overflowY: "auto",
      }}
    >
      {children}
    </motion.div>
  );
}

function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      <Route
        path="/"
        element={
          <AnimactionWrapper>
            <LandingPage />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/company-pannel"
        element={
          <ProtectedRoute requiredType="CU">
            <CompanyPannel />
          </ProtectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <AnimactionWrapper>
            <Login />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/signup"
        element={
          <AnimactionWrapper>
            <Signup />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/signup/:nfc_code"
        element={
          <AnimactionWrapper>
            <Signup />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/profile-setup/:user_id"
        element={
          <AnimactionWrapper>
            <ProtectedRoute requiredType="NU">
              <ProfileSetup />
            </ProtectedRoute>
          </AnimactionWrapper>
        }
      />
      <Route
        path="/intro"
        element={
          <AnimactionWrapper>
            <ProtectedRoute requiredType="NU">
              <Intro />
            </ProtectedRoute>
          </AnimactionWrapper>
        }
      />
      <Route
        path="/nfc/link/:user_id"
        element={
          <AnimactionWrapper>
            <LinkNfc />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/nfc/verification/:nfc_code/"
        element={
          <AnimactionWrapper>
            <NfcVerification />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/account-verification/:otp_id"
        element={
          <AnimactionWrapper>
            <AccountVerification />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <AnimactionWrapper>
            <ResetPassword />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <AnimactionWrapper>
            <ForgotPassword />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/overview"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <Overview />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-user/:user_id/upload-image"
        element={
          <AnimactionWrapper>
            <ProtectedRoute requiredType="NU">
              <ConfirmUpload />
            </ProtectedRoute>
          </AnimactionWrapper>
        }
      />
      <Route
        path="/appearance/:user_id"
        element={
          <AnimactionWrapper>
            <ProtectedRoute requiredType="NU">
              <Appearance />
            </ProtectedRoute>
          </AnimactionWrapper>
        }
      />
      <Route
        path="/modify-user/:user_id"
        element={
          <AnimactionWrapper>
            <ProtectedRoute requiredType="NU">
              <ModifyUser />
            </ProtectedRoute>
          </AnimactionWrapper>
        }
      />
      <Route
        path="/modify-appearance/:user_id"
        element={
          <AnimactionWrapper>
            <ProtectedRoute requiredType="NU">
              <ModifyAppearance />
            </ProtectedRoute>
          </AnimactionWrapper>
        }
      />
      <Route
        path="/add-link/contact/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <ContactInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/website/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <WebSiteInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/social/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <SocialInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/pdf/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <PdfInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/video/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <VideoInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/review/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <ReviewInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/contact/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <ContactInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/pdf/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <PdfInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/website/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <WebSiteInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/social/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <SocialInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/video/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <VideoInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/review/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <ReviewInfo />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/overview/share"
        element={
          <ProtectedRoute requiredType="NU">
            <AnimactionWrapper>
              <ShareOptions />
            </AnimactionWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/overview/settings"
        element={
          <AnimactionWrapper>
            <SettingsOptions />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/unauthorized"
        element={
          <AnimactionWrapper>
            <UnauthorizedPage />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/:username"
        element={
          <AnimactionWrapper>
            <OverviewPreview />
          </AnimactionWrapper>
        }
      />
      <Route
        path="/nfc/:code"
        element={
          <AnimactionWrapper>
            <NfcNavigator />
          </AnimactionWrapper>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Init application language
    if (!localStorage.getItem("language")) {
      const userLang = navigator.language || navigator.userLanguage;

      if (userLang !== "it-IT" && userLang !== "en-US") {
        dispatch(
          setLang({
            language: "en-US",
          })
        );
      } else {
        dispatch(
          setLang({
            language: userLang,
          })
        );
      }
    } else {
      dispatch(
        setLang({
          language: localStorage.getItem("language"),
        })
      );
    }
  }, [dispatch]);

  // Init isAuth & refresh
  const accessToken = localStorage.getItem("access");
  const refreshToken = localStorage.getItem("refresh");
  const userType = JSON.parse(localStorage.getItem("user_type"));
  useEffect(() => {
    if (accessToken) {
      dispatch(setIsAuth());
    }

    if (refreshToken) {
      dispatch(setRefresh());
    }

    if (userType) {
      dispatch(setUserType({ userType }));
    }
    // eslint-disable-next-line
  }, [accessToken, refreshToken, userType]);

  const applicationLanguage = useSelector((state) => state.userLang.value);

  // Analytics
  if (BASE_URL_ENDPOINT !== "http://localhost:8080/api/V0.0.0/") {
    const siteId = 3907563;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }

  const GOOGLE_TRANKING_ID = "G-744YRGECEQ";
  ReactGA.initialize(GOOGLE_TRANKING_ID);

  return (
    <>
      <GlobalStyle />
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RoutesWithAnimation applicationLanguage={applicationLanguage} />
          <NotificationSnackbar />
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
