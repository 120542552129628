import { axiosInstance } from "./common.js";

export const createLink = (data) => {
  const apiEndpoint = "links/";

  return axiosInstance
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserLinks = () => {
  const apiEndpoint = "links/";

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLinkData = (link_id) => {
  const apiEndpoint = `links/${link_id}/`;

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateLink = (link_id, data) => {
  const apiEndpoint = `links/${link_id}/`;

  return axiosInstance
    .put(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteLink = (link_id) => {
  const apiEndpoint = `links/${link_id}/`;

  return axiosInstance
    .delete(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getContactData = (link_id, contact_id) => {
  const apiEndpoint = `links/${link_id}/${contact_id}/`;

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPdfData = (link_id, pdf_id) => {
  const apiEndpoint = `links/${link_id}/${pdf_id}/`;
  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
