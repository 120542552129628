import { axiosInstancePublic } from "./common.js";

export const getPublicUserData = (username) => {
  const apiEndpoint = `public/${username}/`;

  return axiosInstancePublic
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPublicContactData = (link_id, contact_id) => {
  const apiEndpoint = `public/${link_id}/${contact_id}/`;

  return axiosInstancePublic
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPublicPdfData = (link_id, pdf_id) => {
  const apiEndpoint = `public/pdf/${link_id}/${pdf_id}/`;

  return axiosInstancePublic
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
