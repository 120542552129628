import { useEffect, useRef, useState } from "react";
import styles from "./css/Video.module.css";
import { Box, Typography, Button, Container } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { translations as t } from "../../assets/translations.js";
import { useNavigate } from "react-router-dom";
import Player from "@vimeo/player";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size={27} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body2"
          fontSize={13}
          component="div"
          color="textSecondary"
        >
          {`${10 - Math.round(props.value / 10)}`}s
        </Typography>
      </Box>
    </Box>
  );
}

export default function Video() {
  const navigate = useNavigate();
  const vimeoRef = useRef(null);
  const lang = useSelector((state) => state.userLang.value);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [progress, setProgress] = useState(0);
  const [videoStarted, setVideoStarted] = useState(false);

  const handleClick = () => {
    navigate("/overview");
  };

  useEffect(() => {
    let timer;
    if (videoStarted) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 10;
          if (newProgress >= 100) {
            clearInterval(timer);
            setIsButtonDisabled(false);
            return 100;
          }
          return newProgress;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [videoStarted]);

  useEffect(() => {
    const handleVideoPlay = () => {
      setVideoStarted(true);
    };

    const vimeoIframe = vimeoRef.current;
    const player = new Player(vimeoIframe);

    player.on("play", handleVideoPlay);

    return () => {
      player.off("play", handleVideoPlay);
    };
  }, []);

  return (
    <Container maxWidth="sm" className={styles.container}>
      <Box>
        <Typography
          variant="h4"
          fontWeight="500"
          color="initial"
          textAlign="center"
        >
          {t["psu-completata"][lang]}
        </Typography>
        <Box className={styles.mainBox}>
          <Box className={styles.boxVideo}>
            <div className={styles.videoWrapper}>
              <iframe
                ref={vimeoRef}
                src="https://player.vimeo.com/video/922980821?controls=1&amp;player_id=0&amp;app_id=58479"
                className={styles.video}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Video Intro"
              ></iframe>
            </div>
          </Box>
        </Box>
        <Button
          fullWidth
          className={styles.button}
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={isButtonDisabled}
        >
          {videoStarted ? (
            isButtonDisabled ? (
              <CircularProgressWithLabel value={progress} />
            ) : (
              t["psu-inizia"][lang]
            )
          ) : (
            t["psu-continua"][lang]
          )}
        </Button>
      </Box>
    </Container>
  );
}
