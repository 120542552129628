import { useState, forwardRef, useEffect } from "react";
import styles from "../css/ButtonStyleSelection.module.css";
import { Box, Typography, Button } from "@mui/material";
import ColorPickerComponent from "../../../components/ColorPicker";
import { ThemeProvider } from "@mui/material/styles";
import { themeButtonItemsMenu as themeButtons } from "../../../themes";
import Switch from "@mui/material/Switch";
import { useSelector } from "react-redux";
import { formatColor } from "../../../utils";
import { translations as t } from "../../../assets/translations";
import useGetProfile from "../../../hooks/useGetProfile";
import { customAppearanceDefaults } from "../../../slices/userProfile";
import FontSelection from "./FontSelection.jsx";

const ButtonStyleSelection = forwardRef((props, ref) => {
  const lang = useSelector((state) => state.userLang.value);
  const { userProfileLoading, userProfile } = useGetProfile();

  const initialButtonApperance = userProfileLoading
    ? customAppearanceDefaults.buttons
    : userProfile.user.preference.buttons;

  const [strokeIsActive, setStrokeIsActive] = useState(
    initialButtonApperance?.strokeIsActive
  );
  const [selectedColor, setSelectedColor] = useState({
    ...initialButtonApperance?.color,
  });

  const [selectedHoverColor, setSelectedHoverColor] = useState({
    ...initialButtonApperance?.onhover,
  });

  const [selectedTextFont, setSelectedTextFont] = useState({
    family: initialButtonApperance?.font.family,
  });

  const [selectedTextColor, setSelectedTextColor] = useState({
    color: {
      ...initialButtonApperance?.font.color,
    },
  });

  const [selectedStroke, setSelectedStroke] = useState({
    ...initialButtonApperance?.stroke,
  });

  useEffect(() => {
    console.log("Initial appearance loaded:", initialButtonApperance);

    setSelectedColor({
      ...initialButtonApperance?.color,
    });
    setSelectedHoverColor({
      ...initialButtonApperance?.onhover,
    });
    setSelectedTextFont({
      family: initialButtonApperance?.font.family,
    });
    setSelectedTextColor({
      color: {
        ...initialButtonApperance?.font.color,
      },
    });
    setSelectedStroke({
      ...initialButtonApperance?.stroke,
    });
    setStrokeIsActive(initialButtonApperance?.strokeIsActive);
  }, [initialButtonApperance]);

  const handleColorSelection = (color) => {
    setSelectedColor({
      hex: color.hex,
      rgb_r: color.rgb.r,
      rgb_b: color.rgb.b,
      rgb_g: color.rgb.g,
    });
    props.setUnsavedChanges(true);
  };

  const handleHoverColorSelection = (color) => {
    setSelectedHoverColor({
      hex: color.hex,
      rgb_r: color.rgb.r,
      rgb_g: color.rgb.g,
      rgb_b: color.rgb.b,
    });
    props.setUnsavedChanges(true);
  };

  const handleTextFontSelection = (family) => {
    setSelectedTextFont({
      family: family,
    });
    props.setUnsavedChanges(true);
  };

  const handleTextColorSelection = (color) => {
    setSelectedTextColor({
      color: {
        hex: color.hex,
        rgb_r: color.rgb.r,
        rgb_g: color.rgb.g,
        rgb_b: color.rgb.b,
      },
    });
    props.setUnsavedChanges(true);
  };

  const handleStrokeSelection = (color) => {
    setSelectedStroke({
      hex: color.hex,
      rgb_r: color.rgb.r,
      rgb_g: color.rgb.g,
      rgb_b: color.rgb.b,
    });
    props.setUnsavedChanges(true);
  };

  const handleStrokeSwitch = (event) => {
    setStrokeIsActive(event.target.checked);
    props.setUnsavedChanges(true);
  };

  const resetDefaultValues = () => {
    setSelectedColor({
      ...initialButtonApperance.color,
    });
    setSelectedHoverColor({
      ...initialButtonApperance.onhover,
    });
    setSelectedTextFont({
      family: initialButtonApperance.font.family,
    });
    setSelectedTextColor({
      color: {
        ...initialButtonApperance.font.color,
      },
    });
    setSelectedStroke({
      ...initialButtonApperance.stroke,
    });
    setStrokeIsActive(initialButtonApperance.strokeIsActive);
  };

  return (
    <Box className={styles.box}>
      <Typography variant="caption" className={styles.caption}>
        {t["modify_appearance"]["button"][lang]}
      </Typography>
      <Box maxWidth="sm" className={styles["container-button"]}>
        <Button onClick={resetDefaultValues} className={styles["reset-button"]}>
          {t["modify_appearance"]["reset"][lang]}
        </Button>
      </Box>
      <ThemeProvider theme={themeButtons}>
        <Button
          variant="outlined"
          sx={{
            backgroundColor: `rgb(${selectedColor.rgb_r} ${selectedColor.rgb_g} ${selectedColor.rgb_b})`,
            border: strokeIsActive
              ? `1px solid rgb(${selectedStroke.rgb_r} ${selectedStroke.rgb_g} ${selectedStroke.rgb_b}) !important`
              : undefined,
            margin: "0 auto",
            "&:hover": {
              backgroundColor: `rgb(${selectedHoverColor.rgb_r} ${selectedHoverColor.rgb_g} ${selectedHoverColor.rgb_b})`,
            },
          }}
        >
          <Typography
            variant="body1"
            noWrap
            color="initial"
            sx={{
              fontFamily: selectedTextFont.family,
              fontWeight: 700,
              fontSize: "13px",
              color: `rgb(${selectedTextColor.color.rgb_r} ${selectedTextColor.color.rgb_g} ${selectedTextColor.color.rgb_b}) !important`,
            }}
          >
            {t["modify_appearance"]["preview"][lang]}
          </Typography>
        </Button>
      </ThemeProvider>

      <ColorPickerComponent
        label={t["modify_appearance"]["color"][lang]}
        startingColor={formatColor(selectedColor)}
        onColorSelection={handleColorSelection}
      />

      <ColorPickerComponent
        label={t["modify_appearance"]["hover"][lang]}
        startingColor={formatColor(selectedHoverColor)}
        onColorSelection={handleHoverColorSelection}
      />
      <FontSelection
        label={"Font"}
        selectedFont={selectedTextFont.family}
        onTextFontSelection={handleTextFontSelection}
      />
      <ColorPickerComponent
        label={t["modify_appearance"]["text_color"][lang]}
        startingColor={formatColor(selectedTextColor.color)}
        onColorSelection={handleTextColorSelection}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <ColorPickerComponent
          label={t["modify_appearance"]["stroke"][lang]}
          startingColor={formatColor(selectedStroke)}
          onColorSelection={handleStrokeSelection}
        />

        <Switch
          checked={strokeIsActive}
          onChange={handleStrokeSwitch}
          inputProps={{ "aria-label": "controlled" }}
          color="primary"
          sx={{ position: "absolute", top: 28, right: 15 }}
        />
      </Box>
      {/* hidden input */}
      <input
        type="hidden"
        name="styles"
        value={JSON.stringify({
          color: selectedColor,
          onhover: selectedHoverColor,
          font: {
            color: selectedTextColor.color,
            family: selectedTextFont.family,
          },
          strokeIsActive: strokeIsActive,
          stroke: selectedStroke,
        })}
        ref={ref}
      />
    </Box>
  );
});

export default ButtonStyleSelection;
