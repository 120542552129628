import { useState, useEffect } from "react";
import {
  Typography,
  Drawer,
  Container,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Box,
} from "@mui/material";
import GrabItem from "../../../components/GrabItem";
import { useNavigate, useLocation } from "react-router-dom";
import uploadIcon from "../../../assets/icons/Icone/fi-rr-upload.png";
import assistenzaIcon from "../../../assets/icons/Icone/fi-rr-headset.png";
import loginIcon from "../../../assets/icons/Icone/fi-rr-user.png";
import settingsIcon from "../../../assets/icons/Icone/fi-rr-settings.png";
import smartphoneIcon from "../../../assets/icons/Icone/fi-rr-smartphone.png";
import signoutIcon from "../../../assets/icons/Icone/fi-rr-sign-out.png";
import angleSmallRight from "../../../assets/icons/Icone/fi-rr-angle-small-right.png";
import meravigliaLogoChiara from "../../../assets/img/scritta meraviglia chiara.png";
import Meraviglia from "../../../components/Meraviglia";
import editIcon from "../../../assets/icons/Icone/fi-rr-pencil.png";
import { useSelector, useDispatch } from "react-redux";
import { clearIsAuth, clearRefresh } from "../../../slices/userAuth.js";
import { clearUserType } from "../../../slices/userTypeSlice.js";
import { logoutFunction } from "../../../api/authentication.js";
import { clearUserProfile } from "../../../slices/userProfile.js";
import { translations as t } from "../../../assets/translations.js";
import styles from "../css/BottomDrawer.module.css";
import { setNotification } from "../../../slices/notificationSnackbar.js";

export default function BottomDrawer({
  drawerOpen,
  setDrawerOpen,
  drawerHeight,
  userData,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [idUser, setIdUser] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [surname, setSurname] = useState("");
  const lang = useSelector((state) => state.userLang.value);
  const isAuth = useSelector((state) => state.userAuth.isAuth);

  const [pathUsername, setPathUsername] = useState("");

  const menuItems = (
    isAuth,
    handleLogout,
    handleShare,
    navigate,
    pathUsername,
    username,
    handleSettings,
    handleAssistance,
    lang
  ) => [
    ...(isAuth
      ? [
          {
            text: t["botdrw-logout"][lang],
            icon: signoutIcon,
            action: handleLogout,
          },
          {
            text: t["botdrw-share"][lang],
            icon: uploadIcon,
            action: handleShare,
          },
          location.pathname === "/overview"
            ? {
                text: t["botdrw-preview"][lang],
                icon: smartphoneIcon,
                action: () => navigate(pathUsername, { state: username }),
              }
            : {
                text: t["botdrw-edit"][lang],
                icon: editIcon,
                action: () => handleModifyProfile(),
              },
          {
            text: t["botdrw-settings"][lang],
            icon: settingsIcon,
            action: handleSettings,
          },
          {
            text: t["botdrw-assistenza"][lang],
            icon: assistenzaIcon,
            action: handleAssistance,
          },
        ]
      : [
          {
            text: t["botdrw-login"][lang],
            icon: loginIcon,
            action: () => navigate("/login"),
          },
          {
            text: t["botdrw-settings"][lang],
            icon: settingsIcon,
            action: handleSettings,
          },
          {
            text: t["botdrw-assistenza"][lang],
            icon: assistenzaIcon,
            action: handleAssistance,
          },
        ]),
  ];

  useEffect(() => {
    setIdUser(userData ? userData.id : "");
    setName(userData ? userData.first_name : "");
    setSurname(userData ? userData.last_name : "");
    setUsername(userData ? userData.username : "");
    setPathUsername(userData ? `/${userData.username}` : "");
  }, [userData, drawerOpen]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleShare = () => {
    navigate("/overview/share", { state: userData });
  };

  const handleSettings = () => {
    navigate("/overview/settings", { state: idUser });
  };

  const handleModifyProfile = () => {
    navigate(`/appearance/${idUser}`);
  };

  const handleAssistance = () => {
    const phoneNumber = "+393802184259";

    const message = encodeURIComponent(
      "Ciao! Vorrei chiederti aiuto riguardo a un problema che ho avuto con Hellolink.pro..."
    );

    // URL per avviare una chat WhatsApp con il messaggio predefinito
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };
  async function handleLogout() {
    const refresh = JSON.parse(localStorage.getItem("refresh"));

    logoutFunction(refresh)
      .then(() => {
        dispatch(clearIsAuth());
        dispatch(clearRefresh());
        dispatch(clearUserType());
        dispatch(clearUserProfile());
        navigate("/login");
      })
      .catch(() => {
        dispatch(
          setNotification({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          })
        );
        navigate("/login");
      });
  }

  return (
    <Drawer
      anchor={"bottom"}
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      classes={{ paper: styles.drawerPaper }}
    >
      <GrabItem drawerHeight={drawerHeight} setDrawerOpen={setDrawerOpen} />
      <Typography variant="body1" sx={{ color: "black", mt: 2, mb: 1 }}>
        {name + " " + surname}
      </Typography>
      <Container maxWidth="sm" className={styles.container}>
        <List sx={{ width: "100%", my: 2 }}>
          {menuItems(
            isAuth,
            handleLogout,
            handleShare,
            navigate,
            pathUsername,
            username,
            handleSettings,
            handleAssistance,
            lang
          ).map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={item.action}
              className={styles.listItem}
            >
              <ListItemIcon className={styles.listItemIcon}>
                <img
                  src={item.icon}
                  alt={`${item.text} icon`}
                  className={styles.imgIcon}
                />
              </ListItemIcon>
              <ListItemText primary={item.text} />
              <ListItemSecondaryAction>
                <img
                  src={angleSmallRight}
                  alt="arrow-right"
                  className={styles.secondaryActionIcon}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Container>

      <Box className={styles.grabItemBox}>
        {isAuth ? (
          <Box className={styles.poweredByBox}>
            <Typography variant="body2" sx={{ color: "#8E8E8E" }}>
              Powered by
            </Typography>
            <img
              src={meravigliaLogoChiara}
              alt="Meraviglia Logo"
              style={{ marginLeft: "8px", height: "50px", width: "auto" }}
            />
          </Box>
        ) : (
          <Box className={styles.poweredByBox}>
            <Typography variant="h5" sx={{ color: "white", mt: 2 }}>
              {t["botdrw-meralink"][lang]}
            </Typography>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/signup")}
                  sx={{
                    width: { xs: "150px", sm: "190px" },
                    borderRadius: 10,
                    color: "white",
                    fontSize: { xs: 12, sm: 16 },
                  }}
                >
                  {t["botdrw-registrati"][lang]}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    window.open("https://amzn.eu/d/9DBd4QI", "_blank")
                  }
                  sx={{
                    width: { xs: "150px", sm: "190px" },
                    borderRadius: 10,
                    color: "white",
                    fontSize: { xs: 12, sm: 16 },
                  }}
                >
                  {t["botdrw-carta"][lang]}
                </Button>
              </Grid>
            </Grid>
            <Meraviglia chiara={true} />
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
