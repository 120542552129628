import { useState } from "react";
import {
  FormLabel,
  Container,
  Box,
  Typography,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Divider,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import crossIcon from "../../../assets/icons/Icone/fi-rr-cross-circle.png";
import trashIcon from "../../../assets/icons/Icone/fi-rr-trash.png";
import { translations as t } from "../../../assets/translations.js";
import {
  menuIconsHeaderProps as menuIconsProps,
  textFieldStyle,
  IOSSwitch,
} from "../../../themes.js";
import { deleteLink } from "../../../api/links.js";

import SearchSelect from "./SearchSelect";
import CustomIconButton from "../../../components/CustomIconButton";
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog"; // Aggiusta il percorso in base alla tua struttura

import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../../slices/notificationSnackbar.js";

import styles from "../css/Header.module.css";

export default function Header({
  headerData,
  onTitleChange,
  onStyleChange,
  onIconChange,
  onIconIdChange,
  onSwitchChange,
}) {
  const [selectedIcon, setSelectedIcon] = useState(headerData.icon);
  // eslint-disable-next-line
  const [selectedIconId, setSelectedIconId] = useState(
    headerData.icon ? headerData.icon.id : null
  );
  const [selectedType, setSelectedType] = useState(headerData.style);
  const id = headerData ? headerData.id : null;
  const lang = useSelector((state) => state.userLang.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
    onStyleChange(event.target.value);
  };

  const handleChangeIcon = (event, selectedIconId) => {
    setSelectedIcon(event.target.value);
    onIconChange(event.target.value);
    onIconIdChange(selectedIconId);
    setSelectedIconId(selectedIconId);
  };

  const handleCloseInfo = () => {
    // navigate("/overview");
    window.history.back();
  };

  //NASCONDI BOTTONE
  // eslint-disable-next-line
  const [isSwitchOn, setIsSwitchOn] = useState(headerData.show); // Assumi che lo switch sia inizialmente attivo

  // Gestisci il cambiamento dello switch
  const handleSwitchChange = (event) => {
    setIsSwitchOn(event.target.checked);
    onSwitchChange(event.target.checked);
  };

  //MANAGE DIALOG DELETE
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDeleteButton = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteLink(id)
      .then(() => {
        navigate("/overview");
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
    setIsDeleteDialogOpen(false);
  };

  return (
    <Container className={styles.container}>
      <Box className={styles.flexSpaceBetween}>
        <Box sx={{ maxWidth: id ? "50%" : "80%" }}>
          <Typography
            variant="h5"
            fontWeight="500"
            color="initial"
            className={styles.titleTypography}
          >
            {headerData.title ? headerData.title : t["header-title"][lang]}
          </Typography>
        </Box>
        <Grid item className={styles.gridSelects}>
          {id ? (
            <Grid item sx={{ m: 2 }}>
              <CustomIconButton
                icon={trashIcon}
                iconAlt={"delete button"}
                onClickFunction={handleDeleteButton}
              />
            </Grid>
          ) : null}
          <Grid item sx={{ m: 2 }}>
            <CustomIconButton
              icon={crossIcon}
              iconAlt={"close"}
              onClickFunction={handleCloseInfo}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0} className={styles.gridSelectsContainer}>
        <Grid item xs={5}>
          <FormControl id="select-style">
            <FormLabel sx={{ ml: 2 }}> {t["header-style"][lang]}</FormLabel>
            <Select
              name="style"
              value={selectedType}
              onChange={handleChangeType}
              size="small"
              MenuProps={menuIconsProps}
              className={styles.selectStyle}
            >
              <MenuItem className={styles.menuItemStyle} value={"BUTTON"}>
                {t["header-style-button"][lang]}
              </MenuItem>
              <MenuItem className={styles.menuItemStyle} value={"ICON"}>
                {t["header-style-icon"][lang]}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={7}>
          <FormControl id="select-icon" fullWidth>
            <FormLabel sx={{ ml: 2 }}> {t["header-icon"][lang]}</FormLabel>
            <SearchSelect
              handleChangeIcon={handleChangeIcon}
              selectedIcon={selectedIcon}
              page={headerData.page}
            />
          </FormControl>
        </Grid>
        {id ? (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Paper elevation={0} className={styles.paperStyle}>
              <Typography variant="body1" color="initial">
                {t["header-show"][lang]}
              </Typography>
              <IOSSwitch
                // defaultChecked
                checked={headerData.show}
                onChange={handleSwitchChange}
              />
            </Paper>
          </Grid>
        ) : null}
      </Grid>
      <FormControl id="field-title" fullWidth>
        <TextField
          name="title"
          label={t["header-title"][lang]}
          sx={textFieldStyle}
          value={headerData.title}
          onChange={(e) => onTitleChange(e.target.value)}
        />
      </FormControl>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
      />
      <Divider sx={{ mt: 2 }} />
    </Container>
  );
}
