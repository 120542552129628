import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../css/ModifyAppearance.module.css";
import { translations as t } from "../../../assets/translations.js";

function DialogUnsavedChanges({
  userId,
  openDialog,
  handleCloseDialog,
  handleCustomAppearanceSave,
}) {
  const lang = useSelector((state) => state.userLang.value);
  const navigate = useNavigate();

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      PaperProps={{ sx: { borderRadius: 10, p: 1 } }}
    >
      <DialogTitle variant="h4">
        {t["modify_appearance"]["modal_title"][lang]}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t["modify_appearance"]["modal"][lang]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={styles["dialog-button"]} onClick={handleCloseDialog}>
          {t["modify_appearance"]["cancel"][lang]}
        </Button>
        <Button
          className={styles["dialog-button"]}
          variant="contained"
          color="error"
          onClick={() => navigate("/appearance/" + userId)}
        >
          {t["modify_appearance"]["leave"][lang]}
        </Button>
        <Button
          className={styles["dialog-button"]}
          variant="contained"
          onClick={handleCustomAppearanceSave}
          autoFocus
        >
          {t["modify_appearance"]["save"][lang]}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogUnsavedChanges;
