import { createSlice } from "@reduxjs/toolkit";

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: {
    isAuth: false,
    refresh: false,
  },
  reducers: {
    setIsAuth: (state) => {
      state.isAuth = true;
    },
    setRefresh: (state) => {
      state.refresh = true;
    },
    clearIsAuth: (state) => {
      state.isAuth = false;
    },
    clearRefresh: (state) => {
      state.refresh = false;
    },
  },
});

export const { setIsAuth, setRefresh, clearIsAuth, clearRefresh } =
  userAuthSlice.actions;

export default userAuthSlice.reducer;
