import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Typography, Container, Button, Link } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setIsAuth, setRefresh } from "../../slices/userAuth.js";
import { setUserType } from "../../slices/userTypeSlice.js";
import { verifyEmailOtp } from "../../api/authentication.js";
import { translations as t } from "../../assets/translations.js";
import { jwtDecode } from "jwt-decode";
import { setNotification } from "../../slices/notificationSnackbar.js";
import Meraviglia from "../../components/Meraviglia";
import IconFlag from "../../components/IconFlag";

import styles from "./css/Signup.module.css";

export default function AccountVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { otp_id } = useParams();
  const dataFromState = location.state || {};
  const email = dataFromState.email || "user@email.com";
  const [otp, setOtp] = useState("");
  const lang = useSelector((state) => state.userLang.value);
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const matchIsNumeric = (text) => {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  };

  const validateChar = (value) => {
    return matchIsNumeric(value);
  };

  const handleVerification = () => {
    const request = {
      otp_id: otp_id,
      otp_code: otp,
    };

    verifyEmailOtp(request)
      .then((response) => {
        localStorage.setItem("refresh", JSON.stringify(response.data.refresh));
        dispatch(setRefresh());
        localStorage.setItem("access", JSON.stringify(response.data.access));
        dispatch(setIsAuth());
        localStorage.setItem(
          "user_type",
          JSON.stringify(response.data.user_type)
        );
        dispatch(setUserType({ userType: response.data.user_type }));

        const decoded = jwtDecode(localStorage.getItem("access"));
        if (response.data.user_type === "NU") {
          navigate(`/profile-setup/${decoded.user_id}`, {
            state: { email, nextStep: 0 },
          });
        } else {
          navigate("/company-pannel");
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  const handleComplete = (value) => {
    const request = {
      otp_id: otp_id,
      otp_code: value,
    };

    verifyEmailOtp(request)
      .then((response) => {
        localStorage.setItem("refresh", JSON.stringify(response.data.refresh));
        dispatch(setRefresh());
        localStorage.setItem("access", JSON.stringify(response.data.access));
        dispatch(setIsAuth());
        localStorage.setItem(
          "user_type",
          JSON.stringify(response.data.user_type)
        );
        dispatch(setUserType({ userType: response.data.user_type }));

        const decoded = jwtDecode(localStorage.getItem("access"));
        if (response.data.user_type === "NU") {
          navigate(`/profile-setup/${decoded.user_id}`, {
            state: { email, nextStep: 0 },
          });
        } else {
          navigate("/company-pannel");
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <IconFlag />

      <Container maxWidth="md" sx={{ mb: 3 }}>
        <Typography variant="h3" fontWeight="500" color="initial">
          {t["check-verifica"][lang]}
        </Typography>
        <Typography variant="h3" fontWeight="500" color="primary">
          {t["check-account"][lang]}
        </Typography>
      </Container>
      <Container sx={{ p: 2, border: "solid 1px #F2F2F2", borderRadius: 5 }}>
        <Typography
          variant="h5"
          fontWeight="500"
          color="initial"
          maxWidth="220px"
        >
          {t["check-controlla-email"][lang]}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "#8E8E8E" }}>
          {t["check-otp"][lang]}
        </Typography>
        <Typography variant="subtitle1" color="initial">
          <b>{email}</b>
        </Typography>
        <Container maxWidth="md" sx={{ p: 4 }}>
          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            validateChar={validateChar}
            onComplete={handleComplete}
            length={4}
          />
        </Container>
      </Container>
      <Button
        variant="contained"
        color="primary"
        className={styles.buttonForm}
        onClick={handleVerification}
      >
        {t["check-Invia"][lang]}
      </Button>
      <Typography
        variant="body1"
        color="initial"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {t["check-hai-account"][lang]}
        <Link
          href="/login"
          variant="body1"
          underline="hover"
          color="inherit"
          marginLeft="5px"
        >
          <b> {t["check-accedi"][lang]}</b>
        </Link>
      </Typography>
      <Meraviglia />
    </Container>
  );
}
