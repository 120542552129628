import { createSlice } from "@reduxjs/toolkit";

export const persistentRegistrationDataSlice = createSlice({
  name: "persistentRegistrationData",
  initialState: {
    landingUserName: null,
  },
  reducers: {
    setLandingUserName: (state, action) => {
      state.landingUserName = action.payload.landingUserName;
    },
  },
});

export const { setLandingUserName } = persistentRegistrationDataSlice.actions;

export default persistentRegistrationDataSlice.reducer;
