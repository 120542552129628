import { useState } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText, Box } from "@mui/material";

import ButtonTooltip from "../ButtonTooltip";
import CustomIconButton from "../CustomIconButton";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setLang } from "../../slices/userLang";

import flagIcon from "../../assets/icons/Icone/fi-rr-flag.png";
import { translations as t } from "../../assets/translations.js";
import styles from "./IconFlag.module.css";

const IconFlag = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.userLang.value);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={styles.container}>
      <ButtonTooltip title={t["tooltips"]["change-language"][lang]}>
        <CustomIconButton
          icon={flagIcon}
          iconAlt={"flag"}
          onClickFunction={handleClick}
        />
      </ButtonTooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: 5 } }}
      >
        <MenuItem
          onClick={() => {
            dispatch(
              setLang({
                language: "it-IT",
              })
            );
            handleClose();
          }}
          className={styles.itemMenu}
        >
          <ListItemIcon className={styles.icon}>
            <img src={flagIcon} alt="flag" className={styles.icon} />
          </ListItemIcon>
          <ListItemText primary="Italiano" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              setLang({
                language: "en-US",
              })
            );
            handleClose();
          }}
          className={styles.itemMenu}
        >
          <ListItemIcon className={styles.icon}>
            <img src={flagIcon} alt="flag" className={styles.icon} />
          </ListItemIcon>
          <ListItemText primary="English" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default IconFlag;
