import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import { useSelector } from "react-redux";
import CustomIconButton from "../CustomIconButton";

import lockIcon from "../../assets/icons/Icone/fi-rr-lock.png";
import eyeIcon from "../../assets/icons/Icone/fi-rr-eye.png";
import eyeCrossedIcon from "../../assets/icons/Icone/fi-rr-eye-crossed.png";
import { translations as t } from "../../assets/translations.js";
import { textFieldStyleLoginSignup as textFieldStyle } from "../../themes.js";
import styles from "./InputPassword.module.css";

export default function InputPassword2({ setPassword2 }) {
  const [showPassword2, setShowPassword2] = useState(false);
  const lang = useSelector((state) => state.userLang.value);

  const handleClickShowPassword2 = () => setShowPassword2((show2) => !show2);

  return (
    <FormControl id="input-password2" className={styles.formControlPassword}>
      <TextField
        name="password2"
        required
        type={showPassword2 ? "text" : "password"}
        onChange={(e) => setPassword2(e.target.value)}
        InputProps={{
          startAdornment: (
            <img src={lockIcon} alt="lock icon" className={styles.icon} />
          ),
          endAdornment: (
            <CustomIconButton
              icon={showPassword2 ? eyeCrossedIcon : eyeIcon}
              iconAlt={showPassword2 ? "eye-crossed" : "eye"}
              onClickFunction={handleClickShowPassword2}
            />
          ),
          size: "large",
          sx: {
            borderRadius: 10,
          },
        }}
        sx={textFieldStyle}
        placeholder={t["password2"][lang]}
      />
    </FormControl>
  );
}
