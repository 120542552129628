import { createSlice } from "@reduxjs/toolkit";

export const notificationSnackbarSlice = createSlice({
  name: "notificationSnackbar",
  initialState: {
    open: false,
    message: "",
    severity: "",
  },
  reducers: {
    handleSnackbarClose: (state, action) => {
      if (action.payload.reason === "clickaway") {
        return state;
      }

      state.open = false;
    },
    setNotification: (state, action) => {
      state.open = action.payload.open ? action.payload.open : state.open;
      state.message = action.payload.message
        ? action.payload.message
        : state.message;
      state.severity = action.payload.severity
        ? action.payload.severity
        : state.severity;
    },
  },
});

export const { handleSnackbarClose, setNotification } =
  notificationSnackbarSlice.actions;

export default notificationSnackbarSlice.reducer;
