import { useState, useEffect } from "react";

import {
  Menu,
  MenuItem,
  Box,
  ListItemSecondaryAction,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Typography,
} from "@mui/material";
import arrowLeftIcon from "../../assets/icons/Icone/fi-rr-arrow-left.png";
import angleSmallRight from "../../assets/icons/Icone/fi-rr-angle-small-right.png";
import flagIcon from "../../assets/icons/Icone/fi-rr-flag.png";
import meravigliaLogoChiara from "../../assets/img/scritta meraviglia chiara.png";
import userIcon from "../../assets/icons/Icone/fi-rr-user.png";
import cardIcon from "../../assets/icons/Icone/fi-rr-credit-card.png";
import { translations as t } from "../../assets/translations.js";

import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { setLang } from "../../slices/userLang.js";

import { useSelector, useDispatch } from "react-redux";
import CustomIconButton from "../../components/CustomIconButton";
import ButtonTooltip from "../../components/ButtonTooltip";

import styles from "./css/ItemsBottomDrawer.module.css";

export default function SettingsOptions() {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);
  const access = localStorage.getItem("access");
  const dispatch = useDispatch();
  const isLoggedIn = !!access;

  const handleBack = () => {
    if (isLoggedIn) navigate("/overview");
    else navigate(-1);
  };

  const settingsOption = [
    {
      name: t["setting-set-profile"][lang],
      icon: userIcon,
      action: "user info",
      public: false,
    },
    {
      name: t["setting-lingua"][lang],
      icon: flagIcon,
      action: "language",
      public: true,
    },
    {
      name: t["link-your-nfc"][lang],
      icon: cardIcon,
      action: "nfc",
      public: false,
    },
  ];
  const visibleOptions = settingsOption.filter(
    (option) => isLoggedIn || option.public
  );
  // Language option
  const [anchorEl, setAnchorEl] = useState(null);

  const changeLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Modify Profile
  const changeUserInfoClick = () => {
    if (isLoggedIn) {
      const decoded = jwtDecode(access);
      navigate(`/appearance/${decoded.user_id}`, {
        state: { from: "settings" },
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkNfc = () => {
    const decoded = jwtDecode(localStorage.getItem("access"));
    const user_id = decoded.user_id;
    navigate(`/nfc/link/${user_id}`);
  };

  // reset the body bgcolor to white
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.backgroundColor = "#fff";
  });

  return (
    <Box className={styles.optionContainer}>
      <ButtonTooltip title={t["tooltips"]["overview"][lang]}>
        <Box className={styles.backBox}>
          <CustomIconButton
            icon={arrowLeftIcon}
            iconAlt={"arrow left"}
            onClickFunction={handleBack}
          />
        </Box>
      </ButtonTooltip>
      <Container maxWidth="sm" className={styles.optionMainContainer}>
        <Typography variant="h6" fontWeight="500" className={styles.headerText}>
          {t["setting-title"][lang]}
        </Typography>
        <List className={styles.listItems}>
          {visibleOptions.map((option, index) => (
            <ListItem
              button
              key={index}
              onClick={(event) => {
                if (option.action === "language") {
                  changeLanguageClick(event);
                } else if (option.action === "user info") {
                  changeUserInfoClick(event);
                } else if (option.action === "nfc") {
                  handleLinkNfc();
                }
              }}
              className={styles.itemList}
            >
              <ListItemIcon>
                <img
                  src={option.icon}
                  alt={option.action}
                  className={styles.itemIcon}
                />
                <ListItemSecondaryAction>
                  <img
                    src={angleSmallRight}
                    alt="arrow-right"
                    className={styles.arrowIcon}
                  />
                </ListItemSecondaryAction>
              </ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItem>
          ))}
        </List>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{ sx: { borderRadius: 5 } }}
        >
          <MenuItem
            onClick={() => {
              dispatch(
                setLang({
                  language: "it-IT",
                })
              );
              handleClose();
            }}
            sx={{ borderRadius: 5, mx: 1, my: "5px" }}
          >
            <ListItemIcon>
              <img className={styles.icon} src={flagIcon} alt="flag" />
            </ListItemIcon>
            <ListItemText primary="Italiano" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(
                setLang({
                  language: "en-US",
                })
              );
              handleClose();
            }}
            sx={{ borderRadius: 5, mx: 1, my: "5px" }}
          >
            <ListItemIcon>
              <img className={styles.icon} src={flagIcon} alt="flag" />
            </ListItemIcon>
            <ListItemText primary="English" />
          </MenuItem>
        </Menu>
      </Container>
      <Box className={styles.footerCard}>
        <Box className={styles.boxText}>
          <Typography variant="body2" sx={{ color: "#8E8E8E" }}>
            Powered by
          </Typography>
          <img
            src={meravigliaLogoChiara}
            alt="Meraviglia Logo"
            className={styles.imgMeraviglia}
          />
        </Box>
      </Box>
    </Box>
  );
}
