import { useState, useEffect } from "react";
import styles from "./ColorPickerComponent.module.css";
import { Box, Typography, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ColorPicker } from "primereact/colorpicker";
import { hexToRgb } from "../../utils.js";

const ColorPickerComponent = ({ label, startingColor, onColorSelection }) => {
  const [pickerColor, setPickerColor] = useState(startingColor);
  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    setPickerColor(startingColor);
  }, [startingColor]);

  const onChangeColor = (e) => {
    const color = {
      hex: "#" + e.value,
      rgb: hexToRgb(e.value),
    };
    onColorSelection(color);
    setPickerColor(color);
  };

  const handleInputChange = (e) => {
    // force to format "#RRGGBB"
    let value = e.target.value;
    if (value[0] !== "#") {
      value = "#" + value;
    }
    value = value.slice(0, 7);
    value = value.replace(/[^#a-fA-F0-9]/g, "");

    const rgbValue =
      value.length === 7
        ? hexToRgb(value.replace("#", ""))
        : { r: 0, g: 0, b: 0 };

    const color = {
      hex: value,
      rgb: rgbValue,
    };
    onColorSelection(color);
    setPickerColor(color);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          position: "relative",
          mt: label ? "20px" : "0px",
          borderRadius: "20px",
          padding: label ? "15px 20px" : "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
        }}
      >
        {label ?? (
          <Typography variant="caption" className={styles.caption}>
            {label}
          </Typography>
        )}
        <Accordion
          sx={{
            borderRadius: "20px",
            boxShadow: "none",
            border: "none !important",
            padding: "0",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Box
              sx={{
                height: "27px",
                width: "27px",
                bgcolor: `rgb(${pickerColor.rgb.r} ${pickerColor.rgb.g} ${pickerColor.rgb.b})`,
                position: "relative",
                zIndex: 1,
                borderRadius: "4px",
              }}
              onClick={() => setPickerOpen(!pickerOpen)}
            />
            <Typography
              variant="caption"
              sx={{
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {pickerColor?.hex?.toUpperCase()}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ColorPicker
              format="hex"
              value={startingColor.hex}
              onChange={onChangeColor}
              inline
              className={styles["color-picker"]}
            />
            <TextField
              value={pickerColor?.hex?.toUpperCase()}
              onChange={handleInputChange}
              inputProps={{ maxLength: 7 }}
              sx={{
                width: "100%",
                maxWidth: "300px",
                marginTop: "20px",
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default ColorPickerComponent;
