import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getNfcCard } from "../../api/nfc.js";
import NotFoundPage from "../../components/NotFoundPage";
import { useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar";

export default function NfcNavigator() {
  const navigate = useNavigate();
  const location = useLocation();
  const { code } = useParams();
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();

  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  // };

  useEffect(() => {
    // clearCacheData();
    getNfcCard(code)
      .then((response) => {
        const username = response.data.user.user.username;
        const links = response.data.links;
        setIsValid(true);
        if (links.length === 1 && links[0].category === "Review") {
          window.open(`${links[0].url}`, "_blank");
        }
        navigate(`/${username}`);
      })
      .catch((error) => {
        setIsValid(false);
        if (error.response.data.redirect_url) {
          window.location.href = error.response.data.redirect_url;
        } else {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        }
      });
  }, [location.pathname, code, navigate, dispatch]);

  if (isValid) {
    return <></>;
  } else {
    return <NotFoundPage />;
  }
}
