import { useEffect, useRef, useState } from "react";
import { Button, Typography, Container, Box, FormGroup } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import FileIcon from "../../assets/icons/Icone/fi-rr-file.png";
import CloudIcon from "../../assets/icons/Icone/fi-rr-cloud-upload.png";
import downloadIcon from "../../assets/icons/Icone/fi-rr-download-primary.png";
import Header from "./components/Header";
import Meraviglia from "../../components/Meraviglia";
import { translations as t } from "../../assets/translations.js";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";

import styles from "./css/LinksInfo.module.css";
import { createLink, getPdfData, updateLink } from "../../api/links.js";
import { uploadFile } from "../../api/storage.js";

export default function PdfInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const dataFromButton = location.state || {};
  const categoryId = dataFromButton.category_id;
  const icon = dataFromButton.icon;
  const idPdf = dataFromButton ? dataFromButton.pdf : null;
  const isAuth = useSelector((state) => state.userAuth.isAuth);
  const idAlreadyCreate = dataFromButton ? dataFromButton.id : null;
  const lang = useSelector((state) => state.userLang.value);
  const fileInputRef = useRef();
  const [pdfFileId, setPdfFileId] = useState(null);
  const [pdfDownload, setPdfDownload] = useState(null);
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [linkData, setLinkData] = useState({
    title: "",
    pdf: "",
    type: "BUTTON",
    icon: icon,
    is_visible: true,
  });

  const [headerData, setHeaderData] = useState({
    id: idAlreadyCreate,
    isAuth: isAuth,
    title: linkData.title,
    style: dataFromButton.type ? dataFromButton.type : linkData.type,
    icon: linkData.icon,
    icon_id: linkData.icon.id,
    show: linkData.is_visible,
    page: "pdf",
  });

  useEffect(() => {
    if (idAlreadyCreate && idPdf) {
      getPdfData(idAlreadyCreate, idPdf)
        .then((response) => {
          setPdfDownload(response.data.pdf.file);
          setLinkData({
            id: response.data.id,
            title: response.data.title,
            type: response.data.type,
            category: response.data.category,
            icon: response.data.icon,
            is_visible: response.data.is_visible,
            order: response.data.order,
          });
          setHeaderData({
            id: response.data.id,
            title: response.data.title,
            style: response.data.type,
            show: response.data.is_visible,
            icon: response.data.icon,
          });

          dispatch(
            setNotification({
              open: false,
            })
          );
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    }
  }, [idAlreadyCreate, idPdf, dispatch]);

  const [fileName, setFileName] = useState("");

  const uploadPdf = (event) => {
    const file = event.target.files[0];
    const maxSize = 7 * 1024 * 1024; // 7MB

    if (file && file.size <= maxSize) {
      setFileName(file.name);

      uploadFile(file)
        .then((response) => {
          setPdfFileId(response.data.media_id);
          dispatch(
            setNotification({
              open: true,
              message: t["upload-success"][lang],
              severity: "success",
            })
          );
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error?.response?.data?.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
      event.target.value = "";
    } else {
      dispatch(
        setNotification({
          open: true,
          message: t["snackbars"]["pdf-too-big"][lang],
          severity: "error",
        })
      );
    }
  };

  const handleHeaderDataChange = (key, value) => {
    setHeaderData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    if (idAlreadyCreate === undefined) {
      const request = {
        title: headerData.title ? headerData.title : t["btsmenu-pdf"][lang],
        type: headerData.style,
        category_id: categoryId,
        icon_id: headerData.icon_id,
        is_visible: headerData.show,
        pdf_id: pdfFileId,
      };
      createLink(request)
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );

          navigate("/overview", { state: { newData: response.data } });
        })
        .catch((error) => {
          console.error(error.data);
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    } else {
      const request = {
        title: headerData.title ? headerData.title : t["btsmenu-pdf"][lang],
        type: headerData.style,
        category_id: dataFromButton.category.id,
        icon_id: headerData.icon_id || linkData.icon.id,
        is_visible: headerData.show,
        pdf_id: idPdf,
      };

      updateLink(idAlreadyCreate, request)
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );
          navigate("/overview", { state: { newData: response.data } });
        })
        .catch((error) => {
          console.error(error.data);
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    }
  };

  //Invio del form con premendo invio
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <FormGroup onKeyPress={handleKeyPress} className={styles.formGroup}>
        <Header
          headerData={headerData}
          onTitleChange={(value) => handleHeaderDataChange("title", value)}
          onStyleChange={(value) => handleHeaderDataChange("style", value)}
          onIconChange={(value) => handleHeaderDataChange("icon", value)}
          onIconIdChange={(value) => handleHeaderDataChange("icon_id", value)}
          onSwitchChange={(value) => handleHeaderDataChange("show", value)}
        />
        <Container
          maxWidth="sm"
          component="main"
          className={styles.mainContainer}
        >
          <Box>
            <Box className={styles.centerContainer}>
              <img src={FileIcon} alt="FileIcon" className={styles.iconTitle} />
              <Typography
                variant="h5"
                fontWeight="500"
                color="initial"
                sx={{ px: 2 }}
              >
                {t["pdf-title"][lang]}
              </Typography>
            </Box>
            <Box className={styles.uploadBox}>
              {/* Bottone per il caricamento del file */}
              {!pdfDownload && !fileName ? (
                <Button
                  color="primary"
                  variant="contained"
                  component="label"
                  className={styles.uploadButton}
                >
                  {t["pdf-carica"][lang]}
                  <img
                    src={CloudIcon}
                    alt="CloudIcon"
                    className={styles.icon}
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={uploadPdf}
                    accept=".pdf"
                    hidden
                  />
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  component="label"
                  className={styles.uploadButton}
                  onClick={() => window.open(pdfDownload, "_blank")}
                >
                  {t["pdf-scarica"][lang]}
                  <img
                    src={downloadIcon}
                    alt="DownloadIcon"
                    className={styles.icon}
                  />
                </Button>
              )}
              {fileName ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginTop: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    className={styles.uploadedFileName}
                  >
                    {fileName}
                  </Typography>
                  <img src={FileIcon} alt="FileIcon" className={styles.icon} />
                </Box>
              ) : null}

              {idAlreadyCreate && (
                <Button
                  color="primary"
                  variant="contained"
                  component="label"
                  className={styles.uploadButton}
                  sx={{ mt: 2 }}
                >
                  {t["pdf-cambia"][lang]}
                  <img
                    src={CloudIcon}
                    alt="CloudIcon"
                    className={styles.icon}
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={uploadPdf}
                    accept=".pdf"
                    hidden
                  />
                </Button>
              )}
            </Box>
          </Box>
          <Box className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={styles.formButton}
            >
              {t["pdf-salva"][lang]}
            </Button>
          </Box>
        </Container>
        <Meraviglia />
      </FormGroup>
    </Container>
  );
}
