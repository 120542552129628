import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { handleSnackbarClose } from "../../slices/notificationSnackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NotificationSnackbar() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.notificationSnackbar);

  // Check if the message is this format {'key': 'value'}
  const regex = /\{'([^']*)':\s*'([^']*)'\}/;
  const match = snackbar.message.match(regex);

  // If it is, extract the correct part
  const message = match ? match[2] : snackbar.message;

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={(event, reason) => {
        dispatch(handleSnackbarClose({ reason }));
      }}
    >
      <Alert
        onClose={(event, reason) => {
          dispatch(handleSnackbarClose({ reason }));
        }}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
