import React from "react";
import IconButton from "@mui/material/IconButton";
import styles from "./CustomIconButton.module.css";
import ProcessedImage from "../ProcessedImage/ProcessedImage";

const CustomIconButton = React.forwardRef((props, ref) => {
  const {
    icon,
    iconAlt,
    opacity = 1,
    onClickFunction,
    targetColor,
    filter,
    ...otherProps
  } = props;

  return (
    <>
      <IconButton
        onClick={onClickFunction}
        className={styles.iconButton}
        sx={{ opacity: opacity }}
        ref={ref}
        {...otherProps}
      >
        <ProcessedImage
          source={icon}
          alt={iconAlt}
          style={{
            maxHeight: "24px",
            maxWidth: "24px",
            filter: filter ? filter : "none",
          }}
          targetColor={targetColor}
        />
      </IconButton>
    </>
  );
});

export default CustomIconButton;
