import Hero from "./components/Hero.jsx";
import LandingDarkSection from "./components/LandingDarkSection.jsx";
import LandingLightSection from "./components/LandingLightSection.jsx";
import FAQ from "./components/FAQ.jsx";
import { useCheckRefreshToken } from "../../hooks/useCheckRefreshToken.jsx";

const LandingPage = () => {

  useCheckRefreshToken();

  return (
    <>
      <Hero />
      <LandingDarkSection />
      <LandingLightSection />
      <FAQ />
    </>
  );
};

export default LandingPage;
