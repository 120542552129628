import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import meravigliaLogo from "../../assets/img/scritta meraviglia.png";
import meravigliaLogoChiara from "../../assets/img/scritta meraviglia chiara.png";
import { useSelector } from "react-redux";
import { rgbColorIsDark } from "../../utils";

import styles from "./Meraviglia.module.css";

export default function Meraviglia({
  chiara,
  padding,
  isOverview,
  localCustomAppearance,
}) {
  const reduxCustomAppearance = useSelector(
    (state) => state.userProfile?.user?.preference
  );
  const customAppearance = localCustomAppearance || reduxCustomAppearance;

  const backgroundIsFlat = customAppearance?.background?.type === "flat";
  const backgroundIsDark = rgbColorIsDark(
    customAppearance?.background?.color?.rgb_r,
    customAppearance?.background?.color?.rgb_g,
    customAppearance?.background?.color?.rgb_b
  );

  const logoNeedsFilter = backgroundIsFlat && backgroundIsDark && isOverview;

  const handleClick = () => {
    window.open("https://meraviglialab.com/", "_blank");
  };

  return (
    <Box
      onClick={handleClick}
      className={styles.containerMeraviglia}
      sx={{
        m: padding || 3,
        margin: "auto 0 0 0 !important",
        minHeight: "50px",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: logoNeedsFilter ? "white" : "#8E8E8E",
        }}
      >
        Powered by
      </Typography>
      <img
        src={chiara ? meravigliaLogoChiara : meravigliaLogo}
        alt="Meraviglia Logo"
        className={styles.img}
        style={{
          filter: `brightness(${logoNeedsFilter ? "0" : "1"}) invert(${logoNeedsFilter ? "1" : "0"})`,
        }}
      />
    </Box>
  );
}
