import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { translations as t } from "../../../assets/translations.js";

const TermsAndConditions = ({ onAccept }) => {
  const [isChecked, setIsChecked] = useState(false);
  const lang = useSelector(state => state.userLang.value)

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    onAccept(event.target.checked);
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            name="termsAndConditions"
          />
        }
        sx={{ mx: 1 }}
        label={
          <span>
            {t["terms-conditions-1"][lang]}
            <Link href="https://meraviglialab.com/privacy" underline="hover">
              {t["terms-conditions-2"][lang]}
            </Link>
            .
          </span>
        }
      />
    </div>
  );
};

export default TermsAndConditions;
