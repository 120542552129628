import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Importa l'icona di check
import ErrorIcon from "@mui/icons-material/Error"; // Importa l'icona di errore

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { translations as t } from "../../../assets/translations.js";
import styles from "../css/ValidatePassword.module.css";
import { useSelector } from "react-redux";

export default function ValidatePassword({ password }) {
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const lang = useSelector((state) => state.userLang.value);

  const updatePasswordValidation = (password) => {
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/\d/.test(password));
  };

  useEffect(() => {
    updatePasswordValidation(password);
  }, [password]);

  // Funzione per renderizzare i requisiti della password
  const renderPasswordRequirement = (isValid, text) => (
    <Typography
      variant="body2"
      color={isValid ? "inherit" : "error"}
      className={styles.text}
    >
      {isValid && (
        <CheckCircleIcon color="success" className={styles.circleErrorIcon} />
      )}
      {!isValid && (
        <ErrorIcon color="error" className={styles.circleErrorIcon} />
      )}
      {text}
    </Typography>
  );

  if (
    (isLengthValid && hasUpperCase && hasLowerCase && hasNumber) ||
    password.length < 1
  ) {
    return null;
  } else {
    return (
      <Box sx={{ mx: 2.5 }}>
        <Typography variant="subtitle1" color={"error"} className={styles.text}>
          {t["password-requisiti-introduzione"][lang]}
        </Typography>

        {renderPasswordRequirement(
          hasUpperCase,
          t["password-lettera-maiuscola"][lang]
        )}
        {renderPasswordRequirement(
          isLengthValid,
          t["password-lunghezza-minima"][lang]
        )}
        {renderPasswordRequirement(
          hasLowerCase,
          t["password-lettera-minuscola"][lang]
        )}
        {renderPasswordRequirement(
          hasNumber,
          t["password-carattere-numeric"][lang]
        )}
      </Box>
    );
  }
}
