import { createSlice } from "@reduxjs/toolkit";

export const customAppearanceDefaults = {
  background: {
    color: {
      hex: "#ffffff",
      rgb_r: "255",
      rgb_g: "255",
      rgb_b: "255",
    },
    type: "flat",
  },
  buttons: {
    font: {
      color: {
        hex: "#000000",
        rgb_r: "0",
        rgb_g: "0",
        rgb_b: "0",
      },
      family: "Poppins",
    },
    color: {
      hex: "#f5f5f5",
      rgb_r: "245",
      rgb_g: "245",
      rgb_b: "245",
    },
    stroke: {
      hex: "#000000",
      rgb_r: "0",
      rgb_g: "0",
      rgb_b: "0",
    },
    onhover: {
      hex: "#f0f0f0",
      rgb_r: "240",
      rgb_g: "240",
      rgb_b: "240",
    },
    strokeIsActive: false,
  },
  texts: {
    color: {
      hex: "#000000",
      rgb_r: "0",
      rgb_g: "0",
      rgb_b: "0",
    },
    family: "Poppins",
  },
};

const initialState = {
  user: null,
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.user = action.payload.user;
    },
    clearUserProfile: (state) => {
      state.user = null;
    },
  },
});

export const { setUserProfile, clearUserProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
