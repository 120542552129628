import { useEffect, useState } from "react";
import { getUserProfile } from "../api/profile";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../slices/notificationSnackbar";
import { jwtDecode } from "jwt-decode";
import { translations as t } from "../assets/translations";
import { setUserProfile } from "../slices/userProfile";

/**
 * Custom hook to fetch user profile data.
 *
 * @returns {Object} An object containing userProfileLoading and reduxUserProfile.
 */
const useGetProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);
  const [userProfileLoading, setUserProfileLoading] = useState(true);
  const reduxUserProfile = useSelector((state) => state.userProfile);
  // eslint-disable-next-line
  const [userProfile, setUserProfileState] = useState(reduxUserProfile || null);

  useEffect(() => {
    // If user profile is not in redux store, fetch it from the server.
    if (reduxUserProfile.user === null) {
      getUserProfile()
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );
          dispatch(setUserProfile(response.data));
          setUserProfileState(response.data);
          setUserProfileLoading(false);
        })
        .catch((error) => {
          setUserProfileLoading(false);
          if (error.response.status === 404) {
            const decoded = jwtDecode(localStorage.getItem("access"));
            navigate(`/profile-setup/${decoded.user_id}`);
            dispatch(
              setNotification({
                open: true,
                message: t["profile-setup-unfinished"][lang],
                severity: "info",
              })
            );
          } else {
            dispatch(
              setNotification({
                open: true,
                message: error.response.data.data
                  ? error.response.data.data.error
                  : "Something went wrong.",
                severity: "error",
              })
            );
          }
        });
    } else {
      setUserProfileLoading(false);
    }
    // eslint-disable-next-line
  }, [dispatch, navigate, lang]);

  return { userProfileLoading, userProfile: reduxUserProfile };
};
export default useGetProfile;
