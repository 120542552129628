import { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Typography,
  Container,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import AddressBookIcon from "../../../assets/icons/Icone/fi-rr-address-book.png";
import crossIcon from "../../../assets/icons/Icone/fi-rr-cross-circle.png";
import { textFieldStyle } from "../../../themes.js";

export default function AddEmployee({
  formData,
  onSubmit,
  isEditMode,
  handleBack,
}) {
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (isEditMode && formData) {
      setNome(formData.name || "");
      setCognome(formData.surname || "");
      setUsername(formData.username || "");
      setBio(formData.bio || "");
      setEmail(formData.email || "");
    }
  }, [formData, isEditMode, handleBack, onSubmit]);

  // Prepara i dati del form da inviare
  const handleSubmit = () => {
    const employeeData = {
      ...formData,
      id: isEditMode ? formData.id : uuidv4(),
      name: nome,
      surname: cognome,
      username: username,
      bio: bio,
      email: email,
      // Altri campi se necessario
    };
    onSubmit(employeeData);
  };

  return (
    <Container
      maxWidth="sm"
      component="main"
      sx={{ flex: 1, p: 3, overflowX: "hidden" }}
    >
      <Box sx={{ position: "relative", top: 5, left: "95%" }}>
        <IconButton onClick={handleBack}>
          <img src={crossIcon} alt="close" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <img
          src={AddressBookIcon}
          alt="AddressBookIcon"
          style={{ height: "20px", marginY: "10px", marginLeft: "15px" }}
        />
        <Typography
          variant="h5"
          fontWeight="500"
          color="initial"
          sx={{ px: 2 }}
        >
          Aggiungi Dipendente
        </Typography>
      </Box>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Nome"
          variant="outlined"
          sx={textFieldStyle}
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Cognome"
          variant="outlined"
          sx={textFieldStyle}
          value={cognome}
          onChange={(e) => setCognome(e.target.value)}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Bio"
          variant="outlined"
          sx={textFieldStyle}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </FormControl>
      <Divider sx={{ mt: 1 }} />
      <FormControl fullWidth margin="normal">
        <TextField
          label="Username"
          variant="outlined"
          sx={textFieldStyle}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Email"
          variant="outlined"
          sx={textFieldStyle}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>

      <Divider sx={{ mt: 1 }} />

      <Box
        sx={{
          mt: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: "100%", borderRadius: 10, p: 2 }}
        >
          Salva
        </Button>
      </Box>
    </Container>
  );
}
