import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, requiredType }) => {
  const userType = JSON.parse(localStorage.getItem("user_type"));
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      const authCheck = JSON.parse(localStorage.getItem("access"));

      if (!authCheck) {
        navigate("/login");
      }
    };

    checkAuth();
    window.addEventListener("storage", checkAuth);

    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  });

  if (requiredType && userType !== requiredType) {
    return <Navigate to="/unauthorized" />; // Not authorized
  }

  return children;
};

export default ProtectedRoute;
