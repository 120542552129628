import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../../assets/img/favicon.png";
import { useSelector } from "react-redux";

import { translations as t } from "../../../assets/translations.js";
import styles from "../css/ItemsBottomDrawer.module.css";

export default function CopyLink({
  username,
  setOpenSnackbar,
}) {
  const lang = useSelector((state) => state.userLang.value);

  const handleClick = () => {
    navigator.clipboard
      .writeText("https://hellolink.pro/" + username)
      .then(() => {
        setOpenSnackbar(true);
      });
  };

  return (
    <>
      <Grid container className={styles.container}>
        <Grid item xs={2}>
          <img
            src={logo}
            alt="logo meraviglia"
            className={styles.logoMeraviglia}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            maxWidth: { xs: "50%", sm: "70%", md: "85%", lg: "90%" },
          }}
        >
          <Typography
            id="link_utente"
            variant="body1"
            color="initial"
            noWrap
            className={styles.ellipsisText}
          >
            hellolink.pro/{username}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            color="inherit"
            sx={{ borderRadius: 10 }}
            onClick={handleClick}
          >
            {t["share-copia"][lang]}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
