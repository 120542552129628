import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { translations as t } from "../../../assets/translations";
import { useSelector } from "react-redux";

const FAQItem = ({faqId}) => {
  const lang = useSelector((state) => state.userLang.value);

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        bgcolor: "#F2F2F2",
        borderRadius: "14px !important",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {t["faq"][faqId]["title"][lang]}
      </AccordionSummary>
      <AccordionDetails>
        {t["faq"][faqId]["text"][lang]}
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQItem;
