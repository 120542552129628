import React, { useEffect, useState } from "react";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { textFieldStyle } from "../../../themes";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import { translations as t } from "../../../assets/translations.js";
import { getCountries } from "../../../api/assets.js";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../../slices/notificationSnackbar.js";

// Theme.ts
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          renderOption: (props, option, state, ownerState) => (
            <Box
              sx={{
                fontFamily: "Poppins, Arial, sans-serif",
                borderRadius: "40px",
                margin: "5px",
                [`&.${autocompleteClasses.option}`]: {
                  padding: "8px",
                },
              }}
              component="li"
              {...props}
            >
              {ownerState.getOptionLabel(option)}
            </Box>
          ),
        },
      },
    },
  });

export default function CountrySelect({
  value,
  handleAddressChange,
  index,
  handleCountryChange,
}) {
  const outerTheme = useTheme();
  const lang = useSelector((state) => state.userLang.value);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountries();

        dispatch(
          setNotification({
            open: false,
          })
        );

        setCountries(response.data);
      } catch (error) {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      }
    };

    fetchCountries();
  }, [dispatch]);

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Autocomplete
        name="country-autocomplete"
        options={countries}
        sx={textFieldStyle}
        value={value}
        onChange={(event, newValue) => {
          handleAddressChange(index, "country", newValue);
          handleCountryChange(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.name} (${option.code})`}
        renderInput={(params) => (
          <TextField
            name="country-field"
            {...params}
            sx={textFieldStyle}
            label={t["contact-country"][lang]}
          />
        )}
      />
    </ThemeProvider>
  );
}
