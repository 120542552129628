import { useState, forwardRef, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import ColorPickerComponent from "../../../components/ColorPicker";
import { useSelector } from "react-redux";
import { formatColor } from "../../../utils";
import { translations as t } from "../../../assets/translations";
import styles from "../css/ButtonStyleSelection.module.css";
import useGetProfile from "../../../hooks/useGetProfile";
import { customAppearanceDefaults } from "../../../slices/userProfile";
import FontSelection from "./FontSelection.jsx";

const TextStyleSelection = forwardRef((props, ref) => {
  const lang = useSelector((state) => state.userLang.value);

  const { userProfileLoading, userProfile } = useGetProfile();
  const initialApperanceText = userProfileLoading
    ? customAppearanceDefaults.texts
    : userProfile.user.preference.texts;

  const [selectedTextColor, setSelectedTextColor] = useState({
    color: {
      hex: initialApperanceText.color.hex,
      rgb_r: initialApperanceText.color.rgb_r,
      rgb_g: initialApperanceText.color.rgb_g,
      rgb_b: initialApperanceText.color.rgb_b,
    },
  });
  console.log(selectedTextColor);

  const [selectedTextFont, setSelectedTextFont] = useState({
    family: initialApperanceText.family,
  });
  console.log(selectedTextFont);

  useEffect(() => {
    setSelectedTextColor({
      color: {
        hex: initialApperanceText.color.hex,
        rgb_r: initialApperanceText.color.rgb_r,
        rgb_g: initialApperanceText.color.rgb_g,
        rgb_b: initialApperanceText.color.rgb_b,
      },
    });
    setSelectedTextFont({
      family: initialApperanceText.family,
    });
  }, [initialApperanceText]);

  const handleTextColorSelection = (color) => {
    setSelectedTextColor({
      color: {
        hex: color.hex,
        rgb_r: color.rgb.r,
        rgb_g: color.rgb.g,
        rgb_b: color.rgb.b,
      },
    });
    props.setUnsavedChanges(true);
  };

  const handleTextFontSelection = (family) => {
    setSelectedTextFont({
      family: family,
    });
    props.setUnsavedChanges(true);
  };

  const resetDefaultValues = () => {
    setSelectedTextColor({
      color: {
        ...initialApperanceText.color,
      },
    });
    setSelectedTextFont({
      ...initialApperanceText.family,
    });
  };

  return (
    <Box className={styles.box}>
      <Typography variant="caption" className={styles.caption}>
        {t["modify_appearance"]["text"][lang]}
      </Typography>
      <Box className={styles["container-button"]}>
        <Button onClick={resetDefaultValues} className={styles["reset-button"]}>
          {t["modify_appearance"]["reset"][lang]}
        </Button>
      </Box>

      <Typography
        variant="body1"
        noWrap
        color="initial"
        sx={{
          fontFamily: selectedTextFont.family,
          fontWeight: 700,
          fontSize: "13px",
          color: `rgb(${selectedTextColor.color.rgb_r} ${selectedTextColor.color.rgb_g} ${selectedTextColor.color.rgb_b}) !important`,
          textAlign: "center",
        }}
      >
        {t["modify_appearance"]["preview"][lang]}
      </Typography>
      {console.log(selectedTextFont.family)}
      <FontSelection
        label={"Font"}
        selectedFont={selectedTextFont.family}
        onTextFontSelection={handleTextFontSelection}
      />
      <ColorPickerComponent
        label={t["modify_appearance"]["text_color"][lang]}
        startingColor={formatColor(selectedTextColor.color)}
        onColorSelection={handleTextColorSelection}
      />
      {/* hidden inputs */}
      <input
        type="hidden"
        name="textColor"
        value={JSON.stringify({
          color: {
            hex: selectedTextColor.color.hex,
            rgb_r: selectedTextColor.color.rgb_r,
            rgb_g: selectedTextColor.color.rgb_g,
            rgb_b: selectedTextColor.color.rgb_b,
          },
          family: selectedTextFont.family,
        })}
        ref={ref}
      />
    </Box>
  );
});

export default TextStyleSelection;
