import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import CustomIconButton from "../CustomIconButton";

import lockIcon from "../../assets/icons/Icone/fi-rr-lock.png";
import eyeIcon from "../../assets/icons/Icone/fi-rr-eye.png";
import eyeCrossedIcon from "../../assets/icons/Icone/fi-rr-eye-crossed.png";
import { textFieldStyleLoginSignup as textFieldStyle } from "../../themes.js";
import styles from "./InputPassword.module.css";

export default function InputPassword({ setPassword }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <FormControl id="input-password" className={styles.formControlPassword}>
      <TextField
        name="password"
        required
        type={showPassword ? "text" : "password"}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          startAdornment: (
            <img src={lockIcon} alt="lock icon" className={styles.icon} />
          ),
          endAdornment: (
            <CustomIconButton
              icon={showPassword ? eyeCrossedIcon : eyeIcon}
              iconAlt={showPassword ? "eye-crossed" : "eye"}
              onClickFunction={handleClickShowPassword}
            />
          ),
          size: "large",
        }}
        placeholder="Password"
        sx={textFieldStyle}
      />
    </FormControl>
  );
}
