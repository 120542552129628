import { useState } from "react";
import {
  FormControl,
  FormGroup,
  Typography,
  Container,
  Button,
  Box,
} from "@mui/material";

import Check from "../../assets/icons/Icone/shield-check.png";

import InputPassword from "../../components/InputPassword";
import InputPassword2 from "../../components/InputPassword2";
import Meraviglia from "../../components/Meraviglia";
import IconFlag from "../../components/IconFlag";

import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import { translations as t } from "../../assets/translations.js";
import styles from "./ResetPassword.module.css";
import { resetPassword } from "../../api/authentication.js";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const lang = useSelector((state) => state.userLang.value);
  const dispatch = useDispatch();

  const handlePasswordChange = (pwd) => {
    setPassword(pwd);
  };

  const handleSubmit = () => {
    if (password !== password2) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
      resetPassword(token, password, password2)
        .then(() => {
          dispatch(
            setNotification({
              open: false,
            })
          );
          setPasswordChanged(true); // If passwords match, set passwordChanged to true
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    }
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  if (passwordChanged) {
    return (
      <Container maxWidth="sm" className={styles.containerStep2}>
        <Box
          className={styles.boxPhoto}
          sx={{
            backgroundImage: `url(${Check})`,
          }}
        />
        <Typography
          variant="h4"
          fontWeight="500"
          color="inherit"
          textAlign="center"
        >
          {t["newpass-cambiata1"][lang]}{" "}
          <span style={{ color: "#247DFF" /* Primary color */ }}>
            {t["newpass-cambiata2"][lang]}
          </span>
        </Typography>

        <Button
          variant="contained"
          color="primary"
          className={styles.buttonForm}
          onClick={redirectToLogin}
        >
          {t["newpass-accedi"][lang]}
        </Button>
        <Meraviglia />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" className={styles.containerStep1}>
      <IconFlag />

      <FormControl id="field-password" component="fieldset">
        <Typography variant="h4" fontWeight="500" color="inherit">
          {t["newpass-inserisci"][lang]}

          <span style={{ color: "#247DFF" /* Primary color */ }}>
            {t["newpass-Password"][lang]}
          </span>
        </Typography>
        <FormGroup sx={{ mt: 4 }}>
          <InputPassword
            password={password}
            setPassword={setPassword}
            onChange={handlePasswordChange}
          />
          <InputPassword2 password2={password2} setPassword2={setPassword2} />
        </FormGroup>
        {!passwordsMatch && (
          <Typography color="error">{t["newpass-match"][lang]}</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={styles.buttonForm}
          onClick={handleSubmit}
        >
          {t["newpass-inizia"][lang]}
        </Button>
      </FormControl>
      <Meraviglia />
    </Container>
  );
}
