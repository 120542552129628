import { Container, Typography, Button } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import img from "../../assets/img/unauthorized-path.png";
import { translations as t } from "../../assets/translations.js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutFunction } from "../../api/authentication.js";
import { clearIsAuth, clearRefresh } from "../../slices/userAuth.js";
import { clearUserType } from "../../slices/userTypeSlice.js";
import { clearUserProfile } from "../../slices/userProfile.js";
import { setNotification } from "../../slices/notificationSnackbar.js";
import signoutIcon from "../../assets/icons/Icone/fi-rr-sign-out.png";

const UnauthorizedPage = () => {
  const lang = useSelector((state) => state.userLang.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleLogout() {
    const refresh = JSON.parse(localStorage.getItem("refresh"));

    logoutFunction(refresh)
      .then((response) => {
        if (response.ok) {
          dispatch(clearIsAuth());
          dispatch(clearRefresh());
          dispatch(clearUserType());
          dispatch(clearUserProfile());
          navigate("/login");
        }
      })
      .catch(() => {
        dispatch(
          setNotification({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          })
        );
        navigate("/login");
      });
  }

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <img
        src={img}
        alt="Confused Character"
        style={{ maxWidth: "80%", height: "auto", marginBottom: "16px" }}
      />
      <Typography variant="h4" gutterBottom>
        {t["ops"][lang]}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {t["ops2"][lang]}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/login")}
        sx={{ borderRadius: 10, py: 1.5, px: 5, m: 2, fontSize: 20 }}
      >
        <SentimentVeryDissatisfiedIcon sx={{ mr: 1 }} />
        {t["ops3"][lang]}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogout}
        sx={{ borderRadius: 10, py: 1.5, px: 5, m: 2, fontSize: 20 }}
      >
        <img
          src={signoutIcon}
          alt=""
          style={{
            height: "20px",
            marginRight: 10,
            filter: "brightness(0) invert(1)",
          }}
        />
        {t["botdrw-logout"][lang]}
      </Button>
    </Container>
  );
};

export default UnauthorizedPage;
