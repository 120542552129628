import { useState, forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../css/BackgroundSelection.module.css";
import { Box, Typography, Button } from "@mui/material";
import ColorPickerComponent from "../../../components/ColorPicker";
import { formatColor } from "../../../utils";
import { translations as t } from "../../../assets/translations";
import useGetProfile from "../../../hooks/useGetProfile";
import { customAppearanceDefaults } from "../../../slices/userProfile";

const BackgroundSelection = forwardRef((props, ref) => {
  const lang = useSelector((state) => state.userLang.value);

  const { userProfileLoading, userProfile } = useGetProfile();

  const initialBackgroundColor = userProfileLoading
    ? customAppearanceDefaults.background
    : userProfile.user.preference.background;

  const [selectedBackground, setSelectedBackground] = useState({
    color: {
      ...initialBackgroundColor?.color,
    },
  });

  // const [selectedGradient, setSelectedGradient] = useState({
  //   hex: initialBackgroundColor.gradient.hex,
  //   rgb: {
  //     r: initialBackgroundColor.gradient.rgb.r,
  //     g: initialBackgroundColor.gradient.rgb.g,
  //     b: initialBackgroundColor.gradient.rgb.b,
  //   },
  // });

  const [backgroundType, setBackgroundType] = useState(
    initialBackgroundColor?.type
  );

  useEffect(() => {
    setSelectedBackground({
      color: {
        ...initialBackgroundColor?.color,
      },
    });
    setBackgroundType(initialBackgroundColor?.type);
  }, [initialBackgroundColor]);

  const handleColorSelection = (color) => {
    setSelectedBackground({
      color: {
        hex: color.hex,
        rgb_r: color.rgb.r,
        rgb_g: color.rgb.g,
        rgb_b: color.rgb.b,
      },
    });
    props.setUnsavedChanges(true);
  };

  const handleTypeSelection = (type) => {
    setBackgroundType(type);
    props.setUnsavedChanges(true);
  };

  const resetDefaultValues = () => {
    setSelectedBackground({
      color: {
        ...initialBackgroundColor.color,
      },
    });
    setBackgroundType(initialBackgroundColor.type);
  };

  return (
    <Box className={styles.box}>
      <Typography variant="caption" className={styles.caption}>
        {t["modify_appearance"]["background"][lang]}
      </Typography>
      <Box maxWidth="sm" className={styles["container-button"]}>
        <Button onClick={resetDefaultValues} className={styles["reset-button"]}>
          {t["modify_appearance"]["reset"][lang]}
        </Button>
      </Box>
      <Box className={styles["color-box-grid"]}>
        {/* Solid Color */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Box
            className={
              styles["color-box"] +
              " " +
              (backgroundType === "flat" ? styles["selected"] : "")
            }
            sx={{
              backgroundColor: `rgb(${selectedBackground.color.rgb_r}, ${selectedBackground.color.rgb_g}, ${selectedBackground.color.rgb_b})`,
            }}
            onClick={() => handleTypeSelection("flat")}
          ></Box>
          <Typography variant="caption" className={styles["color-box-caption"]}>
            {t["modify_appearance"]["flat_color"][lang]}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {/* Gradient background */}
          <Box
            className={
              styles["color-box"] +
              " " +
              (backgroundType === "gradient" ? styles["selected"] : "")
            }
            sx={{
              background: `linear-gradient(to bottom, rgb(${selectedBackground.color.rgb_r}, ${selectedBackground.color.rgb_g}, ${selectedBackground.color.rgb_b}), rgb(255, 255, 255))`,
            }}
            onClick={() => handleTypeSelection("gradient")}
          ></Box>

          <Typography variant="caption" className={styles["color-box-caption"]}>
            {t["modify_appearance"]["gradient"][lang]}
          </Typography>
        </Box>
      </Box>
      <ColorPickerComponent
        label={t["modify_appearance"]["color"][lang]}
        startingColor={formatColor(selectedBackground.color)}
        onColorSelection={handleColorSelection}
      />
      {/* Restore this when secondary color for gradient will be selectable */}
      {/* {backgroundType && (
        <ColorPickerComponent
          label="Gradient"
          startingColor={selectedGradient}
          onColorSelection={handleGradientSelection}
        />
      )} */}
      {/* hidden input for background value */}
      <input
        type="hidden"
        name="background"
        value={JSON.stringify({
          color: {
            hex: selectedBackground.color.hex,
            rgb_r: selectedBackground.color.rgb_r,
            rgb_g: selectedBackground.color.rgb_g,
            rgb_b: selectedBackground.color.rgb_b,
          },
          type: backgroundType,
        })}
        ref={ref}
      />
    </Box>
  );
});

export default BackgroundSelection;
