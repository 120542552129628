import { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  ThemeProvider,
  Skeleton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ButtonTooltip from "../../components/ButtonTooltip";
import Meraviglia from "../../components/Meraviglia";
import CustomIconButton from "../../components/CustomIconButton";
import ItemsMenu from "./components/ItemsMenu";
import BottomDrawer from "./components/BottomDrawer";
import ProfilePicture from "./components/ProfilePicture";

import menuIcon from "../../assets/icons/Icone/fi-rr-menu-dots.png";
import editIcon from "../../assets/icons/Icone/fi-rr-pencil.png";
import styles from "./css/Overview.module.css";
import useGetProfile from "../../hooks/useGetProfile.jsx";
import { jwtDecode } from "jwt-decode";
import { themeTextOverview } from "../../themes.js";
import { useSelector } from "react-redux";
import { translations as t } from "../../assets/translations.js";

export default function Overview({ infoData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const lang = useSelector((state) => state.userLang.value);
  const isAuth = useSelector((state) => state.userAuth.isAuth);

  const [drawerOpen, setDrawerOpen] = useState(false);

  //CONTROLLI SULLA PAGINA MAIN
  useEffect(() => {
    if (!isAuth && location.pathname === "/overview") {
      navigate("/login");
    }
  }, [isAuth, location.pathname, navigate]);

  // Hook to get user profile data
  const { userProfileLoading, userProfile } = useGetProfile();

  // load background color on body tag
  useEffect(() => {
    if (!userProfileLoading && userProfile.user) {
      document.body.style.backgroundColor = `rgb(${userProfile.user.preference.background.color.rgb_r},
      ${userProfile.user.preference.background.color.rgb_g},
      ${userProfile.user.preference.background.color.rgb_b})`;
    }
    return () => {
      document.body.style.backgroundColor = "#ffffff";
    };
  }
  , [userProfileLoading, userProfile.user]);

  // Funzione per aprire il drawer
  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const [currentComponent, setCurrentComponent] = useState(null);

  // MODIFY DRAWER HEIGHT
  const isLargeScreen = useMediaQuery("(min-width: 992px)"); // Modifica questo valore in base alle specifiche del tuo framework
  const isLandscape = useMediaQuery("(orientation: landscape)");

  const [drawerHeight, setDrawerHeight] = useState(
    isLargeScreen ? "auto" : isLandscape ? "90%" : "auto"
  );

  useEffect(() => {
    // Funzione per aggiornare lo stato di drawerHeight
    const updateDrawerHeight = () => {
      if (isLargeScreen) {
        setDrawerHeight("auto");
      } else {
        setDrawerHeight(isLandscape ? "90%" : "auto");
      }
    };

    // Ascoltatore di eventi per i cambiamenti di dimensione dello schermo
    window.addEventListener("resize", updateDrawerHeight);

    // Aggiorna lo stato iniziale al montaggio del componente
    updateDrawerHeight();

    // Rimozione dell'ascoltatore di eventi al smontaggio del componente
    return () => {
      window.removeEventListener("resize", updateDrawerHeight);
    };
  }, [isLargeScreen, isLandscape]); // Dipendenze dell'effetto

  //REDIRECT TO EDIT PROFILE
  const handleClickEdit = () => {
    const decoded = jwtDecode(localStorage.getItem("access"));
    navigate(`/appearance/${decoded.user_id}`);
  };

  //Open drawer

  const handleOpenDrawer = () => {
    if (location.pathname === "/overview") {
      openDrawer();
    }
  };
  return (
    <Box
      sx={
        !userProfileLoading && userProfile.user
          ? userProfile.user.preference.background.type === "gradient"
            ? {
                background: `linear-gradient(to bottom, rgb(${userProfile.user.preference.background.color.rgb_r},
                                      ${userProfile.user.preference.background.color.rgb_g},
                                      ${userProfile.user.preference.background.color.rgb_b}),
                                      rgb(255, 255, 255))`,
                height: "100%",
              }
            : {
                backgroundColor: `rgb(${userProfile.user.preference.background.color.rgb_r},
                                      ${userProfile.user.preference.background.color.rgb_g},
                                      ${userProfile.user.preference.background.color.rgb_b})`,
                height: "100%",
              }
          : {
              backgroundColor: "#ffffff",
              height: "100%",
            }
      }
    >
      <Container
        maxWidth="sm"
        className={styles.container}
        sx={{
          pt: 2,
          px: { xs: 0, sm: 10 },
        }}
      >
        {!userProfileLoading && userProfile.user && (
          <>
            <ButtonTooltip title={t["tooltips"]["modify-profile"][lang]}>
              <Box className={styles.leftcornerIcon}>
                <CustomIconButton
                  icon={editIcon}
                  iconAlt={"edit"}
                  onClickFunction={handleClickEdit}
                  targetColor={{
                    r: userProfile.user.preference.texts.color.rgb_r,
                    g: userProfile.user.preference.texts.color.rgb_g,
                    b: userProfile.user.preference.texts.color.rgb_b,
                  }}
                />
              </Box>
            </ButtonTooltip>

            <Box className={styles.rightcornerIcon}>
              <CustomIconButton
                icon={menuIcon}
                iconAlt={"menu"}
                onClickFunction={handleOpenDrawer}
                targetColor={{
                  r: userProfile.user.preference.texts.color.rgb_r,
                  g: userProfile.user.preference.texts.color.rgb_g,
                  b: userProfile.user.preference.texts.color.rgb_b,
                }}
              />
            </Box>

            <ProfilePicture
              photo={
                userProfile.user.avatar ? userProfile.user.avatar.file : null
              }
              dataLoaded={!userProfileLoading}
            />

            <Box className={styles.boxCopy}>
              <ThemeProvider theme={themeTextOverview}>
                <Typography
                  variant="h5"
                  sx={{
                    color: userProfile.user.preference.texts.color.hex,
                    fontFamily: userProfile.user.preference.texts.family,
                  }}
                >
                  {userProfile.user.user.first_name}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="500"
                  sx={{
                    color: userProfile.user.preference.texts.color.hex,
                    fontFamily: userProfile.user.preference.texts.family,
                  }}
                >
                  {userProfile.user.user.last_name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: userProfile.user.preference.texts.color.hex,
                    fontFamily: userProfile.user.preference.texts.family,
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                    {userProfile.user.bio}
                </Typography>
              </ThemeProvider>
            </Box>

            <ItemsMenu
              infoData={infoData}
              currentComponent={currentComponent}
              setCurrentComponent={setCurrentComponent}
              drawerHeight={drawerHeight}
              customAppearance={userProfile.user.preference}
            />
            <BottomDrawer
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              drawerHeight={drawerHeight}
              userData={{
                id: userProfile.user.user.id ?? 123234, // TODO review this line, useless
                avatar: userProfile.user.avatar
                  ? userProfile.user.avatar.file
                  : null,
                first_name: userProfile.user.user.first_name,
                last_name: userProfile.user.user.last_name,
                username: userProfile.user.user.username,
                bio: userProfile.user.bio,
              }}
            />
          </>
        )}

        {userProfileLoading && (
          <>
            <Skeleton
              variant="circular"
              width={120}
              height={120}
              sx={{
                marginTop: 2,
              }}
            />
            <Skeleton
              variant="text"
              width={"30%"}
              height={20}
              sx={{
                marginTop: 4,
              }}
            />
            <Skeleton variant="text" width={"30%"} height={20} />
            <Skeleton
              variant="text"
              width={"15%"}
              height={14}
              sx={{
                marginTop: 2,
              }}
            />
            <Skeleton variant="text" width={"15%"} height={14} />
            <Skeleton
              variant="rectangle"
              width={"300px"}
              height={"50px"}
              sx={{
                borderRadius: "1000px",
                marginTop: 6,
              }}
            />
            <Skeleton
              variant="rectangle"
              width={"300px"}
              height={"50px"}
              sx={{
                borderRadius: "1000px",
                marginTop: 2,
              }}
            />
            <Skeleton
              variant="rectangle"
              width={"300px"}
              height={"50px"}
              sx={{
                borderRadius: "1000px",
                marginTop: 2,
              }}
            />
            <Skeleton
              variant="rectangle"
              width={"300px"}
              height={"50px"}
              sx={{
                borderRadius: "1000px",
                marginTop: 2,
              }}
            />
            <Skeleton
              variant="rectangle"
              width={"300px"}
              height={"50px"}
              sx={{
                borderRadius: "1000px",
                marginTop: 2,
              }}
            />
          </>
        )}

        <Meraviglia isOverview={true} />
      </Container>
    </Box>
  );
}
