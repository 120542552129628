import { Box } from "@mui/material";
import styles from "./GoogleLoginButton.module.css";
// import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../../api/authentication";
import { useSelector, useDispatch } from "react-redux";
import { setRefresh, setIsAuth } from "../../slices/userAuth";
import { setUserType } from "../../slices/userTypeSlice";
import { useNavigate } from "react-router-dom";
import { setNotification } from "../../slices/notificationSnackbar";
import { translations as t } from "../../assets/translations";

const GoogleLoginButton = ({ nfc_passkey }) => {
  const lang = useSelector((state) => state.userLang.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(nfc_passkey);
  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => handleGoogleLogin(tokenResponse),
  //   onError: () => {
  //     dispatch(
  //       setNotification({
  //         open: true,
  //         message: t["google-login-error"][lang],
  //         severity: "error",
  //       })
  //     );
  //   },
  // });

  const handleGoogleLogin = (response) => {
    const auth_token = response.credential;

    const request = {
      auth_token: auth_token,
      nfc_passkey: nfc_passkey,
    };
    console.log(request);

    googleLogin(request)
      .then((response) => {
        const data = response.data;
        localStorage.setItem("refresh", JSON.stringify(data.refresh));
        dispatch(setRefresh());
        localStorage.setItem("access", JSON.stringify(data.access));
        dispatch(setIsAuth());
        localStorage.setItem("user_type", JSON.stringify(data.user_type));
        dispatch(setUserType({ userType: data.user_type }));

        if (data.user_type === "NU") {
          navigate("/overview");
        } else if (data.user_type === "CU") {
          navigate("/company-pannel");
        } else {
          navigate("/unauthorized");
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  return (
    // <Button
    //   variant="outlined"
    //   startIcon={<img src={google} alt="icon" className={styles.icons} />}
    //   onClick={() => login()}
    //   sx={{
    //     marginTop: "25px",
    //   }}
    // >
    //   {t["google-login-button"][lang]}
    // </Button>
    <Box
      sx={{
        marginTop: "25px",
      }}
    >
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          handleGoogleLogin(credentialResponse);
        }}
        onError={() => {
          dispatch(
            setNotification({
              open: true,
              message: t["google-login-error"][lang],
              severity: "error",
            })
          );
        }}
        locale={lang}
        className={styles.defaultGoogleButton}
      />
    </Box>
  );
};

export default GoogleLoginButton;
