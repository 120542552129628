import styles from "./HeroGrid.module.css";
import { Typography, Grid, Box } from "@mui/material";
import landingPageHeroImage from "../../../assets/img/landing_page_hero_image.png";
import SelectUsername from "./SelectUsername";
import { translations as t } from "../../../assets/translations";
import { useSelector } from "react-redux";
import { Typewriter } from "react-simple-typewriter";

const HeroGrid = () => {
  const lang = useSelector((state) => state.userLang.value);

  const greets = [
    "Ciao, Benvenuto",
    "Hello, Welcome",
    "Hola, Bienvenido",
    "Nǐ hǎo, Huānyíng",
    "Hallo, Wilkommen",
    "Salut, Bienvenue",
  ];

  return (
    <Grid container sx={{ rowGap: 4 }} className={styles["hero-grid"]}>
      <Grid item xs={12} md={8} className={styles["hero-grid-item"]}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              marginBottom: "25px",
            }}
          >
            <Typography
              variant="h3"
              color="white"
              fontWeight={700}
              className={styles["hero-animated-text"]}
              sx={{
                width: "180px",
                height: "120px",
              }}
            >
              <Typewriter
                words={greets}
                cursor
                loop={0}
                cursorStyle="|"
                typeSpeed={100}
                deleteSpeed={100}
                delaySpeed={1000}
              />
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              className={styles["hero-paragraph"]}
              sx={{
                marginBottom: "25px",
                paddingRight: "40px",
              }}
            >
              {t["landing_page"]["hero_text"][lang]}
            </Typography>
            <SelectUsername />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        className={styles["hero-grid-item"]}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={landingPageHeroImage}
          alt="User example"
          style={{
            maxWidth: "100%",
            display: "block",
            margin: "0 auto",
            maxHeight: "350px",
            height: "350px",
            objectFit: "contain",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default HeroGrid;
