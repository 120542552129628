import axios from "axios";
import {
  axiosInstance,
  axiosInstancePublic,
  BASE_URL_ENDPOINT,
  config,
} from "./common.js";

export const refreshAccessToken = (refresh) => {
  const apiEndpoint = BASE_URL_ENDPOINT + "auth/token/refresh/";

  return axios
    .post(apiEndpoint, { refresh }, config)
    .then((response) => {
      if (response.data.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response.data.status === 401) {
        // Not authenticated user
        clearClientAuthState();
      }

      throw error;
    });
};

export const loginFunction = (data) => {
  const apiEndpoint = "auth/token/";

  return axiosInstancePublic
    .post(apiEndpoint, data)
    .then((response) => {
      if (response.data.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const googleLogin = (data) => {
  const apiEndpoint = "social_auth/google/";

  return axiosInstancePublic
    .post(apiEndpoint, data)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const registerUser = (data) => {
  const apiEndpoint = "auth/register/";

  return axiosInstancePublic
    .post(apiEndpoint, data)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyEmailOtp = (data) => {
  const apiEndpoint = "auth/verify-email-otp/";

  return axiosInstancePublic
    .post(apiEndpoint, data)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};

let cancelTokenSource = null;

export const validateUsername = async (username) => {
  const apiEndpoint = "auth/validate-username/";

  // Se esiste un token di cancellazione precedente, annulla quella richiesta
  if (cancelTokenSource) {
    cancelTokenSource.cancel(
      "Richiesta annullata a causa di una nuova richiesta."
    );
  }

  // Crea un nuovo token di cancellazione per la nuova richiesta
  cancelTokenSource = axios.CancelToken.source();

  try {
    const response = await axiosInstancePublic.post(
      apiEndpoint,
      { username },
      { cancelToken: cancelTokenSource.token }
    );
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.warn("Richiesta cancellata:", error.message);
      return;
    } else {
      throw error;
    }
  }
};

export const logoutFunction = (refresh) => {
  const apiEndpoint = "auth/logout/";

  return axiosInstance
    .post(apiEndpoint, { refresh })
    .then((response) => {
      clearClientAuthState();
      return response.data;
    })
    .catch((error) => {
      clearClientAuthState();
      throw error;
    });
};

export const forgotPassword = (email) => {
  const apiEndpoint = "auth/forgot-password/";

  return axiosInstancePublic
    .post(apiEndpoint, { email })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = (token, password, password2) => {
  const apiEndpoint = `auth/reset-password/${token}/`;

  const request = {
    password,
    password2,
  };

  return axiosInstancePublic
    .post(apiEndpoint, request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const clearClientAuthState = () => {
  localStorage.removeItem("refresh");
  localStorage.removeItem("access");
  localStorage.removeItem("user_type");
  localStorage.removeItem("userData");
  localStorage.removeItem("arrayData");
  localStorage.removeItem("arrayDataApi");
};

// export const logoutFunction = async (refresh, attempt = 1) => {
//   const maxAttempts = 3; // Numero massimo di tentativi
//   try {
//     const apiEndpoint = "auth/logout/";
//     console.log("Dati inviati al server: ", refresh);

//     const response = await axiosInstance.post(apiEndpoint, { refresh });
//     console.log("Response LOGOUT: ", response.data.data.message);

//     if (response.status === 200) { // Modifica qui per controllare direttamente lo status della response
//       clearClientAuthState();
//       return { ok: true, data: response.data };
//     }
//   } catch (error) {
//     console.log("Errore nella response:", error.response);

//     if ((error.response?.data.status === 400 || error.response?.data.status === 401) && attempt < maxAttempts) {
//       // Prova a fare il logout ricorsivamente utilizzando il valore del refresh token salvato in localStorage
//       const refreshFromLocalStorage = JSON.parse(localStorage.getItem("refresh"));

//       if (refreshFromLocalStorage) {
//         console.log(`Tentativo di logout n° ${attempt + 1}`);
//         return logoutFunction(refreshFromLocalStorage, attempt + 1);
//       }
//     } else {
//       // Gestisci altri errori o casi di errore dopo aver superato il limite di tentativi
//       clearClientAuthState(); // Potresti voler pulire lo stato di autenticazione anche in caso di errore
//       return { ok: false, error: error.response?.data || "Errore sconosciuto" };
//     }
//   }
// };
