import styles from "./HeroNav.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { themeSecondaryButton as secondaryButtonTheme } from "../../../themes.js";
import { themeBlackButton as blackButtonTheme } from "../../../themes.js";
import { useSelector } from "react-redux";
import logo from "../../../assets/img/logo_hellolink.png";
import logoSmall from "../../../assets/img/logo_hellolink_small.png";
import { translations as t } from "../../../assets/translations.js";
import IconFlag from "../../../components/IconFlag/IconFlag.jsx";

const HeroNav = () => {
  const minWidth768 = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);

  return (
    <Paper className={styles.navigation}>
      <img
        src={minWidth768 ? logo : logoSmall}
        alt="hellolink"
        className={styles.heroLogo}
      />
      <div className={styles["nav-buttons"]}>
        <ThemeProvider theme={secondaryButtonTheme}>
          <Button variant="outlined" onClick={() => navigate("/login")}>
            {t["landing_page"]["login"][lang]}
          </Button>
        </ThemeProvider>

        <ThemeProvider theme={blackButtonTheme}>
          <Button variant="outlined" onClick={() => navigate("/signup")}>
            {t["landing_page"]["register_free"][lang]}
          </Button>
        </ThemeProvider>

        <IconFlag />
      </div>
    </Paper>
  );
};

export default HeroNav;
