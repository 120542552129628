import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useSelector } from "react-redux";

import emailIcon from "../../assets/icons/Icone/fi-rr-envelope.png";
import { translations as t } from "../../assets/translations.js";
import { textFieldStyleLoginSignup as textFieldStyle } from "../../themes.js";
import styles from "./InputEmail.module.css";

export default function InputsEmail({
  email,
  setEmail,
  isValidEmail,
  setIsValidEmail,
}) {
  const lang = useSelector(state => state.userLang.value)

  // Email validation function
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Effect to validate email on change
  useEffect(() => {
    if (email) {
      setIsValidEmail(validateEmail(email));
    } else {
      setIsValidEmail(true);
    }
  }, [email, setIsValidEmail]);

  return (
    <FormControl id="input-email" className={styles.formControlEmail}>
      <TextField
        name="email"
        required
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        sx={textFieldStyle}
        InputProps={{
          startAdornment: (
            <img src={emailIcon} alt="email icon" className={styles.icon} />
          ),
          style: { borderRadius: "50px" },
          inputProps: {
            autoCapitalize: "none",
          },
        }}
        placeholder="Email"
        type="email"
      />
      {!isValidEmail && (
        <Typography
          variant="body2"
          color="error"
          className={styles.helpertextEmail}
        >
          {t["helpertext-email"][lang]}
        </Typography>
      )}
    </FormControl>
  );
}
