import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

export default function DeleteDialog({
  openConfirmDialog,
  setOpenConfirmDialog,
  handleDeleteClick,
  employeeToDelete,
}) {
  return (
    <Dialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Conferma Eliminazione"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Sei sicuro di voler eliminare questo dipendente?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
          Annulla
        </Button>
        <Button
          onClick={() => handleDeleteClick(employeeToDelete)}
          variant="contained"
          color="primary"
          autoFocus
        >
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
}
