import React from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { translations as t } from "../../../assets/translations";
import { useSelector } from "react-redux";
import darkSectionImage from "../../../assets/img/landing_page_dark_section_image.png";
import { useNavigate } from "react-router-dom";

const LandingDarkSection = () => {
  const lang = useSelector((state) => state.userLang.value);
  const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: "#000000" }}>
      <Container
        sx={{
          padding: {
            xs: "50px 16px 50px 16px",
            md: "120px 32px 120px 32px",
          },
        }}
      >
        <Grid
          container
          rowGap={5}
          sx={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              order: {
                xs: 1,
                md: 2,
              },
              padding: {
                xs: "0",
                md: "0 0 0 50px",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography variant="h4" color="white" fontWeight={700}>
              {t["landing_page"]["dark_section_title"][lang]}
            </Typography>

            <Typography
              variant="subtitle1"
              color="white"
              fontWeight={200}
              sx={{
                marginTop: "25px",
              }}
            >
              {t["landing_page"]["dark_section_subtitle"][lang]}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/signup")}
              sx={{
                padding: "8px 24px !important",
                borderRadius: "32px !important",
                marginTop: "25px",
              }}
            >
              {t["landing_page"]["register_free"][lang]}
            </Button>
          </Grid>
          <Grid item xs={12} md={4} sx={{ order: { xs: 2, md: 1 } }}>
            <img
              src={darkSectionImage}
              alt="User example"
              style={{
                maxWidth: "100%",
                display: "block",
                margin: "0 auto",
                maxHeight: "350px",
                height: "300px",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingDarkSection;
