import { useEffect, useState, useRef } from "react";
import { Button, Drawer, Container, Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import crossIcon from "../../assets/icons/Icone/fi-rr-cross-circle.png";
import photoIcon from "../../assets/icons/Icone/fi-rr-camera-blue.png";
import uploadIcon from "../../assets/icons/Icone/fi-rr-upload.png";
import trashIcon from "../../assets/icons/Icone/fi-rr-trash.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { translations as t } from "../../assets/translations.js";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";
// import { updateUserProfile } from "../../api/profile.js";
import useUpdateProfile from "../../hooks/useUpdateProfile.jsx";
import useGetProfile from "../../hooks/useGetProfile.jsx";
import { uploadFile } from "../../api/storage.js";
import Cropper from "../../components/CustomCropper";
import GrabItem from "../../components/GrabItem";
import CustomIconButton from "../../components/CustomIconButton";
import Meraviglia from "../../components/Meraviglia";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";

import styles from "./css/ConfirmUpload.module.css";

export default function ConfirmUpload() {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(min-width: 992px)");
  const isLandscape = useMediaQuery("(orientation: landscape)");
  // eslint-disable-next-line
  const [drawerHeight, setDrawerHeight] = useState(
    isLargeScreen ? "auto" : isLandscape ? "90%" : "auto"
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const lang = useSelector((state) => state.userLang.value);
  const fileInputRef = useRef(null);
  //Image
  const [image, setImage] = useState(null);
  const [imageUrlLocal, setImageUrlLocal] = useState("");
  const [avatar_id, setAvatar_id] = useState("");
  // eslint-disable-next-line
  const [file, setFile] = useState(null);
  const { updateUserProfile } = useUpdateProfile();

  //DEFAULT VALUES
  const { userProfileLoading, userProfile } = useGetProfile();

  useEffect(() => {
    if (!userProfileLoading) {
      setImage(userProfile.user.avatar ? userProfile.user.avatar.file : null);
    }
  }, [userProfileLoading, userProfile]);

  const handleClose = () => {
    if (window.location.href.includes("modify-user")) {
      navigate(`/modify-user/${user_id}`, { state: { image, avatar_id } });
    } else {
      navigate("/profile-setup");
    }
  };

  const toggleDrawer = (state) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(state);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChangeLocal = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxFileSize = 1 * 1024 * 1024; // For example, 5MB

      if (file.size > maxFileSize) {
        dispatch(
          setNotification({
            open: true,
            message: t["snackbars"]["file-too-big"][lang],
            severity: "error",
          })
        );
      } else {
        setFile(file);
        const imageUrl = URL.createObjectURL(file);
        setImageUrlLocal(imageUrl); // Set the URL for preview
        setOpenDrawer(false); // Close the drawer after selection
        setOpenCropper(true);
      }

      event.target.value = ""; // Reset the file input value to allow for new upload attempts
    }
  };

  //TAKE PHOTO
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const videoRef = useRef(null);

  function stopVideo() {
    const videoElement = videoRef.current;
    const canvasElement = document.createElement("canvas");
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;
    const context = canvasElement.getContext("2d");
    context.drawImage(
      videoElement,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    const stream = videoElement.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    videoElement.srcObject = null;
    setIsVideoPlay(false);
    setOpenDrawer(false);
    setIsVideoReady(false);
  }

  const handleTakePhotoClick = async () => {
    setOpenDrawer(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        setIsVideoPlay(true);
      }
      setIsVideoReady(true);
    } catch (error) {
      setIsVideoPlay(false);
      console.error("Error accessing the camera", error);
      // Gestisci l'errore, ad esempio mostrando un messaggio all'utente
    }
  };

  const capturePhoto = () => {
    if (videoRef.current) {
      const videoElement = videoRef.current;
      const canvasElement = document.createElement("canvas");
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;
      const context = canvasElement.getContext("2d");
      context.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );

      // Convert the canvas image to data URL
      const imageDataUrl = canvasElement.toDataURL("image/png");
      setImageUrlLocal(imageDataUrl);

      const stream = videoElement.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach(function (track) {
        track.stop();
      });

      videoElement.srcObject = null;
      setIsVideoPlay(false);
      setOpenDrawer(false);
      setIsVideoReady(false);

      // Set the capture picture
      setImage(imageDataUrl);
    }
  };

  const handleConfirmUpload = () => {
    if (imageUrlLocal) {
      fetch(imageUrlLocal)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "filename", { type: "image/png" }); // Assume png as an example
          uploadFile(file)
            .then((response) => {
              setAvatar_id(response.data.media_id);
              setImage(imageUrlLocal);
              dispatch(
                setNotification({
                  open: true,
                  message: t["upload-success"][lang],
                  severity: "success",
                })
              );
              updateUserProfile({ avatar: response.data.media_id }, () => {
                // Success callback
                dispatch(
                  setNotification({
                    open: true,
                    message: t["upload-success"][lang],
                    severity: "success",
                  })
                );
              });
              setImageUrlLocal("");
            })
            .catch((error) => {
              try {
                dispatch(
                  setNotification({
                    open: true,
                    message: error.response.data.data
                      ? error.response.data.data.error
                      : "Something went wrong.",
                    severity: "error",
                  })
                );
              } catch (error) {
                console.error(error);
                dispatch(
                  setNotification({
                    open: true,
                    message: "Failed to upload photo",
                    severity: "error",
                  })
                );
              }
            });
        });
    }
  };

  //MANAGE DIALOG DELETE
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDeleteButton = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    updateUserProfile({ avatar: "" }, () => {
      // Success callback
      dispatch(
        setNotification({
          open: true,
          message: t["delete-success"][lang],
          severity: "success",
        })
      );
      navigate(`/modify-user/${user_id}`);
    });

    setIsDeleteDialogOpen(false);
  };

  //CROP IMAGE
  const [openCropper, setOpenCropper] = useState(false);
  const [croppedUrlImage, setCroppedUrlImage] = useState(null);

  const cancelCrop = () => {
    // Close cropper
    setOpenCropper(false);
    setImageUrlLocal(croppedUrlImage);
    setFile(null);
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      {isVideoPlay && (
        <Box sx={{ width: "100%" }}>
          <Box className={styles.boxSkip}>
            <CustomIconButton
              icon={crossIcon}
              iconAlt={"close"}
              onClickFunction={() => {
                stopVideo(false, false);
              }}
            />
          </Box>
          <Box className={styles.cameraBox}>
            <video ref={videoRef} className={styles.camera}></video>
            <Button
              variant="contained"
              color="primary"
              onClick={capturePhoto}
              className={styles.button + " " + styles.takePhotoButton}
              disabled={!isVideoReady}
            >
              {t["upload-take"][lang]}
            </Button>
          </Box>
        </Box>
      )}

      {!isVideoPlay && (
        <Box className={styles.centerBox}>
          <Box className={styles.boxSkip}>
            <CustomIconButton
              icon={crossIcon}
              iconAlt={"close"}
              onClickFunction={handleClose}
            />
          </Box>

          <Typography
            variant="h3"
            fontWeight="500"
            color="initial"
            textAlign="center"
          >
            {t["upload-title"][lang]}
          </Typography>

          <Box className={styles.avatarBox}>
            <img
              // In this way we can use the cropper in preview
              src={imageUrlLocal || croppedUrlImage || image}
              className={styles.avatar}
              alt="Preview"
            />
          </Box>
          <Button
            fullWidth
            className={styles.button}
            variant={
              imageUrlLocal || croppedUrlImage ? "outlined" : "contained"
            }
            color={!imageUrlLocal || !croppedUrlImage ? "primary" : "inherit"}
            onClick={() => setOpenDrawer(true)}
          >
            {!imageUrlLocal || !croppedUrlImage
              ? t["upload-upload"][lang]
              : t["upload-change"][lang]}
          </Button>
          <Button
            fullWidth
            className={styles.button}
            variant={
              !imageUrlLocal && !croppedUrlImage ? "outlined" : "contained"
            }
            color={imageUrlLocal || croppedUrlImage ? "primary" : "inherit"}
            disabled={!imageUrlLocal && !croppedUrlImage}
            onClick={handleConfirmUpload}
          >
            {t["upload-confirm"][lang]}
          </Button>
        </Box>
      )}
      <Meraviglia padding={1} />
      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        classes={{ paper: styles.drawerPaper }}
      >
        <Container maxWidth="sm">
          <Box className={styles.buttonsContainer}>
            <GrabItem
              drawerHeight={drawerHeight}
              setDrawerOpen={setOpenDrawer}
            />
            <Button
              variant="contained"
              color="primary"
              className={styles.button}
              onClick={handleUploadClick}
            >
              <img
                src={uploadIcon}
                alt="close"
                className={styles.icon}
                style={{
                  filter: "brightness(0) invert(1)",
                  transform: "translate(-10px, 0)",
                }}
              />
              {t["upload-photo"][lang]}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChangeLocal}
              style={{ display: "none" }}
              accept="image/*"
            />
            <Button
              variant="outlined"
              color="primary"
              className={styles.button}
              onClick={() => {
                handleTakePhotoClick();
                setIsVideoPlay(true);
              }}
            >
              <img
                src={photoIcon}
                alt="close"
                className={styles.icon}
                style={{
                  transform: "translate(-10px, 0)",
                }}
              />
              {t["upload-take"][lang]}
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              className={styles.button}
              onClick={handleDeleteButton}
            >
              <img
                src={trashIcon}
                alt="trashIcon"
                className={styles.icon}
                style={{
                  transform: "translate(-10px, 0)",
                }}
              />
              {t["upload-remove"][lang]}
            </Button>
          </Box>
        </Container>
        <Meraviglia padding={1} />
        <DeleteConfirmationDialog
          open={isDeleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
        />
      </Drawer>
      <Cropper
        open={openCropper}
        imageUrlLocal={imageUrlLocal}
        setImageUrlLocal={setImageUrlLocal}
        setOpenCropper={setOpenCropper}
        setFile={setFile}
        cancelCrop={cancelCrop}
        setCroppedUrlImage={setCroppedUrlImage}
      />
    </Container>
  );
}
