import { useEffect, useState } from "react";
import { Typography, FormControl, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ButtonTooltip from "../ButtonTooltip";
import { validateUsername } from "../../api/authentication.js";
import { useSelector } from "react-redux";
import editIcon from "../../assets/icons/Icone/fi-rr-pencil.png";
import { translations as t } from "../../assets/translations.js";
import { textFieldStyle } from "../../themes.js";
import styles from "./ValidateUsername.module.css";
import { containsInvalidCharacters } from "../../utils.js";

export default function ValidateUsername({ props }) {
  const lang = useSelector((state) => state.userLang.value);
  const [userHasTyped, setUserHasTyped] = useState(false);

  useEffect(() => {
    // Se c'è un username di default, esegui il controllo
    if (props.username) {
      handleUsernameChange(props.username);
    }
    // eslint-disable-next-line
  }, []);

  async function handleUsernameChange(newUsername) {
    setUserHasTyped(true);
    props.setCheckingUsername(true); // Begin check
    props.setUsername(newUsername);

    // Checking invalid chars
    const invalidChars = containsInvalidCharacters(newUsername);
    props.setHasInvalidCharacters(invalidChars.length > 0);
    props.setInvalidChars(invalidChars);

    // Checking username numeric
    const isNumeric = /^\d+$/.test(newUsername);
    props.setIsNumeric(isNumeric);

    // Checking username length
    if (newUsername.length >= 5) {
      props.setLengthValid(true);
    } else {
      props.setLengthValid(false);
    }

    // If username is valid, check if it's taken
    if (newUsername.length >= 5 && invalidChars.length === 0 && !isNumeric) {
      if (props.debounceTimeout) {
        clearTimeout(props.debounceTimeout);
      }
      props.setDebounceTimeout(
        setTimeout(() => {
          validateUsername(newUsername)
            .then(() => {
              props.setIsUsernameTaken(false);
            })
            .catch(() => {
              props.setIsUsernameTaken(true);
            })
            .finally(() => {
              props.setCheckingUsername(false);
            });
        }, 1000)
      );
    } else {
      props.setLengthValid(false);
      props.setIsUsernameTaken(false);
      props.setCheckingUsername(false);
    }
  }

  return (
    <FormControl id="validate-username" fullWidth className={styles.form}>
      <TextField
        name="username"
        label={t["psu-l3"][lang]}
        InputLabelProps={props.inputLabelProps}
        required={props.required}
        maxRows={4}
        value={props.username.toLowerCase()} // Remove toLowerCase
        onChange={(e) => handleUsernameChange(e.target.value)}
        InputProps={{
          startAdornment: props.modifyprofile && (
            <img src={editIcon} alt="edit" className={styles.editicon} />
          ),
          endAdornment: props.checkingUsername ? (
            <CircularProgress size={24} />
          ) : props.lengthValid &&
            !props.isUsernameTaken &&
            !props.hasInvalidCharacters &&
            !props.isNumeric &&
            userHasTyped ? (
            <CheckCircleOutlineIcon style={{ color: "green" }} />
          ) : props.username &&
            (props.isUsernameTaken ||
              !props.lengthValid ||
              props.hasInvalidCharacters ||
              props.isNumeric) ? (
            <ButtonTooltip title={"Username invalido o non disponibile"}>
              <ErrorOutlineIcon color="error" />
            </ButtonTooltip>
          ) : null,
        }}
        error={
          !props.checkingUsername &&
          !props.landingPage &&
          ((props.isUsernameTaken &&
            props.firstLoadData.username !== props.username) ||
            !props.lengthValid ||
            props.hasInvalidCharacters ||
            props.isNumeric)
        }
        sx={textFieldStyle}
      />
      {/* HELPER TEXT */}
      {!props.checkingUsername && !props.landingPage ? (
        <Typography
          sx={{ mx: 3 }}
          variant="body2"
          color={
            props.isUsernameTaken ||
            !props.lengthValid ||
            props.hasInvalidCharacters ||
            props.isNumeric
              ? "error"
              : "primary"
          }
        >
          {props.isUsernameTaken &&
          props.firstLoadData.username !== props.username
            ? t["psu-username-utilizzato"][lang]
            : props.isNumeric
              ? t["psu-username-numeric"][lang]
              : !props.lengthValid
                ? t["psu-username-lenght"][lang]
                : props.hasInvalidCharacters
                  ? t["psu-username-invalid-characters"][lang]
                  : ""}
        </Typography>
      ) : null}
      {props.hasInvalidCharacters && (
        <Typography sx={{ mx: 3 }} color="error" variant="body2">
          {t["psu-username-list-characters"][lang]}
          {props.invalidChars.join(", ")}
        </Typography>
      )}
      {!props.checkingUsername &&
        !props.isUsernameTaken &&
        props.lengthValid &&
        !props.hasInvalidCharacters &&
        !props.isNumeric &&
        !props.landingPage &&
        userHasTyped && (
          <Typography
            sx={{
              mx: 3,
              textOverflow: "ellipsis",
              whiteSpace: "wrap",
            }}
            variant="body2"
            noWrap
            color={
              props.isUsernameTaken ||
              !props.lengthValid ||
              !props.hasInvalidCharacters
                ? "primary"
                : "error"
            }
          >
            {t["psu-username-valid"][lang]}
            <br />
            <b>https://hellolink.pro/{props.username}</b>
          </Typography>
        )}
    </FormControl>
  );
}
