import { createSlice } from "@reduxjs/toolkit";

export const userTypeSlice = createSlice({
  name: "userType",
  initialState: {
    userType: null,
  },
  reducers: {
    setUserType: (state, action) => {
      state.userType = action.payload.userType;
    },
    clearUserType: (state) => {
      state.userType = null;
    },
  },
});

export const { setUserType, clearUserType } = userTypeSlice.actions;

export default userTypeSlice.reducer;
