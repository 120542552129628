import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import styles from "../css/FontSelection.module.css";
import { textFieldStyle } from "../../../themes";
import { getFonts } from "../../../api/preferences.js";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../slices/notificationSnackbar.js";

const FontSelection = ({ label, selectedFont, onTextFontSelection }) => {
  const [localSelectedFont, setSelectedFont] = useState(selectedFont);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedFont(selectedFont);
  }, [selectedFont]);

  const [fonts, setFonts] = useState([]);

  useEffect(() => {
    getFonts()
      .then((response) => {
        const identifiers = response.data.data.map((obj) => obj.identifier);
        setFonts(identifiers);
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  }, [dispatch]);

  const changingFont = (event, newValue) => {
    setSelectedFont(newValue);
    onTextFontSelection(newValue);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          position: "relative",
          mt: label ? "20px" : "0px",
          borderRadius: "20px",
          padding: label ? "15px 20px" : "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
        }}
      >
        {label ?? (
          <Typography variant="caption" className={styles.caption}>
            {label}
          </Typography>
        )}
        <Accordion
          sx={{
            borderRadius: "20px",
            boxShadow: "none",
            border: "none !important",
            padding: "0",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-label="Expand"
            aria-controls="font-content"
            id="font-header"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Box
              sx={{
                height: "27px",
                width: "27px",
                bgcolor: "#F2F2F2",
                position: "relative",
                zIndex: 1,
                borderRadius: "4px",
                fontFamily: localSelectedFont,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              Aa
            </Box>
            <Typography
              sx={{
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
                fontFamily: localSelectedFont, // Apply font family to the Typography
              }}
            >
              {localSelectedFont}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Autocomplete
              options={fonts}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} style={{ fontFamily: option }}>
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by font name"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    style: { fontFamily: localSelectedFont }, // Apply font family to input text
                  }}
                  sx={{
                    ...textFieldStyle,
                    width: "100%",
                  }}
                />
              )}
              value={localSelectedFont}
              onChange={changingFont}
              sx={{
                ...textFieldStyle,
                width: "100%",
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default FontSelection;
