import { axiosInstance } from "./common.js";

export const getCategories = (path) => {
  const apiEndpoint = `assets/categories/${path}/`;

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getModules = () => {
  const apiEndpoint = "assets/modules/";

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getIcons = () => {
  const apiEndpoint = "assets/icons/";

  return axiosInstance
    .get(apiEndpoint, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCountries = () => {
  const apiEndpoint = `assets/countries/`;

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getProvinces = (country_id) => {
  const apiEndpoint = `assets/provinces/${country_id}/`;

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCities = (province_id) => {
  const apiEndpoint = `assets/cities/${province_id}/`;

  return axiosInstance
    .get(apiEndpoint)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
