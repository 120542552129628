import React from "react";
import { Box, Container } from "@mui/material";
import HeroNav from "./HeroNav";
import HeroGrid from "./HeroGrid";

const Hero = () => {
  return (
    <Box sx={{ bgcolor: "#007FFF" }}>
      <Container sx={{
        padding: {
          xs: "40px 16px 16px 16px",
          md: "50px 32px 70px 32px"
        }
      }}>
        <HeroNav />
        <HeroGrid />
      </Container>
    </Box>
  );
};

export default Hero;
