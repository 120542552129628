import React from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import styles from "./GrabItem.module.css";

export default function GrabItem({ setDrawerOpen }) {
  const handleMouseDown = () => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = () => {
    setDrawerOpen(false);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = () => {
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  };

  const handleTouchMove = () => {
    // Qui puoi calcolare la posizione del touch e aggiornare lo stato come necessario
    // Ad esempio, puoi usare event.touches[0].clientY per la posizione verticale del dito
    setDrawerOpen(false);
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleTouchEnd);
  };

  return (
    <Box
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onClick={() => setDrawerOpen(false)}
      className={styles.dragableContainer}
    >
      <Divider className={styles.dividerStyle} />
    </Box>
  );
}
