import React, { useState, useEffect } from "react";

import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  Typography,
  Container,
  Divider,
  Box,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AddressBookIcon from "../../assets/icons/Icone/fi-rr-address-book.png";
import trashIcon from "../../assets/icons/Icone/fi-rr-trash.png";
import { translations as t } from "../../assets/translations.js";
import { capitalizeFirstLetterOfEachWord } from "../../utils";
import {
  menuPropsContact as menuProps,
  numberInputStyle,
  textFieldStyle,
} from "../../themes";
import { createLink, getContactData, updateLink } from "../../api/links.js";
import { setNotification } from "../../slices/notificationSnackbar.js";

import Meraviglia from "../../components/Meraviglia";
import Header from "./components/Header";
import CountrySelect from "./components/CountrySelect";
import ProvinceSelect from "./components/ProvinceSelect";
import CitySelect from "./components/CitySelect";
import CustomIconButton from "../../components/CustomIconButton";

import styles from "./css/LinksInfo.module.css";

export default function ContactInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const dataFromButton = location.state || {};
  const lang = useSelector((state) => state.userLang.value);
  const icon = dataFromButton.icon;
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [nomeAttivita, setNomeAttivita] = useState("");
  const [ruolo, setRuolo] = useState("");
  const [piva, setPIVA] = useState("");
  const [sdi, setSDI] = useState("");
  const dispatch = useDispatch();
  // Utilizziamo uno stato per tenere traccia di ogni sezione di input che può essere aggiunta dinamicamente
  const [phoneFields, setPhoneFields] = useState([
    { type: "MOBILE", phonenumber: "" },
  ]);
  const [emailFields, setEmailFields] = useState([{ type: "WORK", email: "" }]);
  const [addressFields, setAddressFields] = useState([
    {
      type: "HOME",
      address_line1: "",
      address_line2: "",
      house_number: "",
      country: "",
      province: "",
      city: "",
      zip_code: "",
    },
  ]);

  // CODICE IN PREVISIONE DELL'ENDPOINT
  const categoryId = dataFromButton.category_id || dataFromButton.category.id;
  const idAlreadyCreate = dataFromButton ? dataFromButton.id : null;
  const idContact = dataFromButton ? dataFromButton.contact : null;

  const [contactInfo, setContactInfo] = useState({
    name: "",
    surname: "",
    business_name: "",
    role: "",
    vat_number: "",
    sdi: "",
    phone_numbers: [{ type: "MOBILE", phonenumber: "" }],
    emails: [{ type: "WORK", email: "" }],
    addresses: [
      {
        type: "HOME",
        address_line1: "",
        address_line2: "",
        house_number: "",
        country: "",
        province: "",
        city: "",
        zip_code: "",
      },
    ],
  });

  const [linkData, setLinkData] = useState({
    title: "",
    contact: contactInfo,
    type: "BUTTON",
    icon: icon,
    is_visible: true,
  });

  const [headerData, setHeaderData] = useState({
    id: idAlreadyCreate,
    title: linkData.title,
    style: dataFromButton.type ? dataFromButton.type : linkData.type,
    icon: linkData.icon,
    icon_id: linkData.icon.id,
    show: linkData.is_visible,
    page: "contact",
  });

  useEffect(() => {
    if (idAlreadyCreate && idContact) {
      getContactData(idAlreadyCreate, idContact)
        .then((response) => {
          setLinkData({
            id: response.data.id,
            title: response.data.title,
            contact: response.data.contact,
            type: response.data.type,
            category: response.data.category,
            icon: response.data.icon,
            is_visible: response.data.is_visible,
            order: response.data.order,
          });
          setHeaderData({
            id: response.data.id,
            title: response.data.title,
            style: response.data.type,
            show: response.data.is_visible,
            icon: response.data.icon,
          });
          setContactInfo(response.data.contact);
          setNome(response.data.contact.name);
          setCognome(response.data.contact.surname);
          setNomeAttivita(response.data.contact.business_name);
          setRuolo(response.data.contact.role);
          setPIVA(response.data.contact.vat_number);
          setSDI(response.data.contact.sdi);
          setPhoneFields(
            response.data.contact.phone_numbers[0]
              ? response.data.contact.phone_numbers
              : [{ type: "MOBILE", phonenumber: "" }]
          );
          setEmailFields(
            response.data.contact.emails[0]
              ? response.data.contact.emails
              : [{ type: "WORK", email: "" }]
          );
          setAddressFields(
            response.data.contact.addresses[0]
              ? response.data.contact.addresses
              : [
                  {
                    type: "HOME",
                    address_line1: "",
                    address_line2: "",
                    house_number: "",
                    country: "",
                    province: "",
                    city: "",
                    zip_code: "",
                  },
                ]
          );
          setSelectedCountry(
            response.data.contact.addresses[0]
              ? response.data.contact.addresses[0].country
              : null
          );
          setSelectedProvince(
            response.data.contact.addresses[0]
              ? response.data.contact.addresses[0].province
              : null
          );
          setSelectedCity(
            response.data.contact.addresses[0]
              ? response.data.contact.addresses[0].city
              : null
          );
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    }
    // eslint-disable-next-line
  }, [idAlreadyCreate, idContact]);

  const handleSubmit = () => {
    if (idAlreadyCreate === undefined) {
      let request = {
        title: headerData.title ? headerData.title : t["btsmenu-contact"][lang],
        type: headerData.style,
        category_id: categoryId,
        icon_id: headerData.icon_id,
        is_visible: headerData.show,
        contact: {
          name: nome,
          surname: cognome,
          business_name: nomeAttivita,
          role: ruolo,
          vat_number: piva,
          sdi: sdi,
        },
      };

      if (emailFields.length > 0 && emailFields[0].email !== "") {
        request.contact.emails = emailFields;
      }

      if (phoneFields.length > 0 && phoneFields[0].phonenumber !== "") {
        request.contact.phone_numbers = phoneFields;
      }

      if (
        addressFields.length > 0 &&
        (addressFields[0].address_line1 !== "" ||
          addressFields[0].address_line2 !== "" ||
          addressFields[0].house_number !== "" ||
          addressFields[0].country !== "" ||
          addressFields[0].province !== "" ||
          addressFields[0].city !== "" ||
          addressFields[0].zip_code !== "")
      ) {
        request.contact.addresses = [
          {
            type: addressFields[0].type,
            address_line1: addressFields[0].address_line1,
            address_line2: addressFields[0].address_line2,
            house_number: addressFields[0].house_number,
            country: addressFields[0].country
              ? addressFields[0].country.id
              : undefined,
            province: addressFields[0].province
              ? addressFields[0].province.id
              : undefined,
            city: addressFields[0].city ? addressFields[0].city.id : undefined,
            zip_code: addressFields[0].zip_code,
          },
        ];
      }

      createLink(request)
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );
          navigate("/overview", { state: { newData: response.data } });
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    } else {
      let request = {
        title: headerData.title ? headerData.title : t["btsmenu-contact"][lang],
        type: headerData.style,
        category_id: categoryId,
        icon_id: headerData.icon_id || linkData.icon.id,
        is_visible: headerData.show,
        contact: {
          id: idContact,
          name: nome,
          surname: cognome,
          business_name: nomeAttivita,
          role: ruolo,
          vat_number: piva,
          sdi: sdi,
          phone_numbers: phoneFields,
          emails: emailFields,
          addresses: addressFields,
        },
      };

      if (emailFields.length > 0 && emailFields[0].email !== "") {
        request.contact.emails = emailFields;
      } else {
        request.contact.emails = [];
      }

      if (phoneFields.length > 0 && phoneFields[0].phonenumber !== "") {
        request.contact.phone_numbers = phoneFields;
      } else {
        request.contact.phone_numbers = [];
      }

      if (
        addressFields.length > 0 &&
        (addressFields[0].address_line1 !== "" ||
          addressFields[0].address_line2 !== "" ||
          addressFields[0].house_number !== "" ||
          addressFields[0].country !== "" ||
          addressFields[0].province !== "" ||
          addressFields[0].city !== "" ||
          addressFields[0].zip_code !== "")
      ) {
        request.contact.addresses = [
          {
            type: addressFields[0].type,
            address_line1: addressFields[0].address_line1,
            address_line2: addressFields[0].address_line2,
            house_number: addressFields[0].house_number,
            country: addressFields[0].country
              ? addressFields[0].country.id
              : undefined,
            province: addressFields[0].province
              ? addressFields[0].province.id
              : undefined,
            city: addressFields[0].city ? addressFields[0].city.id : undefined,
            zip_code: addressFields[0].zip_code,
          },
        ];
      } else {
        request.contact.addresses = [];
      }
      updateLink(idAlreadyCreate, request)
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );
          navigate("/overview", { state: { newData: response.data } });
        })
        .catch((error) => {
          console.error(error.data);
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    }
  };

  // Funzioni per gestire l'aggiunta di nuovi campi
  const addEmailField = () => {
    setEmailFields([...emailFields, { type: "WORK", email: "" }]);
  };

  const addPhoneField = () => {
    setPhoneFields([
      ...phoneFields,
      { type: "MOBILE", prefix: "", phonenumber: "" },
    ]);
  };

  // eslint-disable-next-line
  const addAddressField = () => {
    setAddressFields([
      ...addressFields,
      {
        type: "HOME",
        address_line1: "",
        address_line2: "",
        zip_code: "",
        city: null,
        province: null,
        state: "",
        country: null,
      },
    ]);
  };

  // Stato per tenere traccia della selezione di paese e provincia
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  // Funzione di callback per aggiornare lo stato quando un paese viene selezionato
  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    // Resetta la selezione della provincia e della città quando il paese cambia
    setSelectedProvince(null);
    setSelectedCity(null);
  };

  // Funzione di callback per aggiornare lo stato quando una provincia viene selezionata
  const handleProvinceChange = (province) => {
    setSelectedProvince(province);
    setSelectedCity(null);
  };

  // Funzione di callback per aggiornare lo stato quando una città viene selezionata
  const handleCityChange = (city) => {
    setSelectedCity(city);
  };

  const handleEmailChange = (index, field, value) => {
    const newEmailFields = [...emailFields];
    newEmailFields[index] = { ...newEmailFields[index], [field]: value };
    setEmailFields(newEmailFields);
  };

  const handlePhoneChange = (index, field, value) => {
    const newPhoneFields = [...phoneFields];
    // Assicurati che il valore inserito non superi i 10 numeri
    newPhoneFields[index] = { ...newPhoneFields[index], [field]: value };
    setPhoneFields(newPhoneFields);
  };

  const handleAddressChange = (index, field, value) => {
    const newAddressFields = [...addressFields];
    newAddressFields[index] = { ...newAddressFields[index], [field]: value };
    setAddressFields(newAddressFields);
  };

  // Aggiungi funzioni per gestire i cambiamenti nei campi di input e select
  const handleSelectChange = (list, setList, index, field, value) => {
    const updatedList = [...list];
    updatedList[index] = { ...updatedList[index], [field]: value };
    setList(updatedList);
  };

  const handleHeaderDataChange = (key, value) => {
    setHeaderData((prev) => ({ ...prev, [key]: value }));
  };

  //VERIFICA MAIL
  const [emailValidity, setEmailValidity] = useState(
    contactInfo.emails.map(() => true)
  );

  useEffect(() => {
    const validateEmails = () => {
      const updatedValidity = emailFields.map((field) =>
        field.email ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(field.email) : true
      );
      setEmailValidity(updatedValidity);
    };
    validateEmails();
  }, [emailFields]);

  //VERIFICA NUMERO DI TELEFONO
  const [phoneValidity, setPhoneValidity] = useState(
    contactInfo.phone_numbers.map(() => true)
  );

  useEffect(() => {
    const validatePhoneNumbers = () => {
      const updatedValidity = phoneFields.map(
        (field) =>
          field.phonenumber
            ? /^[0-9+{1}$\s]{10,}$/.test(field.phonenumber)
            : true // Esempio: valida numeri di 10 cifre
      );
      setPhoneValidity(updatedValidity);
    };
    validatePhoneNumbers();
  }, [phoneFields]);

  //RIMUOVI CAMPI
  const removeEmailField = (index) => {
    setEmailFields(emailFields.filter((_, i) => i !== index));
  };

  const removePhoneField = (index) => {
    setPhoneFields(phoneFields.filter((_, i) => i !== index));
  };

  const removeAddressField = (index) => {
    setAddressFields(addressFields.filter((_, i) => i !== index));
  };

  //Invio del form con premendo invio
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <FormGroup onKeyPress={handleKeyPress} className={styles.formGroup}>
        <Header
          headerData={headerData}
          onTitleChange={(value) => handleHeaderDataChange("title", value)}
          onStyleChange={(value) => handleHeaderDataChange("style", value)}
          onIconChange={(value) => handleHeaderDataChange("icon", value)}
          onIconIdChange={(value) => handleHeaderDataChange("icon_id", value)}
          onSwitchChange={(value) => handleHeaderDataChange("show", value)}
        />
        <Container component="main" className={styles.mainContainer}>
          <Box className={styles.centerContainer}>
            <img
              src={AddressBookIcon}
              alt="AddressBookIcon"
              className={styles.iconTitle}
            />
            <Typography
              variant="h5"
              fontWeight="500"
              color="initial"
              sx={{ px: 2 }}
            >
              {t["contact-title"][lang]}
            </Typography>
          </Box>
          <FormControl id="field-name" fullWidth margin="dense" sx={{ mt: 2 }}>
            <TextField
              name="name"
              required
              label={t["contact-name"][lang]}
              variant="outlined"
              sx={textFieldStyle}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </FormControl>
          <FormControl id="field-surname" fullWidth margin="dense">
            <TextField
              name="surname"
              label={t["contact-surname"][lang]}
              variant="outlined"
              sx={textFieldStyle}
              value={cognome}
              onChange={(e) => setCognome(e.target.value)}
            />
          </FormControl>
          <Divider sx={{ mt: 1, mb: 0.5 }} />
          <FormControl id="field-company-name" fullWidth margin="dense">
            <TextField
              name="company-name"
              label={t["contact-attivita"][lang]}
              variant="outlined"
              value={nomeAttivita}
              onChange={(e) => setNomeAttivita(e.target.value)}
              sx={textFieldStyle}
            />
          </FormControl>
          <FormControl id="field-role" fullWidth margin="dense">
            <TextField
              name="role"
              label={t["contact-ruolo"][lang]}
              variant="outlined"
              value={ruolo}
              onChange={(e) => setRuolo(e.target.value)}
              sx={textFieldStyle}
            />
          </FormControl>
          <Divider sx={{ mt: 1, mb: 1.5 }} />
          {/* Sezione Email */}
          {emailFields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControl id="select-email-type" fullWidth margin="dense">
                    <InputLabel>{t["contact-tipo"][lang]}</InputLabel>
                    <Select
                      name="email-type"
                      MenuProps={menuProps}
                      value={field.type}
                      onChange={(e) =>
                        handleSelectChange(
                          emailFields,
                          setEmailFields,
                          index,
                          "type",
                          e.target.value
                        )
                      }
                      sx={textFieldStyle}
                      variant="outlined"
                      label="Tipo"
                    >
                      <MenuItem className={styles.contactMenuItem} value="WORK">
                        {t["contact-lavoro"][lang]}
                      </MenuItem>
                      <MenuItem
                        className={styles.contactMenuItem}
                        value="PERSONAL"
                      >
                        {t["contact-personale"][lang]}
                      </MenuItem>
                      <MenuItem className={styles.contactMenuItem} value="PEC">
                        {t["contact-pec"][lang]}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={index > 0 ? 7 : 8}>
                  <FormControl id="field-email" fullWidth margin="dense">
                    <TextField
                      name="email"
                      label={t["contact-mail"][lang]}
                      variant="outlined"
                      sx={textFieldStyle}
                      value={field.email}
                      type="email"
                      onChange={(e) =>
                        handleEmailChange(index, "email", e.target.value)
                      }
                      error={!emailValidity[index]}
                    />
                    {!emailValidity[index] && (
                      <Typography sx={{ pl: 3 }} variant="body2" color="error">
                        {t["helpertext-email"][lang]}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {index > 0 ? (
                  <Grid item xs={1} className={styles.centerContainer}>
                    <CustomIconButton
                      icon={trashIcon}
                      iconAlt={"delete"}
                      onClickFunction={() => removeEmailField(index)}
                    />
                  </Grid>
                ) : null}
              </Grid>
              {index !== emailFields.length - 1 ? (
                <Divider sx={{ my: 1 }} />
              ) : null}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              aria-label="add"
              variant="dashed"
              color="inherit"
              onClick={addEmailField}
              className={styles.addButton}
            >
              <AddIcon className={styles.addIcon} />
            </Button>
          </Grid>

          <Divider sx={{ my: 1.5 }} />

          {/* Sezione Telefono */}
          {phoneFields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControl id="select-phone-type" fullWidth margin="dense">
                    <InputLabel>{t["contact-tipo"][lang]}</InputLabel>
                    <Select
                      name="phone-type"
                      MenuProps={menuProps}
                      value={field.type}
                      onChange={(e) =>
                        handleSelectChange(
                          phoneFields,
                          setPhoneFields,
                          index,
                          "type",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      label="Tipo"
                      sx={textFieldStyle}
                    >
                      <MenuItem
                        className={styles.contactMenuItem}
                        value="MOBILE"
                      >
                        {t["contact-mobile"][lang]}
                      </MenuItem>
                      <MenuItem className={styles.contactMenuItem} value="WORK">
                        {t["contact-lavoro"][lang]}
                      </MenuItem>
                      <MenuItem
                        className={styles.contactMenuItem}
                        value="LANDLINE"
                      >
                        {t["contact-fisso"][lang]}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={index > 0 ? 7 : 8}>
                  <FormControl id="field-phone" fullWidth margin="dense">
                    <TextField
                      name="phone"
                      label={t["contact-cell"][lang]}
                      type="tel"
                      variant="outlined"
                      error={!phoneValidity[index]}
                      sx={numberInputStyle}
                      value={field.phonenumber}
                      inputProps={{ pattern: "\\d*" }} // Imposta il limite di caratteri a 5
                      onChange={(e) =>
                        handlePhoneChange(index, "phonenumber", e.target.value)
                      }
                    />
                    {!phoneValidity[index] && (
                      <Typography sx={{ pl: 3 }} variant="body2" color="error">
                        {t["helpertext-phone"][lang]}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {index > 0 ? (
                  <Grid
                    item
                    xs={1}
                    className={styles.centerContainer}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <CustomIconButton
                      icon={trashIcon}
                      iconAlt={"delete"}
                      onClickFunction={() => removePhoneField(index)}
                    />
                  </Grid>
                ) : null}
              </Grid>
              {index !== phoneFields.length - 1 ? (
                <Divider sx={{ my: 1 }} />
              ) : null}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              aria-label="add"
              variant="dashed"
              color="inherit"
              onClick={addPhoneField}
              className={styles.addButton}
            >
              <AddIcon className={styles.addIcon} />
            </Button>
          </Grid>

          <Divider sx={{ my: 1.5 }} />

          {/* Sezione Indirizzo */}
          {addressFields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControl
                    id="select-address-type"
                    fullWidth
                    margin="dense"
                  >
                    <InputLabel>{t["contact-tipo"][lang]}</InputLabel>
                    <Select
                      name="address-type"
                      MenuProps={menuProps}
                      value={field.type}
                      onChange={(e) =>
                        handleSelectChange(
                          addressFields,
                          setAddressFields,
                          index,
                          "type",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      label="Tipo"
                      sx={textFieldStyle}
                    >
                      <MenuItem className={styles.contactMenuItem} value="HOME">
                        {t["contact-casa"][lang]}
                      </MenuItem>
                      <MenuItem className={styles.contactMenuItem} value="WORK">
                        {t["contact-lavoro"][lang]}
                      </MenuItem>
                      <MenuItem
                        className={styles.contactMenuItem}
                        value="OPOFFICE"
                      >
                        {t["contact-operativa"][lang]}
                      </MenuItem>
                      <MenuItem
                        className={styles.contactMenuItem}
                        value="LOFFICE"
                      >
                        {t["contact-legale"][lang]}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={index > 0 ? 7 : 8}>
                  <FormControl id="field-address" fullWidth margin="dense">
                    <TextField
                      name="address"
                      label={t["contact-indirizzo"][lang]}
                      variant="outlined"
                      value={field.address_line1}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "address_line1",
                          capitalizeFirstLetterOfEachWord(e.target.value)
                        )
                      }
                      sx={textFieldStyle}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl id="field-place" fullWidth margin="dense">
                    <TextField
                      name="place"
                      label={t["contact-place"][lang]}
                      variant="outlined"
                      value={field.address_line2}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "address_line2",
                          capitalizeFirstLetterOfEachWord(e.target.value)
                        )
                      }
                      sx={textFieldStyle}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl id="field-house-number" fullWidth margin="dense">
                    <TextField
                      name="house-number"
                      label={t["contact-house"][lang]}
                      variant="outlined"
                      value={field.house_number}
                      onChange={(e) =>
                        handleAddressChange(
                          index,
                          "house_number",
                          e.target.value
                        )
                      }
                      sx={textFieldStyle}
                    />
                  </FormControl>
                </Grid>
                {index > 0 ? (
                  <Grid item xs={1} className={styles.centerContainer}>
                    <CustomIconButton
                      icon={trashIcon}
                      iconAlt={"delete"}
                      onClickFunction={() => removeAddressField(index)}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={7}>
                  <form autoComplete="off">
                    <FormControl
                      id="autocomplete-country"
                      fullWidth
                      margin="dense"
                    >
                      <CountrySelect
                        value={selectedCountry}
                        index={index}
                        handleAddressChange={handleAddressChange}
                        handleCountryChange={handleCountryChange}
                      />
                    </FormControl>
                  </form>
                </Grid>
                {/* Campo Provincia */}
                <Grid item xs={5}>
                  <form autoComplete="off">
                    <FormControl
                      id="autocomplete-province"
                      fullWidth
                      margin="dense"
                    >
                      <ProvinceSelect
                        value={selectedProvince}
                        index={index}
                        handleAddressChange={handleAddressChange}
                        handleProvinceChange={handleProvinceChange}
                        disabled={!selectedCountry}
                        selectedCountryId={
                          selectedCountry ? selectedCountry.id : null
                        }
                      />
                    </FormControl>
                  </form>
                </Grid>
                {/*
              // Campo Stato
              <Grid item xs={5}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Stato"
                    value={field.state}
                    onChange={(e) =>
                      handleAddressChange(
                        index,
                        "state",
                        capitalizeFirstLetterOfEachWord(e.target.value)
                      )
                    }
                    sx={textFieldStyle}
                  />
                </FormControl>
              </Grid> */}

                {/* Campo Città */}
                <Grid item xs={7}>
                  <form autoComplete="off">
                    <FormControl
                      id="autocomplete-city"
                      fullWidth
                      margin="dense"
                    >
                      <CitySelect
                        value={selectedCity}
                        index={index}
                        handleAddressChange={handleAddressChange}
                        handleCityChange={handleCityChange}
                        disabled={!selectedProvince}
                        selectedProvinceId={
                          selectedProvince ? selectedProvince.id : null
                        }
                      />
                    </FormControl>
                  </form>
                </Grid>
                {/* Campo CAP */}
                <Grid item xs={5}>
                  <form autoComplete="off">
                    <FormControl id="field-zip-code" fullWidth margin="dense">
                      <TextField
                        name="zip-code"
                        label={t["contact-cap"][lang]}
                        value={field.zip_code}
                        onChange={(e) => {
                          handleAddressChange(
                            index,
                            "zip_code",
                            e.target.value
                          );
                        }}
                        sx={numberInputStyle}
                      />
                    </FormControl>
                  </form>
                </Grid>
              </Grid>

              {index !== addressFields.length - 1 ? (
                <Divider sx={{ my: 1 }} />
              ) : null}
            </React.Fragment>
          ))}
          {/* {isAuth ? (
          <Grid item xs={12}>
            <Button
              aria-label="add"
              variant="dashed"
              color="inherit"
              onClick={addAddressField}
              className={styles.addButton}
            >
              <AddIcon className={styles.addIcon} />
            </Button>
          </Grid>
        ) : null} */}
          <Divider sx={{ my: 1.5 }} />

          <FormControl id="field-vat-number" fullWidth margin="dense">
            <TextField
              name="vat-number"
              label={t["contact-iva"][lang]}
              variant="outlined"
              value={piva}
              onChange={(e) => setPIVA(e.target.value)}
              sx={textFieldStyle}
            />
          </FormControl>
          <FormControl id="field-sdi" fullWidth margin="dense">
            <TextField
              name="sdi"
              label={t["contact-sdi"][lang]}
              variant="outlined"
              value={sdi}
              onChange={(e) => setSDI(e.target.value)}
              sx={textFieldStyle}
            />
          </FormControl>
          <Box className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={styles.formButton}
            >
              {t["salva"][lang]}
            </Button>
          </Box>
        </Container>
        <Meraviglia />
      </FormGroup>
    </Container>
  );
}
