import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Grid, Typography, Skeleton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { ListManager } from "react-beautiful-dnd-grid";
// import { importAll } from "../utils";
import styles from "../css/ItemsMenu.module.css";
import { getCategories, getModules } from "../../../api/assets.js";
import { getUserLinks } from "../../../api/links.js";
import { disponiIcone } from "../../../utils.js";
import DrawerButtonsMenu from "../../../components/DrawerButtonsMenu";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../../slices/notificationSnackbar.js";
import CustomIconButton from "../../../components/CustomIconButton";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { ListManager } from "react-beautiful-dnd-grid";
// import { importAll } from "../utils";
import { ThemeProvider } from "@mui/material/styles";
import { themeButtonItemsMenu as themeButtons } from "../../../themes.js";
import { hexToCSSFilter } from "hex-to-css-filter";

const initialMenuItems = Array(4)
  .fill(0)
  .map((_, index) => ({
    skeleton: true,
    id: `skeleton-${index}`,
  }));

export default function ButtonsMenu({ drawerHeight, customAppearance }) {
  const navigate = useNavigate();
  // const location = useLocation();
  const lang = useSelector((state) => state.userLang.value);
  const [dataLoaded, setDataLoaded] = useState(false); // Stato per tracciare il completamento del caricamento dei dati
  const dispatch = useDispatch();
  // Error handling

  const getLinksFunction = async () => {
    try {
      const response = await getUserLinks();
      dispatch(
        setNotification({
          open: false,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(
        setNotification({
          open: true,
          message: error.response.data.data
            ? error.response.data.data.error
            : "Something went wrong.",
          severity: "error",
        })
      );
    }
  };

  // const [arrayDataApi, setArrayDataApi] = useState(() => {
  //   // Inizializzazione dello stato con i dati da localStorage o un array vuoto
  //   const savedData = localStorage.getItem("arrayDataApi");
  //   return savedData ? JSON.parse(savedData) : [];
  // });
  const [arrayDataApi, setArrayDataApi] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const linksData = await getLinksFunction();
      if (linksData) {
        setArrayDataApi(linksData);
        setDataLoaded(true);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  // //Aggiorno ll'array
  // useEffect(() => {
  //   // Controlla se il componente viene da 'add-link'
  //   if (location.state?.newData) {
  //     setArrayDataApi(location.state.newData);
  //   }
  // }, [location.state]);

  // funzione per aggiornare i dati in companypannel
  // useEffect(() => {
  //   if (infoData) {
  //     setArrayData(infoData);
  //   }
  // }, [infoData]);

  // MENU CATEGORIE BOTTONI
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(initialMenuItems);

  const handleOpenDrawer = () => {
    // Apri il drawer immediatamente
    setDrawerOpen(true);
    let slug = "";

    getModules()
      .then((response) => {
        slug = response.data[0].slug;
        dispatch(
          setNotification({
            open: false,
          })
        );

        // Effettua la chiamata API in modo asincrono
        getCategories(slug)
          .then((response) => {
            // Aggiorna i menu items una volta che i dati sono disponibili
            setMenuItems(response.data);
            dispatch(
              setNotification({
                open: false,
              })
            );
          })
          .catch((error) => {
            dispatch(
              setNotification({
                open: true,
                message: error.response.data.data
                  ? error.response.data.data.error
                  : "Something went wrong.",
                severity: "error",
              })
            );
          });
      })
      .catch((error) => {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      });
  };

  const handleClickOpen = (data) => {
    if (data.category.name === `Contact`) {
      navigate(`/modify-link/contact/${data.id}`, { state: data });
    }
    if (data.category.name === `Links/Websites`) {
      navigate(`/modify-link/website/${data.id}`, { state: data });
    }
    if (data.category.name === `PDF`) {
      navigate(`/modify-link/pdf/${data.id}`, { state: data });
    }
    if (data.category.name === `Social Media`) {
      navigate(`/modify-link/social/${data.id}`, { state: data });
    }
    if (data.category.name === `Video`) {
      navigate(`/modify-link/video/${data.id}`, { state: data });
    }
    if (data.category.name === `Review`) {
      navigate(`/modify-link/review/${data.id}`, { state: data });
    }
  };

  // const [icons, setIcons] = useState(
  //   arrayData.filter((item) => item.style === "ICON")
  // );

  // const [buttons, setButtons] = useState(
  //   arrayData.filter((item) => item.style === "BUTTON")
  // );

  const iconsApi = arrayDataApi.filter((item) => item.type === "ICON");

  const buttonsApi = arrayDataApi.filter((item) => item.type === "BUTTON");

  // useEffect(() => {
  //   // Combina gli array 'buttons' e 'icons' per formare 'arrayData'
  //   const combinedArray = [...buttons, ...icons];
  //   setArrayData(combinedArray);

  //   // Salva i dati aggiornati in localStorage
  //   localStorage.setItem("arrayData", JSON.stringify(combinedArray));
  // }, [buttons, icons]);

  // DRAG AND DROP
  // const maxIcons = (numberOfIcons) => {
  //   if (numberOfIcons === 1) return 1;
  //   if (numberOfIcons === 2) return 2;
  //   if (numberOfIcons === 3) return 3;
  //   if (numberOfIcons <= 6) return 3;
  //   if (numberOfIcons <= 8) return 4;
  //   if (numberOfIcons >= 9) return 5;
  // };
  // Aggiungi una funzione per gestire il riordino degli elementi

  // function handleOnDragEnd(result) {
  //   const itemToMove = arrayData.filter(
  //     (item) => item.id === result.draggableId
  //   )[0];

  //   if (!result.destination) return;

  //   if (itemToMove.style === "BUTTON") {
  //     const items = Array.from(buttons);
  //     const [reorderedItem] = items.splice(result.source.index, 1);
  //     items.splice(result.destination.index, 0, reorderedItem);

  //     setButtons(items);
  //   }
  // }

  // const sortList = (list) => {
  //   return list.slice().sort((first, second) => first.order - second.order);
  // };

  // useEffect(() => {
  //   sortList(icons);
  // }, [icons]);

  // const reorderList = (sourceIndex, destinationIndex) => {
  //   if (destinationIndex === undefined || destinationIndex === sourceIndex) {
  //     return;
  //   }

  //   const newIcons = Array.from(icons);
  //   const [reorderedItem] = newIcons.splice(sourceIndex, 1);
  //   newIcons.splice(destinationIndex, 0, reorderedItem);

  //   setIcons(newIcons);
  //   localStorage.setItem("icons", JSON.stringify(newIcons));
  // };

  return (
    <Box className={styles.container}>
      {!dataLoaded ? (
        <>
          <Skeleton variant="rectangular" className={styles.buttonStyle} />
          <Skeleton variant="rectangular" className={styles.buttonStyle} />
          <Skeleton variant="rectangular" className={styles.buttonStyle} />
        </>
      ) : (
        <>
          {buttonsApi.length > 0 && (
            //  <DragDropContext onDragEnd={handleOnDragEnd}>
            //    <Droppable droppableId="droppable-buttons-menu">
            //      {(provided) => (
            <Box
              className={styles.buttonsBox}
              //  {...provided.droppableProps}
              // ref={provided.innerRef}
            >
              {buttonsApi.map((item, index) => {
                const cssFilter = hexToCSSFilter(
                  customAppearance.buttons.font.color.hex
                ).filter.slice(0, -1);

                return (
                  //  <Draggable key={item.id} draggableId={item.id} index={index}>
                  //    {(provided) => (
                  //      <div
                  //        ref={provided.innerRef}
                  //        {...provided.draggableProps}
                  //        className={styles.buttonContainer}
                  //        style={provided.draggableProps.style}
                  //      >
                  <ThemeProvider key={index} theme={themeButtons}>
                    <Button
                      variant="outlined"
                      onClick={() => handleClickOpen(item)}
                      // {...provided.dragHandleProps}
                      sx={{
                        opacity: item.is_visible ? 1 : 0.5,
                        bgcolor: customAppearance.buttons.color.hex,
                        border: customAppearance.buttons.strokeIsActive
                          ? `1px solid ${customAppearance.buttons.stroke.hex} !important`
                          : "none",
                        "&:hover": {
                          bgcolor: customAppearance.buttons.onhover.hex,
                        },
                      }}
                      // className={styles.buttonStyle}
                      startIcon={
                        <img
                          src={item.icon.icon.file}
                          alt={item.icon.identifier}
                          style={{
                            height: "21px",
                            filter: cssFilter || "none", // Aggiungi il filtro qui
                          }}
                        />
                        // <IconButton className={styles.iconButtonStyle}>
                        // </IconButton>
                      }
                      // endIcon={
                      //   <IconButton className={styles.iconButtonStyle}>
                      //     <DragIndicatorIcon
                      //       sx={{
                      //         zIndex: 5,
                      //         color: "black",
                      //         cursor: "grab",
                      //       }}
                      //     />
                      //   </IconButton>
                      // }
                    >
                      <Typography
                        variant="body1"
                        noWrap
                        color="initial"
                        sx={{
                          fontFamily: `${customAppearance.buttons.font.family} !important`,
                          color: customAppearance.buttons.font.color.hex,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Button>
                  </ThemeProvider>

                  //  </div>
                  //  )}
                  //  </Draggable>
                );
              })}
              {/* {provided.placeholder} */}
            </Box>
          )}
          {/* </Droppable>
    </DragDropContext> */}
          {/* )} */}

          {/* <ListManager
      items={iconsApi}
      direction="horizontal"
      maxItems={maxIcons(iconsApi.length)}
      render={(item) => (
        <div className={styles.divIcon} onClick={() => handleClickOpen(item)}>
        <img
        src={item.icon.file}
        alt={item.title}
        className={
          item.is_visible ? styles.iconNormal : styles.iconDimmed
        }
        />
        </div>
        )}
        onDragEnd={(sourceIndex, destinationIndex) =>
          reorderList(sourceIndex, destinationIndex)
        }
      /> */}
        </>
      )}

      {!dataLoaded ? (
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
          sx={{ maxWidth: "150px", mb: 1 }}
        >
          <Grid
            xs={disponiIcone(3)}
            item
            tabIndex={0}
            className={styles.centerGrid}
          >
            <Skeleton variant="circular" width={28} height={28} />
          </Grid>

          <Grid
            xs={disponiIcone(3)}
            item
            tabIndex={0}
            className={styles.centerGrid}
          >
            <Skeleton variant="circular" width={28} height={28} />
          </Grid>
          <Grid
            xs={disponiIcone(3)}
            item
            tabIndex={0}
            className={styles.centerGrid}
          >
            <Skeleton variant="circular" width={28} height={28} />
          </Grid>
        </Grid>
      ) : (
        iconsApi.length > 0 && (
          <Grid container className={styles.gridContainer}>
            {iconsApi.map((item, index) => {
              const cssFilter = hexToCSSFilter(
                customAppearance.buttons.font.color.hex
              ).filter.slice(0, -1);
              
              return (
                <Grid
                  key={index}
                  xs={disponiIcone(iconsApi.length)}
                  item
                  tabIndex={0}
                  className={styles.centerGrid}
                >
                  <CustomIconButton
                    icon={item.icon ? item.icon.icon.file : ""}
                    iconAlt={item.icon ? item.icon.icon.identifier : item.title}
                    opacity={item.is_visible ? 1 : 0.35}
                    onClickFunction={() => handleClickOpen(item)}
                    filter={cssFilter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )
      )}

      <Button
        onClick={handleOpenDrawer}
        className={styles.addButton}
        sx={{
          border: `dashed 2px ${customAppearance.texts.color.hex} !important`,
          margin: "20px 0 !important",
        }}
      >
        <AddIcon
          className={styles.addIcon}
          sx={{
            color: customAppearance.texts.color.hex,
          }}
        />
      </Button>

      <DrawerButtonsMenu
        drawerHeight={drawerHeight}
        lang={lang}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        menuItems={menuItems}
      />
    </Box>
  );
}
