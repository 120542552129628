import { styled, createTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import eyeIcon from "./assets/icons/Icone/fi-rr-eye.png";
import eyeCrossedIcon from "./assets/icons/Icone/fi-rr-eye-crossed.png";
import helloImg from "./assets/img/hello_emoji.png";
import { css, keyframes } from "@emotion/react";

// Definisci l'animazione keyframe
const wave = keyframes`
0%, 100% {
  transform: rotate(-15deg);
}
50% {
  transform: rotate(15deg);
}
`;

// Applica l'animazione al Box
export const helloBoxStyle = css`
  width: 180px;
  height: 180px;
  border-radius: 50px;
  background-color: #fff;
  margin-top: 24px;
  margin-bottom: 24px;
  background-image: url(${helloImg});
  background-size: 125px;
  background-repeat: no-repeat;
  background-position: center;
  animation: ${wave} 1.2s infinite ease-in-out;
  transform-origin: bottom;
`;

export const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    background: "#F2F2F2",
    borderRadius: 10,
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    paddingX: "22px",
  },
  "& .MuiAutocomplete-inputRoot": {
    paddingX: "15px",
  },
  "& .MuiInputBase-inputMultiline": {
    paddingX: "10px",
  },
  "& .MuiInputLabel-outlined, & .MuiInputRootLabel-outlined": {
    transform: "translate(22px, 16px) scale(1)", // Modifica questo valore in base al tuo tema e dimensioni
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(22px, -10px) scale(0.75)", // Modifica questo valore in base al tuo tema e dimensioni
  },
  background: "#F2F2F2",
  borderRadius: 7,
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000000",
  },
};

export const textFieldStyleLoginSignup = {
  "& .MuiOutlinedInput-root": {
    background: "#F2F2F2",
    borderRadius: 10,
  },
  "& fieldset": {
    border: "none",
  },
  background: "#F2F2F2",
  borderRadius: 10,
};

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 27,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${eyeIcon})`,
        backgroundSize: "16px",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 23,
    height: 23,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${eyeCrossedIcon})`,
      backgroundSize: "16px",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const menuPropsContact = {
  PaperProps: {
    elevation: 2,
    sx: {
      maxHeight: 300,
      borderRadius: "15px",
      overflowY: "auto",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "8px",
        translateX: "-5px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "rgba(0, 0, 0, 0.25)",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f2f2f2",
      },
    },
  },
};

export const numberInputStyle = {
  ...textFieldStyle,
  "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
};

export const menuIconsHeaderProps = {
  PaperProps: {
    elevation: 2,
    style: {
      maxHeight: 350,
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
  },
};

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#247DFF",
      main_r: "36",
      main_g: "125",
      main_b: "255",
    },
    background: { default: "#fff" }, // modify this for the background color
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontSize: 16,
        },
      },
    },
  },
});

export const themeSecondaryButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          fontFamily: "Poppins, Arial, sans-serif",
          letterSpacing: -0.3,
          fontSize: 16,
          backgroundColor: "#f5f5f5",
          color: "black",
          textTransform: "none",
          border: "none",
          "&:hover": {
            backgroundColor: "#f0f0f0",
            border: "none",
          },
        },
      },
    },
  },
});

export const themeBlackButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ...themeSecondaryButton.components.MuiButton.styleOverrides.root,
          backgroundColor: "#000000",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#4d4d4d",
            border: "none",
          },
        },
      },
    },
  },
});

export const themeButtonItemsMenu = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ...themeSecondaryButton.components.MuiButton.styleOverrides.root,
          minWidth: 235,
          margin: 6,
          width: "71.5%",
          height: 50,
        },
      },
    },
  },
});

export const themeTextOverview = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontFamily: "Poppins, Arial, sans-serif",
          color: "#000000",
        },
        subtitle1: {
          fontFamily: "Poppins, Arial, sans-serif",
          marginTop: 8,
          marginBottom: 8,
          color: "#8e8e8e",
          maxWidth: 300,
        },
      },
    },
  },
});
