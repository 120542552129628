import { useState, useEffect } from "react";
import {
  FormControl,
  Typography,
  Container,
  Link,
  Button,
  FormGroup,
} from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { registerUser } from "../../api/authentication.js";
import { translations as t } from "../../assets/translations.js";
import { useSelector, useDispatch } from "react-redux";
import { setRefresh } from "../../slices/userAuth.js";
import { setNotification } from "../../slices/notificationSnackbar.js";
import Meraviglia from "../../components/Meraviglia";
import InputEmail from "../../components/InputEmail";
import InputPassword from "../../components/InputPassword";
import InputPassword2 from "../../components/InputPassword2";
import IconFlag from "../../components/IconFlag";
import ValidatePassword from "./components/ValidatePassword";
import TermsAndConditions from "./components/TermsAndConditions";
import styles from "./css/Signup.module.css";
import GoogleLoginButton from "../../components/GoogleLoginButton/GoogleLoginButton.jsx";
import { useCheckRefreshToken } from "../../hooks/useCheckRefreshToken.jsx";

export default function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const { nfc_code } = useParams() || null;
  const nfc_passkey = location.state ? location.state : "";
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const lang = useSelector((state) => state.userLang.value);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const dispatch = useDispatch();

  function validatePassword(password) {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);

    return (
      password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers
    );
  }

  const redirectToSetup = () => {
    // const passwordsDoMatch = password === password2;
    const isEmailValid = isValidEmail && email.trim() !== "";
    const isPasswordValid = validatePassword(password);

    // const isPassword2Valid = validatePassword(password2);

    if (
      isEmailValid &&
      isPasswordValid
      // &&
      // isPassword2Valid
      //&&
      //passwordsDoMatch
    ) {
      const request = {
        email: email,
        password: password,
        password2: password2,
      };
      if (nfc_passkey) {
        request.nfc_passkey = nfc_passkey;
      }

      registerUser(request)
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );

          const otp_id = response.data.otp_id;
          navigate(`/account-verification/${otp_id}`, {
            state: { email: email },
          });
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    }
  };

  // Checking presence of refresh token
  if (localStorage.getItem("refresh")) {
    dispatch(setRefresh());
  }

  // Checking refresh state
  useCheckRefreshToken();

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && acceptTerms) {
      redirectToSetup();
    }
  };

  // reset the body bgcolor to white
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.backgroundColor = "#fff";
  });

  return (
    <Container maxWidth="sm" className={styles.container}>
      <IconFlag />
      <FormControl id="signup" component="fieldset" sx={{ width: "100%" }}>
        <Typography variant="h3" fontWeight="500" color="initial">
          {t["signup-ciao"][lang]}
        </Typography>
        <Typography variant="h3" fontWeight="500" color="primary">
          {t["signup-benvenuto"][lang]}
        </Typography>
        {nfc_code && (
          <Typography variant="subtitle1">
            {t["pin-code"][lang]}:<b> {nfc_code}</b>
          </Typography>
        )}
        <FormGroup sx={{ width: "100%" }} onKeyPress={handleKeyPress}>
          <InputEmail
            email={email}
            setEmail={setEmail}
            isValidEmail={isValidEmail}
            setIsValidEmail={setIsValidEmail}
          />
          <InputPassword password={password} setPassword={setPassword} />
          <InputPassword2 password2={password2} setPassword2={setPassword2} />
          <ValidatePassword password={password} />
          <TermsAndConditions onAccept={setAcceptTerms} />
        </FormGroup>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={styles.buttonForm}
          onClick={redirectToSetup}
          disabled={
            !email ||
            !password ||
            !password2 ||
            !acceptTerms ||
            !isValidEmail || // Assicurati che l'email sia valida
            !validatePassword(password) // Assicurati che la password soddisfi i criteri
          }
        >
          {t["signup-inizia"][lang]}
        </Button>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body1"
            color="initial"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {t["signup-hai-account"][lang]}
          </Typography>
          <Link
            href="/login"
            variant="body1"
            underline="hover"
            color="inherit"
            fontWeight="600"
          >
            {t["signup-accedi"][lang]}
          </Link>
        </Container>
      </FormControl>

      <GoogleLoginButton nfc_passkey={nfc_passkey} />
      <Meraviglia />
    </Container>
  );
}
