import { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Container,
  Box,
  TextField,
  FormControl,
  FormGroup,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import PlayIcon from "../../assets/icons/Icone/fi-rr-play.png";
import Header from "./components/Header";
import Meraviglia from "../../components/Meraviglia";
import { translations as t } from "../../assets/translations.js";
import { textFieldStyle } from "../../themes.js";
import { createLink, getLinkData, updateLink } from "../../api/links.js";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar.js";

import styles from "./css/LinksInfo.module.css";

export default function VideoInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const dataFromButton = location.state || {};
  const categoryId = dataFromButton.category_id;
  const icon = dataFromButton.icon;
  const [link, setLink] = useState("");
  const isAuth = useSelector((state) => state.userAuth.isAuth);
  const lang = useSelector((state) => state.userLang.value);
  const dispatch = useDispatch();

  const idAlreadyCreate = dataFromButton ? dataFromButton.id : null;

  const [linkData, setLinkData] = useState({
    title: "",
    url: "",
    type: "BUTTON",
    icon: icon,
    is_visible: true,
  });
  const [headerData, setHeaderData] = useState({
    id: idAlreadyCreate,
    isAuth: isAuth,
    title: linkData.title,
    style: dataFromButton.type ? dataFromButton.type : linkData.type,
    icon: linkData.icon,
    icon_id: linkData.icon.id,
    show: linkData.is_visible,
    page: "video",
  });

  useEffect(() => {
    if (idAlreadyCreate) {
      getLinkData(idAlreadyCreate)
        .then((response) => {
          setLinkData({
            id: response.data.id,
            title: response.data.title,
            url: response.data.url,
            type: response.data.type,
            category: response.data.category,
            icon: response.data.icon,
            is_visible: response.data.is_visible,
            order: response.data.order,
          });
          setHeaderData({
            id: response.data.id,
            title: response.data.title,
            style: response.data.type,
            show: response.data.is_visible,
            icon: response.data.icon,
          });
          setLink(response.data.url);
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : t["snackbar"]["not-load-info"][lang],
              severity: "error",
            })
          );
        });
    }
    // eslint-disable-next-line
  }, [idAlreadyCreate]);

  const handleSubmit = () => {
    if (idAlreadyCreate === undefined) {
      const request = {
        title: headerData.title ? headerData.title : t["btsmenu-video"][lang],
        url:
          link.startsWith("http://") || link.startsWith("https://")
            ? link
            : `https://${link}`,
        type: headerData.style,
        category_id: categoryId,
        icon_id: headerData.icon_id,
        is_visible: headerData.show,
      };
      createLink(request)
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );
          navigate("/overview", { state: { newData: response.data } });
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    } else {
      const request = {
        title: headerData.title ? headerData.title : t["btsmenu-video"][lang],
        url:
          link.startsWith("http://") || link.startsWith("https://")
            ? link
            : `https://${link}`,
        type: headerData.style,
        category_id: dataFromButton.category.id,
        icon_id: headerData.icon_id || linkData.icon.id,
        is_visible: headerData.show,
      };

      updateLink(idAlreadyCreate, request)
        .then((response) => {
          dispatch(
            setNotification({
              open: false,
            })
          );
          navigate("/overview", { state: { newData: response.data } });
        })
        .catch((error) => {
          dispatch(
            setNotification({
              open: true,
              message: error.response.data.data
                ? error.response.data.data.error
                : "Something went wrong.",
              severity: "error",
            })
          );
        });
    }
  };

  const handleHeaderDataChange = (key, value) => {
    setHeaderData((prev) => ({ ...prev, [key]: value }));
  };

  //VALIDATE LINK

  const [isLinkValid, setIsLinkValid] = useState(true);

  const validateLink = (link) => {
    const regex = /^(?=.*\.[^.]+$)[^ "]+$/;
    return regex.test(link);
  };

  useEffect(() => {
    setIsLinkValid(validateLink(link));
  }, [link]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Container maxWidth="sm" className={styles.container}>
      <FormGroup onKeyPress={handleKeyPress} className={styles.formGroup}>
        <Header
          headerData={headerData}
          onTitleChange={(value) => handleHeaderDataChange("title", value)}
          onStyleChange={(value) => handleHeaderDataChange("style", value)}
          onIconChange={(value) => handleHeaderDataChange("icon", value)}
          onIconIdChange={(value) => handleHeaderDataChange("icon_id", value)}
          onSwitchChange={(value) => handleHeaderDataChange("show", value)}
        />
        <Container component="main" className={styles.mainContainer}>
          <Box>
            <Box className={styles.centerContainer}>
              <img src={PlayIcon} alt="PlayIcon" className={styles.iconTitle} />
              <Typography
                variant="h5"
                fontWeight="500"
                color="initial"
                sx={{ px: 2 }}
              >
                Video
              </Typography>
            </Box>
            <FormControl name="link" fullWidth sx={{ my: 2 }}>
              <TextField
                name="link"
                sx={textFieldStyle}
                required
                value={link}
                onChange={(e) => setLink(e.target.value)}
                label="Link"
                type="url"
                disabled={!isAuth}
                error={!isLinkValid && link.length > 0}
              />
              {!isLinkValid && link.length > 0 && (
                <Typography sx={{ pl: 3 }} variant="body2" color="error">
                  {t["helpertext-link"][lang]}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={styles.formButton}
            >
              {t["salva"][lang]}
            </Button>
          </Box>
        </Container>
        <Meraviglia />
      </FormGroup>
    </Container>
  );
}
