import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";

import { translations as t } from "../../assets/translations.js";
import styles from "./DeleteConfirmationDialog.module.css";

const DeleteConfirmationDialog = ({ open, onClose, onConfirm }) => {
  const lang = useSelector((state) => state.userLang.value);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: 5, padding: 1 } }}
    >
      <DialogTitle id="alert-dialog-title">
        {t["delete-confirmation-title"][lang]}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t["delete-confirmation-description"][lang]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={styles.deleteButtons} onClick={onClose}>
          {t["delete-confirmation-cancel"][lang]}
        </Button>
        <Button
          className={styles.deleteButtons}
          onClick={onConfirm}
          autoFocus
          variant="contained"
        >
          {t["delete-confirmation-confirm"][lang]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
